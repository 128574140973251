import React, { useEffect, useState } from 'react'

import _, { debounce } from 'lodash'
import { useMutation, useQuery } from 'react-query'

import CabalButton from 'global/CabalButton'
import { Select, TextArea } from 'global/Input'
import Modal, { useModal } from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useAdvisors } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

interface RequestReasonsOptions {
  fundraising: string
  job: string
  networking: string
  other_request_reason: string
  sales_partnership: string
}

interface IntroStatuses {
  [key: string]: any
}

interface Person {
  first_name: string
  last_name: string
  name: string
  headline: string
  uuid: string
}

interface GlobalPeople {
  people: Person[]
}

interface ListItem {
  [key: string]: any
}

interface IFacilitateIntroRequest {
  target: string
  requestor: string
  facilitator: string
  category: string
  status: string
  note: string
}

const CreateManualIntro = (team?: any) => {
  const salesFundraisingStatuses = [
    'Created',
    'Requested',
    'Offered',
    'Sent',
    'Meeting',
    'Opportunity',
    'Closed-Won',
    'Declined',
  ]

  const talentStatuses = ['Requested', 'Accepted', 'Sent', 'Led to Placement', 'Declined']

  const otherStatuses = ['Requested', 'Accepted', 'Sent', 'Declined']

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  const [list, setList] = useState<ListItem[]>([])
  const [targetOptions, setTargetOptions] = useState([])
  const [query, setQuery] = useState('')
  const [note, setNote] = useState('')
  const { closeModal } = useModal()
  const { data: requestReasonsOptions, isLoading: requestReasonsFetchLoading } =
    useQuery<RequestReasonsOptions>(['requestReasons'], () => callApi(api.getIntroRequestReasons))

  const { data: introRequestStatuses, isLoading: introRequestStatusesLoading } =
    useQuery<IntroStatuses>(['introRequestStatuses'], () => callApi(api.getIntroRequestStatuses))

  const teamSlug = team?.team?.slug
  const { advisors } = useAdvisors({ teamSlug })

  useEffect(() => {
    const allFieldsCaptured =
      list[0]?.target &&
      list[0]?.requestor &&
      list[0]?.facilitator &&
      list[0]?.category &&
      list[0]?.status

    setIsSubmitDisabled(!allFieldsCaptured)
  }, [list])

  const fetchGlobalPeople = (queryString?: string) => {
    const [debouncedQuery, setDebouncedQuery] = useState(queryString)

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedQuery(queryString)
      }, 500)

      return () => {
        clearTimeout(handler)
      }
    }, [queryString])

    return useQuery(
      ['globalPeople', debouncedQuery],
      () => callApi(() => api.getGlobalPeople(debouncedQuery)),
      {
        onSuccess: (data) => {
          globalPeopleOptions(data)
        },
        onError: (error) => {
          console.error('Error fetching global people:', error)
        },
      },
    )
  }
  const { isLoading: globalPeopleLoading, data: globalPeopleData } = fetchGlobalPeople(query)

  const globalPeopleOptions = (globalPeople: GlobalPeople) => {
    const formattedOptions = globalPeople?.map((person: Person) => ({
      label: `${person.first_name} ${person.last_name}${
        person.headline ? ` (${person.headline})` : ''
      }`,
      value: person.uuid,
    }))

    setTargetOptions(formattedOptions)
  }

  const capitalizeKeys = (obj: IntroStatuses) => {
    const capitalizedObj: { [key: string]: any } = {}

    Object.keys(obj).forEach((key) => {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1)
      capitalizedObj[capitalizedKey] = obj[key]
    })

    return capitalizedObj
  }

  const statusesOptions = introRequestStatuses
    ? Object.entries(capitalizeKeys(introRequestStatuses)).map(([key, value]) => ({
        label: key,
        value: value,
      }))
    : []

  const handleInputChange = (queryString: string) => {
    if (queryString.length < 4) {
      return
    }

    setQuery(queryString)
  }

  const reasonsOptions = requestReasonsOptions
    ? Object.entries(requestReasonsOptions).map(([key, value]) => ({
        label: value,
        value: key,
      }))
    : []

  const requestorOptions =
    advisors
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .map((advisor: { name: string; uuid: string; email: string }) => ({
        label: `${advisor.name} (${advisor.email})`,
        value: advisor.uuid,
      })) || []

  const updateList = (key: string, value: any) => {
    setList((prevList) => {
      if (prevList.length === 0) {
        return [{ [key]: value }]
      } else {
        const updatedList = { ...prevList[0], [key]: value }
        return [updatedList]
      }
    })
  }

  const handleCreateIntro = () => {
    const params: IFacilitateIntroRequest = {
      target: list[0].target,
      requestor: list[0].requestor,
      facilitator: list[0].facilitator,
      category: list[0].category,
      status: list[0].status,
      note: list[0].note,
    }

    createMutateManualIntro(params)
  }

  const { mutate: createMutateManualIntro, isLoading: creatingIntro } = useMutation(
    (params: IFacilitateIntroRequest) => callApi(api.createManualIntroRequest, params),
    {
      onSuccess: (data) => {
        cabalToast({
          style: 'success',
          content: `Intro created successfully!`,
          cta: {
            variant: 'tertiary',
            content: 'View intro',
            component: 'link',
            className: 'underline',
            to: data?.intro?.relative_request_center_url,
          },
        })
        closeModal('render_manual_intro_modal')
      },
      onError: (error) => {
        console.error('Error creating manual intro:', error)
      },
    },
  )

  const otherItem = reasonsOptions.filter((item) => item.label === 'Other')

  const sortedReasonsOptions = reasonsOptions.filter((item) => item.label !== 'Other')

  if (otherItem.length) {
    sortedReasonsOptions.push(otherItem[0])
  }

  const filterStatuses = (category: string, statuses: IntroStatuses) => {
    let validStatuses = []

    if (category === 'sales_partnership' || category === 'fundraising') {
      validStatuses = salesFundraisingStatuses
    } else if (category === 'job') {
      validStatuses = talentStatuses
    } else {
      validStatuses = otherStatuses
    }

    return statuses.filter((status: { label: string }) => validStatuses.includes(status.label))
  }

  const filteredStatuses = filterStatuses(list[0]?.category, statusesOptions)

  return (
    <Modal
      show={true}
      onHide={() => closeModal('render_manual_intro_modal')}
      header={
        <Typography fontSize="16" fontWeight={600} lineHeight="1.2" className="w-full text-center">
          New Intro
        </Typography>
      }
      centerActions={
        <div className="pb-2">
          <CabalButton
            onClick={() => handleCreateIntro()}
            working={creatingIntro}
            className="mr-2"
            disabled={isSubmitDisabled}
          >
            Create Intro
          </CabalButton>
        </div>
      }
    >
      <ModalInputWrapper className="mb-3 flex justify-center items-center">
        <ModalInputLabel className="mr-2">Target</ModalInputLabel>
        <Select
          options={targetOptions}
          isLoading={globalPeopleLoading}
          className="w-full"
          onInputChange={(e) => handleInputChange(e)}
          onChange={(e) => updateList('target', e)}
        />
      </ModalInputWrapper>
      <ModalInputWrapper className="mb-3 flex justify-center items-center">
        <ModalInputLabel className="mr-2">Requestor</ModalInputLabel>
        <Select
          options={requestorOptions}
          className="w-full"
          onChange={(e) => updateList('requestor', e)}
        />
      </ModalInputWrapper>
      <ModalInputWrapper className="mb-3 flex justify-center items-center">
        <ModalInputLabel className="mr-2">Facilitator</ModalInputLabel>
        <Select
          options={requestorOptions}
          className="w-full"
          onChange={(e) => updateList('facilitator', e)}
        />
      </ModalInputWrapper>
      <ModalInputWrapper className="mb-3 flex justify-center items-center">
        <ModalInputLabel className="mr-2">Category</ModalInputLabel>
        <Select
          options={sortedReasonsOptions}
          className="w-full"
          onChange={(e) => updateList('category', e)}
        />
      </ModalInputWrapper>
      <ModalInputWrapper className="mb-3 flex justify-center items-center">
        <ModalInputLabel className="mr-2">Status</ModalInputLabel>
        <Select
          options={filteredStatuses}
          className="w-full"
          onChange={(e) => updateList('status', e)}
        />
      </ModalInputWrapper>
      <div className="space-y-2 mb-2">
        <>
          <Typography fontSize="12" fontWeight={400} lineHeight="1" className="text-center">
            Note
          </Typography>
          <TextArea
            value={note}
            className="w-full mt-1"
            rows={3}
            data-testid="request-talent-intro-modal-context"
            onChange={(e) => {
              setNote(e.target.value)
              updateList('note', e.target.value)
            }}
          />
        </>
      </div>
    </Modal>
  )
}

export default CreateManualIntro

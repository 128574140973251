import React from 'react'

import JobsList from 'containers/ListIndexView/TalentList/JobsList'
import PeopleList from 'containers/ListIndexView/TalentList/PeopleList'

import { CompanyListBlueprint } from 'utils/types'

interface Props {
  companyListData: CompanyListBlueprint
  refetchCompanyList: () => void
}

const TalentList: React.FC<Props> = ({ companyListData, refetchCompanyList }) => {
  const isJobsList = companyListData && companyListData.list_type === 'jobs'
  const isPeopleList = companyListData && companyListData.list_type === 'people'

  return (
    <div>
      {isJobsList && (
        <JobsList companyListData={companyListData} refetchCompanyList={refetchCompanyList} />
      )}
      {isPeopleList && (
        <PeopleList companyListData={companyListData} refetchCompanyList={refetchCompanyList} />
      )}
    </div>
  )
}

export default TalentList

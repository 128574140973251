import React from 'react'

import { EditListType } from 'components/EditList'
import { TextInput } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'

interface Props {
  list: Partial<EditListType>
  setList: (list: Partial<EditListType>) => void
}

const ResourceDiscountOptions = ({ list, setList }: Props) => {
  return (
    <>
      <ModalInputWrapper>
        <ModalInputLabel>Name</ModalInputLabel>
        <TextInput
          placeholder="Add a list name"
          value={list.name}
          autoFocus={true}
          onChange={(e) => setList({ ...list, name: e.currentTarget.value })}
          data-testid="list-name-input"
        />
      </ModalInputWrapper>
    </>
  )
}

export default ResourceDiscountOptions

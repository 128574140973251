import React, { useEffect, useState } from 'react'

import { Field, FieldProps, Form, Formik } from 'formik'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { object, string } from 'yup'

import { Footer, useCandidateProfile } from 'containers/CandidateProfileModal'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { MultiSelect, SelectField, TextInputField, UploadButton } from 'global/Input'
import CityInput from 'global/Input/CityInput'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'

export const SelectWrapper = styled.div`
  .select__value-container {
    justify-content: flex-start !important;
  }
`

export function AvatarInput(props: {
  name: string
  file: File | undefined
  onChangeFile: (file: File | undefined) => unknown
}) {
  const fileReader = React.useRef(new FileReader()).current
  const [dataUrl, setDataUrl] = React.useState<string>()
  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    fileReader.addEventListener('load', () => {
      setDataUrl(fileReader.result?.toString())
    })
  }, [setDataUrl, fileReader])

  React.useEffect(() => {
    if (props.file) {
      fileReader.readAsDataURL(props.file)
    } else {
      setDataUrl(undefined)
    }
  }, [props.file, fileReader])

  const pickFile = () => {
    inputRef.current?.click()
  }

  return (
    <div className="flex items-center gap-5">
      <input
        ref={inputRef}
        type="file"
        hidden
        accept="image/*"
        onChange={(e) => {
          props.onChangeFile(e.target.files?.[0])
        }}
        className=""
      />
      <div className="flex justify-center items-center">
        <Avatar name={props.name} src={dataUrl} isSrcDataUrl />
      </div>

      <div className="flex flex-col items-start">
        {props.file ? (
          <div className="flex items-center">
            <CabalButton type="button" variant="link" onClick={pickFile}>
              Change photo
            </CabalButton>
            <CabalButton
              type="button"
              variant="tertiary"
              onClick={() => {
                props.onChangeFile(undefined)
              }}
            >
              Remove photo
            </CabalButton>
          </div>
        ) : (
          <>
            <CabalButton type="button" variant="link" onClick={pickFile}>
              Add photo
            </CabalButton>
          </>
        )}
      </div>
    </div>
  )
}

export const candidateProfileSchema = object({
  firstName: string().required('This field is required'),
  lastName: string().required('This field is required'),
  email: string().required('This field is required').email('Invalid email'),
  linkedInUrl: string(),
  company: string().required('This field is required'),
  location: string().required('This field is required'),
  title: string().required('This field is required'),
  ownerUuid: string().required('This field is required'),
})

interface Props {
  listUuid?: string
  onContinue: () => void
  teamSlug: string
  inPeopleList?: boolean
  creatingCandidateProfile?: boolean
}

const CandidatePersonalInfo: React.FC<Props> = ({
  listUuid,
  onContinue,
  teamSlug,
  inPeopleList,
  creatingCandidateProfile = true,
}) => {
  const { candidateProfile, updateCandidateProfile } = useCandidateProfile()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const [companyLists, setCompanyLists] = useState<any>(null)
  const [allCandidatesListUuids, setAllCandidatesListUuids] = useState<any>([])
  const [allCandidatesUuid, setAllCandidatesUuid] = useState<any>('')
  const { team } = useTeam(teamSlug)
  const isAllCandidates = listUuid === 'all-candidates'

  useEffect(() => {
    callApi(api.getTalentLists, teamSlug).then((results) => {
      const lists = results?.company_lists ? results?.company_lists : []
      const listsWithoutAllCandidates = lists.filter((list) => !list.is_all_candidates)
      let defaultListUuid
      if (inPeopleList && listUuid) {
        defaultListUuid = listUuid
      } else if (listsWithoutAllCandidates.length > 0 && !inPeopleList) {
        if (isAllCandidates) {
          if (candidateProfile.companyListUuids.length === 0) {
            if (team?.default_talent_list_id) {
              defaultListUuid = team?.default_talent_list_id
            } else {
              defaultListUuid =
                listsWithoutAllCandidates.find((list) => list.uuid === listUuid)?.uuid ||
                team?.default_talent_list_id ||
                listsWithoutAllCandidates[0].uuid
            }
          }
        } else {
          defaultListUuid = listUuid
        }
      }
      const initialDefaultUuids = creatingCandidateProfile
        ? defaultListUuid
          ? [defaultListUuid]
          : []
        : candidateProfile.companyListUuids.filter((uuid) => {
            const list = listsWithoutAllCandidates.find((l) => l.uuid === uuid)
            return list && !list.is_all_candidates
          })

      setAllCandidatesUuid(lists.find((list) => list.is_all_candidates)?.uuid)
      setCompanyLists(listsWithoutAllCandidates)
      setAllCandidatesListUuids(initialDefaultUuids)
    })
  }, [])

  const { user: currentUser } = useCurrentUser()
  const { data: ownersList } = useQuery(['getTeamUsers', teamSlug], () =>
    callApi(api.getTeamUsers, teamSlug),
  )

  const autoFocusStack = [
    candidateProfile.firstName ? null : 'firstName',
    candidateProfile.lastName ? null : 'lastName',
    candidateProfile.email ? null : 'email',
    candidateProfile.company ? null : 'company',
    candidateProfile.title ? null : 'title',
    candidateProfile.linkedInUrl ? null : 'linkedInUrl',
  ].filter(Boolean)

  return (
    <div className="px-4">
      <Formik
        initialValues={{
          firstName: candidateProfile.firstName,
          lastName: candidateProfile.lastName,
          email: candidateProfile.email,
          linkedInUrl: candidateProfile.linkedInUrl,
          company: candidateProfile.company,
          title: candidateProfile.title,
          location: candidateProfile.location,
          ownerUuid: candidateProfile.ownerUuid || currentUser.uuid,
        }}
        validationSchema={candidateProfileSchema}
        onSubmit={(values) => {
          const updatedCompanyListUuids = [
            ...new Set([...allCandidatesListUuids, allCandidatesUuid].filter(Boolean)),
          ]
          console.log('updatedCompanyListUuids', updatedCompanyListUuids)
          updateCandidateProfile({ ...values, companyListUuids: updatedCompanyListUuids })
          onContinue()
        }}
      >
        {({ handleSubmit }) => (
          <Form
            onSubmit={(e) => {
              handleSubmit(e)
            }}
          >
            <ModalInputWrapper className="py-2">
              <ModalInputLabel>
                <div className="flex items-center justify-start h-full">Photo</div>
              </ModalInputLabel>
              <div className="flex">
                <UploadButton
                  allowedExtensions={['png', 'jpg', 'jpeg']}
                  onUpload={(uuid) => updateCandidateProfile({ avatar: `/api/uploads/${uuid}` })}
                  showPencil={true}
                  text=""
                  triggerProps={{
                    variant: 'tertiary',
                    leftIcon: (
                      <Typography className="flex items-start justify-start">
                        <Avatar
                          src={candidateProfile.avatar}
                          size={'32'}
                          name={candidateProfile.firstName}
                        />
                        <p className="pt-4 pl-3">Edit</p>
                      </Typography>
                    ),
                    padding: '0',
                  }}
                />
              </div>
            </ModalInputWrapper>

            <ModalInputWrapper className="py-2">
              <ModalInputLabel>
                <div className="flex items-center justify-start h-full">First Name</div>
              </ModalInputLabel>
              <Field name="firstName">
                {(props: FieldProps) => (
                  <>
                    <TextInputField
                      placeholder="Enter first name"
                      autoFocus={autoFocusStack[0] === 'firstName'}
                      error={props.meta.touched ? props.meta.error : undefined}
                      {...props.field}
                    />
                  </>
                )}
              </Field>
            </ModalInputWrapper>

            <ModalInputWrapper className="py-2">
              <ModalInputLabel>
                <div className="flex items-center justify-start h-full">Last Name</div>
              </ModalInputLabel>
              <Field name="lastName">
                {(props: FieldProps) => (
                  <>
                    <TextInputField
                      placeholder="Enter last name"
                      autoFocus={autoFocusStack[0] === 'lastName'}
                      clearable={false}
                      required
                      error={props.meta.touched ? props.meta.error : undefined}
                      {...props.field}
                    />
                  </>
                )}
              </Field>
            </ModalInputWrapper>

            <ModalInputWrapper className="py-2">
              <ModalInputLabel>
                <div className="flex items-center justify-start h-full">Email</div>
              </ModalInputLabel>
              <Field name="email">
                {(props: FieldProps) => (
                  <TextInputField
                    placeholder="Enter email"
                    autoFocus={autoFocusStack[0] === 'email'}
                    error={props.meta.touched ? props.meta.error : undefined}
                    {...props.field}
                    disabled={!creatingCandidateProfile}
                  />
                )}
              </Field>
            </ModalInputWrapper>

            <ModalInputWrapper className="py-2">
              <ModalInputLabel>
                <div className="flex items-center justify-start h-full">LinkedIn URL</div>
              </ModalInputLabel>
              <Field name="linkedInUrl">
                {(props: FieldProps) => (
                  <TextInputField
                    {...props.field}
                    autoFocus={autoFocusStack[0] === 'linkedInUrl'}
                    error={props.meta.touched ? props.meta.error : undefined}
                    placeholder="LinkedIn URL"
                  />
                )}
              </Field>
            </ModalInputWrapper>

            <ModalInputWrapper className="py-2">
              <ModalInputLabel>
                <div className="flex items-center justify-start h-full">Company</div>
              </ModalInputLabel>
              <Field name="company">
                {(props: FieldProps) => (
                  <TextInputField
                    {...props.field}
                    autoFocus={autoFocusStack[0] === 'company'}
                    error={props.meta.touched ? props.meta.error : undefined}
                    placeholder="Current company"
                  />
                )}
              </Field>
            </ModalInputWrapper>

            <ModalInputWrapper className="py-2">
              <ModalInputLabel>
                <div className="flex items-center justify-start h-full">Title</div>
              </ModalInputLabel>
              <Field name="title">
                {(props: FieldProps) => (
                  <TextInputField
                    {...props.field}
                    autoFocus={autoFocusStack[0] === 'title'}
                    error={props.meta.touched ? props.meta.error : undefined}
                    placeholder="Title"
                  />
                )}
              </Field>
            </ModalInputWrapper>

            <ModalInputWrapper className="py-2">
              <ModalInputLabel>
                <div className="flex items-center justify-start h-full">Location</div>
              </ModalInputLabel>
              <Field name="location">
                {(props: FieldProps) => (
                  <SelectWrapper>
                    <CityInput
                      {...props.field}
                      onBlur={() => {
                        props.form.setFieldTouched(props.field.name, true)
                      }}
                      onChange={(opt) => {
                        props.form.setFieldValue(props.field.name, opt?.value)
                      }}
                      value={
                        props.field.value
                          ? { value: props.field.value, label: props.field.value }
                          : null
                      }
                      error={props.meta.touched ? props.meta.error : undefined}
                    />
                  </SelectWrapper>
                )}
              </Field>
            </ModalInputWrapper>

            {!inPeopleList && isAdminOrEmployee && (
              <>
                <ModalInputWrapper className="py-2 text-left">
                  <ModalInputLabel>
                    <div className="flex items-center justify-start h-full">Talent List</div>
                  </ModalInputLabel>
                  <SelectWrapper>
                    <MultiSelect<string>
                      placeholder="Select a Talent List"
                      value={allCandidatesListUuids}
                      onChange={(companyListUuids) =>
                        setAllCandidatesListUuids(companyListUuids ?? [])
                      }
                      options={
                        companyLists
                          ? companyLists.map((list) => ({ value: list.uuid, label: list.name }))
                          : []
                      }
                    />
                  </SelectWrapper>
                </ModalInputWrapper>

                <ModalInputWrapper className="py-2">
                  <ModalInputLabel>
                    <div className="flex items-center justify-start h-full">Owner</div>
                  </ModalInputLabel>
                  <SelectWrapper>
                    <Field name="ownerUuid">
                      {(props: FieldProps) =>
                        ownersList && (
                          <SelectField
                            placeholder="Owner"
                            fieldProps={props}
                            defaultValue={
                              props.field.value == '' ? currentUser.uuid : props.field.value
                            }
                            options={ownersList?.users.map((owner) => ({
                              value: owner.uuid,
                              label: `${owner.name} - ${owner.email}`,
                            }))}
                          />
                        )
                      }
                    </Field>
                  </SelectWrapper>
                </ModalInputWrapper>
              </>
            )}

            <div>
              <Footer className="-mx-6 flex justify-center mt-4 pt-6 pb-5">
                <CabalButton
                  type="submit"
                  variant="primary"
                  rightIcon={<i className="far fa-arrow-right" />}
                  className="w-1/4"
                >
                  Continue
                </CabalButton>
              </Footer>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CandidatePersonalInfo

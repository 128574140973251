import React, { useEffect, useRef, useState } from 'react'

import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized'
import styled from 'styled-components'

import PortfolioEmptyState from 'components/EmptyStates/PortfolioEmptyState'
import { useAccessControl } from 'global/AccessControl'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import Tabs, { Tab } from 'global/Tabs'
import Typography from 'global/Typography'
import { useCurrentUser, useUniqueAccessLink } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CompanySlugParam, PortfolioCompaniesFiltersType } from 'utils/types'
import { InvestorCompany } from 'utils/types/investor'

import Filters from '../AdminView/CompaniesTabV1/Filters'
import CompanyCard, { RequestIntroState } from './CompanyCard'
import RequestIntroModal from './RequestIntroModal'

const FilterWrapper = styled.div`
  padding: 6px 24px;
  background-color: ${({ theme }) => theme.layout.main_content_bg_color};
  border-bottom: ${({ theme }) => theme.border};
  position: sticky;
  top: 0px;
  z-index: 10;
`

const MemberView = () => {
  const { showModal, closeModal } = useModal()
  const { company_slug } = useParams<CompanySlugParam>()
  const { canViewPortfolio } = useAccessControl(company_slug)
  const [allTags, setAllTags] = useState<string[]>([])
  const [allStages, setAllStages] = useState<string[]>([])
  const [companies, setCompanies] = useState<InvestorCompany[]>([])
  const [filters, setFilters] = useState<PortfolioCompaniesFiltersType>({})
  const [filterSelected, setFilterSelected] = useState(false)
  const [filteredCompanies, setFilteredCompanies] = useState<InvestorCompany[]>([])
  const [requestIntroState, setRequestIntroState] = useState<{ [key: string]: RequestIntroState }>(
    {},
  )
  const { reloadUser } = useCurrentUser()
  const { isUniqueAccessLink, uuid: uniqueAccessLinkUuid } = useUniqueAccessLink()

  const cache = new CellMeasurerCache({
    keyMapper: (rowIndex: number) => companies[rowIndex].uuid,
    defaultHeight: 200,
    fixedWidth: true,
  })

  const [tableHeight, setTableHeight] = useState(0)

  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const isFilterSelected = !every(filters, isEmpty)

    let selectedCompanies = companies

    if (filters.search) {
      selectedCompanies = selectedCompanies?.filter((c: InvestorCompany) => {
        for (const i in filters.search) {
          const query = filters.search[i]
          if (c.company_name.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
            return true
          }
        }
      })
    }

    if (filters.tags) {
      selectedCompanies = selectedCompanies?.filter((c: InvestorCompany) => {
        for (const i in filters.tags) {
          const tag = filters.tags[i]
          if (c.tags.includes(tag)) {
            return true
          }
        }
      })
    }

    if (filters.stage) {
      selectedCompanies = selectedCompanies?.filter((c: InvestorCompany) => {
        for (const i in filters.stage) {
          const stage = filters.stage[i]
          if (c.stage === stage) {
            return true
          }
        }
      })
    }

    setFilterSelected(isFilterSelected)
    setFilteredCompanies(
      selectedCompanies.length > 0 || isFilterSelected ? selectedCompanies : companies,
    )
  }, [filters, companies])

  useEffect(() => {
    if (!wrapperRef.current) return

    const { offsetTop } = wrapperRef.current
    const vh = window.innerHeight
    const tableHeight = vh - offsetTop - 0
    setTableHeight(tableHeight)

    wrapperRef.current.style.height = `${vh - offsetTop - 40}px`
  }, [wrapperRef.current])

  useEffect(() => {
    if (!window.II) {
      api.sendTalentActivity({
        team_slug: company_slug,
        name: 'view_portfolio',
        unique_access_link_uuid: uniqueAccessLinkUuid,
      })
    }
  }, [isUniqueAccessLink])

  const updateRequestIntroLoading = (companyUuid: string, state: RequestIntroState) => {
    const updatedLoading = { ...requestIntroState }
    updatedLoading[companyUuid] = state
    setRequestIntroState(updatedLoading)
  }

  const setCompanyIntroRequested = (company: InvestorCompany) => {
    const updatedCompanies = companies?.map((c) => {
      if (c.uuid === company.uuid) return company
      return c
    })
    setCompanies(updatedCompanies)
  }

  const { mutate: requestIntro } = useMutation(
    ['requestIntro'],
    ({ companyUuid, details }) => {
      updateRequestIntroLoading(companyUuid, 'in_progress')
      return callApi(
        api.requestIntro,
        companyUuid,
        company_slug,
        details,
        undefined,
        uniqueAccessLinkUuid,
      )
    },
    {
      onSuccess: ({ company }) => {
        const { uuid } = company
        updateRequestIntroLoading(uuid, 'requested')
        setCompanyIntroRequested(company)
        cabalToast({ style: 'success', content: 'Intro sent!' })
        closeModal('request-intro-modal')
        reloadUser()
      },
      onError: (error, { company_uuid: companyUuid }) => {
        updateRequestIntroLoading(companyUuid, 'ready')
        const errMsg = error?.response?.data?.error
        cabalToast({
          style: 'error',
          content: `Something went wrong${errMsg ? ': ' + errMsg : '!'}`,
        })
      },
    },
  )

  const { isLoading, isFetching } = useQuery(
    ['getInvestorCompanies', company_slug],
    () => callApi(api.getInvestorCompanies, company_slug, uniqueAccessLinkUuid),
    {
      onSuccess: ({ companies, tags, stages }) => {
        setCompanies(companies)
        setAllTags(tags)
        setAllStages([...new Set(['seed', 'early', 'growth', 'public'].concat(stages || []))])
      },
      enabled: canViewPortfolio,
    },
  )

  const openRequestIntroModal = (companyUuid: string, companyName: string, companyLogo: string) => {
    showModal(
      (resolve) => (
        <RequestIntroModal
          teamSlug={company_slug}
          requestIntro={requestIntro}
          companyUuid={companyUuid}
          resolve={resolve}
          companyName={companyName}
          companyLogo={companyLogo}
        />
      ),
      'request-intro-modal',
    )
  }

  useEffect(() => {
    const newRequestIntroState: { [key: string]: RequestIntroState } = {}
    companies?.forEach((c) => {
      if (c.intro_request) {
        newRequestIntroState[c.uuid] = 'requested'
      } else {
        newRequestIntroState[c.uuid] = 'ready'
      }
    })
    setRequestIntroState(newRequestIntroState)
    setFilteredCompanies(companies)
  }, [companies])

  if (isLoading || (isFetching && companies?.length === 0)) return <Loading className="mt-10" />

  if (!isLoading && !isFetching && companies?.length === 0) {
    return (
      <div className="mt-4">
        <PortfolioEmptyState companySlug={company_slug} />
      </div>
    )
  }

  const tabs: Tab[] = [
    {
      id: 'companies',
      component: (
        <>
          <FilterWrapper>
            <Filters
              setFilters={setFilters}
              filters={filters}
              allTags={allTags}
              allStages={allStages}
            />
          </FilterWrapper>
          {filterSelected && filteredCompanies.length === 0 ? (
            <Typography className="text-center">No results found</Typography>
          ) : (
            <div ref={wrapperRef} style={{ height: tableHeight, width: '100%' }}>
              <AutoSizer>
                {({ width, height }) => {
                  return (
                    <div>
                      <List
                        width={width}
                        height={height}
                        rowHeight={cache.rowHeight}
                        deferredMeasurementCache={cache}
                        rowCount={filteredCompanies.length}
                        overscanRowCount={5}
                        rowRenderer={({ key, index, style, parent }) => {
                          const company = filteredCompanies[index]
                          return (
                            <CellMeasurer
                              key={key}
                              cache={cache}
                              parent={parent}
                              columnIndex={0}
                              rowIndex={index}
                            >
                              <div style={style}>
                                <CompanyCard
                                  company={company}
                                  key={company.uuid}
                                  requestIntroState={requestIntroState}
                                  companyUuid={company.uuid}
                                  openRequestIntroModal={openRequestIntroModal}
                                />
                              </div>
                            </CellMeasurer>
                          )
                        }}
                      />
                    </div>
                  )
                }}
              </AutoSizer>
            </div>
          )}
        </>
      ),
      label: (
        <>
          <i className="fas fa-building mr-2"></i>Companies
        </>
      ),
    },
  ]

  return (
    <>
      <Tabs data={tabs} hideTabs defaultTab="companies" sticky={true} pushSubRoutes={false} />
    </>
  )
}

export default MemberView

import React, { useEffect, useState } from 'react'

import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import { SeeMore } from 'containers/CandidateProfile'
import { FacilitateIntroModal } from 'containers/Portfolio/MemberView/FacilitateIntroModal'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { cn } from 'utils/styles'
import { CandidateProfile } from 'utils/types'

interface Props {
  globalPerson: any
  candidateProfile?: CandidateProfile
  isCandidate?: boolean
}

const CandidateHeader: React.FC<Props> = ({ globalPerson, candidateProfile, isCandidate }) => {
  const hl =
    globalPerson.company_name && globalPerson.position
      ? `${globalPerson.position} at ${globalPerson.company_name}`
      : globalPerson.headline
  const headlineLength = hl?.length || 0
  const [isExpanded, setIsExpanded] = useState(headlineLength < 165 || false)
  const headline = isExpanded ? hl : `${hl?.slice(0, 165)}`
  const requestable = {
    ...globalPerson,
    type: globalPerson.type || globalPerson.itemType || 'GlobalPerson',
  }
  const showIntro = useSearchParam('intro')
  const history = useHistory()

  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const user = useCurrentUser().user
  const { showModal } = useModal()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)

  const { list_id } = useParams<{ list_id: string }>()

  const canUseExternalIntroRequest =
    team?.features?.external_intro_request && !(user?.team?.slug === teamSlug)

  const companyListUuid = list_id || candidateProfile?.company_list_uuids[0]

  const companyListQuery = useQuery(['getCompanyList', teamSlug, companyListUuid], () =>
    callApi(api.getCompanyList, teamSlug, companyListUuid, false, false),
  )

  const companyList = companyListQuery?.data?.company_list
  const ctaType = companyList?.cta_type

  useEffect(() => {
    if (companyList && showIntro && canUseExternalIntroRequest) {
      openRequestIntroModal()
      history.replace({ search: '' })
    }
  }, [companyList])

  const openRequestIntroModal = () => {
    showModal(
      (resolve) => (
        <FacilitateIntroModal
          requestable={requestable}
          companyList={companyList}
          resolve={resolve}
          gatekeeperId={companyList?.gatekeeper_id}
          facilitator={team}
          includeInitialNote={false}
          requestType={'external'}
          networkingOnly={
            candidateProfile && candidateProfile.opportunity_type
              ? candidateProfile.opportunity_type.length === 1 &&
                candidateProfile.opportunity_type[0] === 'networking'
              : undefined
          }
        />
      ),
      'facilitate-intro-modal',
    )
  }

  return (
    <div className="mt-4 flex items-center justify-between">
      <div
        className={cn('flex ', {
          'items-end': headlineLength < 165,
          'items-start': headlineLength > 165,
        })}
      >
        <div className="flex flex-shrink-0">
          {history.length > 1 && (
            <Typography
              component="button"
              color="fog"
              fontSize="12"
              data-testid="item-back-button"
              className="hover:text-[#5C69D1] mr-4"
              onClick={() => {
                history.goBack()
              }}
            >
              <i className="far fa-chevron-left" />
            </Typography>
          )}
          <Avatar src={globalPerson.image_url} name={globalPerson.name} size={'72px'} />
        </div>
        <div className="flex flex-col ml-3">
          <div className="flex items-baseline gap-2">
            <Typography lineHeight={1.25} fontSize="36" fontWeight={600}>
              {globalPerson.name}
            </Typography>
            <Typography fontSize="20" color="purple" lineHeight={1}>
              <i className="fa fa-badge-check fa-fw -mr-1" />
            </Typography>
            {(candidateProfile?.linkedin_url || globalPerson?.linkedin_url) && (
              <Typography
                component="a"
                color="fog"
                fontSize="20"
                href={candidateProfile?.linkedin_url || globalPerson.linkedin_url}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </Typography>
            )}
          </div>
          {headline && (
            <p className="leading-5">
              <Typography fontSize="16" color="fog" lineHeight={1} className="mt-1">
                {headline}
              </Typography>
              {headline.length > 165 && (
                <SeeMore
                  className="ml-1 cursor-pointer"
                  color="fog"
                  fontSize="16"
                  lineHeight={1}
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? '...see less' : '...see more'}
                </SeeMore>
              )}
            </p>
          )}
        </div>
      </div>
      <div className="flex-shrink-0">
        {ctaType === 'request_intro' &&
          canUseExternalIntroRequest &&
          !isAdminOrEmployee &&
          !isCandidate && (
            <CabalButton
              onClick={() => {
                openRequestIntroModal()
              }}
            >
              Request intro
            </CabalButton>
          )}
      </div>
    </div>
  )
}

export default CandidateHeader

import React from 'react'

import ListSharingSettings from 'components/EditList/EditListAccess/ListSharingSettings'
import CTAs, { CTAOption } from 'containers/ListIndexView/Components/CTAs'
import { useResourcesListContext } from 'containers/ListIndexView/ResourcesList'
import ResourceSettingsModal from 'containers/ListIndexView/ResourcesList/ResourceSettingsModal'
import { RenderModal, useModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'

interface Props {
  onShowAddWidget: () => void
}

const ResourcesListCTAs: React.FC<Props> = ({ onShowAddWidget }) => {
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()

  const {
    companyListData: companyList,
    refetchCompanyList,
    view,
    setView,
    adminView,
  } = useResourcesListContext()

  const renderSettingsModal: RenderModal = (resolve) => (
    <ResourceSettingsModal
      teamSlug={teamSlug}
      onHide={() => resolve(false)}
      companyList={companyList}
      reload={refetchCompanyList}
    />
  )

  const renderShareListModal: RenderModal = (resolve) => (
    <ListSharingSettings
      teamSlug={teamSlug}
      resolve={() => resolve(false)}
      uuid={companyList?.uuid}
      reload={refetchCompanyList}
      header={`Share ${companyList?.name}`}
    />
  )

  const options = [
    !!adminView && {
      label: (
        <Typography className="flex gap-2 items-center">
          <i className="far fa-plus" />
          Add
        </Typography>
      ),
      onClick: () => onShowAddWidget(),
    },
    {
      label: (
        <Typography className="flex gap-2 items-center">
          {view === 'list' ? (
            <>
              <i className="far fa-grid" />
              Grid
            </>
          ) : (
            <>
              <i className="far fa-list" />
              List
            </>
          )}
        </Typography>
      ),
      onClick: () => setView(view === 'list' ? 'grid' : 'list'),
    },

    !!adminView && {
      label: (
        <Typography className="flex gap-2 items-center">
          <i className="far fa-arrow-up-from-bracket" />
          Share
        </Typography>
      ),
      onClick: () => showModal(renderShareListModal, 'render_share_list_modal'),
    },
    !!adminView && {
      label: (
        <Typography className="flex gap-2 items-center">
          <i className="far fa-gear" />
        </Typography>
      ),
      onClick: () => showModal(renderSettingsModal, 'render_resource_settings_modal'),
    },
  ].filter(Boolean)

  return <CTAs CTAOptions={options as CTAOption[]} />
}

export default ResourcesListCTAs

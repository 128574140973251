import { DowncastWriter, Element, Model, Plugin, Position, Text } from 'ckeditor5'
import { toWidget, viewToModelPositionOutsideModelElement } from 'ckeditor5'

import { Team } from 'utils/types'

const variableRegex = new RegExp(/^(.*)({{[\w_\-:]+}})(.*)$/)

export default class VariableHighlight extends Plugin {
  init() {
    const editor = this.editor
    const flags: Team['flags'] = editor.config.get('flags')

    const schema = editor.model.schema
    const model = editor.model
    const conversion = editor.conversion

    schema.register('variable', {
      allowWhere: '$text',
      isInline: true,
      isObject: true,
      allowAttributesOf: '$text',
    })

    conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        classes: ['variable'],
      },
      model: (viewElement, { writer: modelWriter }) => {
        const variableName = (viewElement.getChild(0) as unknown as Text | undefined)?.data || ''

        const el = modelWriter.createElement('variable')
        modelWriter.insertText(variableName, el)

        return el
      },
    })

    conversion.for('editingDowncast').elementToElement({
      model: 'variable',
      view: (modelItem, { writer: viewWriter }) => {
        const widgetElement = createVariableView(modelItem, viewWriter)

        // Enable widget handling on a variable element inside the editing view.
        return toWidget(widgetElement, viewWriter)
      },
    })

    conversion.for('dataDowncast').elementToElement({
      model: 'variable',
      view: (modelItem, { writer: viewWriter }) => createVariableView(modelItem, viewWriter),
    })

    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(this.editor.model, (viewElement) =>
        viewElement.hasClass('variable'),
      ) as any,
    )

    // if (!!flags?.variable_highlight) {
    //   this.listenTo(model.document, 'change:data', () => {
    //     model.document.differ.getChanges().forEach((diff) => {
    //       if (diff.type === 'insert' || diff.type === 'remove') {
    //         wrapInVariableHighlightElement(diff.position, model)
    //       }
    //     })
    //   })
    // }

    function createVariableView(modelItem: Element, viewWriter: DowncastWriter) {
      const childText = (modelItem.getChild(0) as Text)?.data
      const attr = modelItem.getAttribute('name')

      const variableView = viewWriter.createContainerElement('span', {
        class: 'variable',
      })

      if (!childText && attr) {
        // Insert the variable name (as a text).
        const innerText = viewWriter.createText(attr)
        viewWriter.insert(viewWriter.createPositionAt(variableView, 0), innerText)
      }

      return variableView
    }
  }
}

const wrapInVariableHighlightElement = (pos: Position, model: Model) => {
  if (!pos) return

  const root = pos.root
  const el = pos.parent

  if (!el || el.is('variable') || (el as Element).name === 'variable') return

  const selectionChildren = []

  if (root === el) {
    selectionChildren.push(
      ...Array.from(el.getChildren()).flatMap((cel) => Array.from((cel as Element).getChildren())),
    )
  } else {
    selectionChildren.push(...Array.from(el.getChildren()))
    const nextPos = model.createPositionAfter(el as Element)
    selectionChildren.push(...Array.from(nextPos.parent.getChildren()))
  }

  selectionChildren.forEach((child) => {
    if (child.is('$text') && !child.is('variable')) {
      const textData = child.data

      const matches = variableRegex.exec(textData)
      if (matches === null || !matches[2]) return

      model.change((writer1) => {
        let pos: Position | null = writer1.createPositionBefore(child)
        writer1.remove(child)
        const text1 = writer1.createText(matches[1])

        if (!!matches[1]) {
          writer1.insert(text1, pos)
          pos = null
        }

        model.change((writer2) => {
          if (pos === null) {
            pos = writer2.createPositionAfter(text1)
          }
          const varEl = writer2.createElement('variable', { variable: matches[2] })
          writer2.insertText(matches[2], varEl)
          writer2.insert(varEl, pos)

          model.change((writer3) => {
            pos = writer3.createPositionAfter(varEl)

            if (!!matches[3]) {
              const text3 = writer3.createText(matches[3])
              writer3.insert(text3, pos)
            }

            writer3.setSelection(pos)
          })
        })
      })
    }
  })
}

import { Position } from 'ckeditor5'
import { compact, concat, flatten, get, isEqual } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { v4 as uuid } from 'uuid'

import Loading from 'global/Loading'
import { darkTheme } from 'global/darkTheme'
import { mainTheme } from 'global/theme'
import { useCurrentUser, useTeam } from 'store/hooks'

import ErrorLogger from 'utils/ErrorLogger'
import emojis from 'utils/constants/emoji'
import { CurrentUserProfile, Params, Team } from 'utils/types'

import { ClassicCKEditor, ClassicCollaborativeCKEditor, colors } from './base'
import { ImageUploadAdapter } from './plugins/ImageUploadAdapter'

export interface MentionSuggestion {
  id: number | string
  name: string
  extra?: string
  avatar?: string
}

const StyledLoading = styled(Loading)`
  ${tw`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
`

const Wrapper = styled.div<{ loading: boolean }>`
  ${tw`rounded-lg flex-1 flex-col flex z-10`}

  flex: 1 1 auto;
  min-height: 0px;

  contain: layout;
  ${({ loading }) => loading && `background: var(--ck-color-base-background);`}

  .ck.ck-reset.ck-editor {
    ${tw`flex-1`}

    min-height: 0px;
  }

  .ck-editor__main {
    ${tw`flex-1 overflow-y-auto rounded-t`}

    background: var(--ck-color-base-background);
  }
`

export interface TextEditorProps {
  windowKey?: string
  value?: string
  onChange?: (val: string) => void
  onTyping?: (isTyping: boolean) => void
  variables?: string[]
  allowVariables?: boolean
  allowSignature?: boolean
  companySlug?: string
  documentId?: string
  presenceListContainer?: HTMLDivElement
  onInit?: (ckeditor: ClassicCKEditor) => void
  height?: number
  fixHeightOnLoad?: boolean
  ckEditorRef?: React.MutableRefObject<ClassicCKEditor | undefined>
  minimal?: boolean
  mentionSuggestions?: MentionSuggestion[]
  placeholder?: string
  toolbarLeftButtons?: { label: string; command: string; icon: string }[]
  toolbarRightButtons?: { label: string; command: string; icon: string }[]
  onToolbarButtonClick?: (command: string, args?: any[]) => void
}

interface Props extends TextEditorProps {
  user: CurrentUserProfile
  team?: Team
}

interface State {
  loaded: boolean
  generation: number
  collab: boolean
}

class CKEditorClass extends React.Component<Props, State> {
  ckeditor?: ClassicCKEditor
  state: State = {
    collab: true,
    loaded: false,
    generation: 0,
  }
  lastReceivedValue?: string
  editorInstanceId = 'editor-instance-' + uuid()

  regenerationSnapshot?: {
    data: string
    position: Position | null
  }

  /*
  Changing one of these props should cause a full re-render and a
  re-instantiation of the CKEditor.
  */
  dirtyProps: (keyof Props)[] = ['allowVariables', 'companySlug', 'documentId', 'variables', 'team']

  /*
  Changing one of these props should cause a regular update. These are mostly
  props that act on the container, rather than the quillized editing area.
  */
  cleanProps: (keyof Props)[] = ['onChange']

  constructor(props: Props) {
    super(props)
  }

  get emojiFeed() {
    return {
      marker: ':',
      feed: (query: string) => {
        return emojis
          .filter((e) => e.shortname.includes(query))
          .splice(0, 10)
          .map((e) => ({
            id: e.shortname,
            text: e.emoji,
          }))
      },
      itemRenderer: (item: { id: string; text: string }) => {
        const itemElement = document.createElement('div')
        itemElement.innerHTML = `${item.text} ${item.id}`
        return itemElement
      },
      minimumCharacters: 0,
    }
  }

  get mentionFeed() {
    if (!this.props.mentionSuggestions?.length) return null

    return {
      marker: '@',
      feed: this.getMentionUsers.bind(this),
      itemRenderer: (item: {
        id: string
        text: string
        avatar_url?: string
        name: string
        extra?: string
      }) => {
        const itemElement = document.createElement('div')
        let html = `${item.name}`
        if (item.avatar_url) {
          html = `
            <div>
              <img
                src="${item.avatar_url}"
                class="rounded-full"
                style="width: 1rem; display: inline-block; margin-right: 5px"
              /> ${html}
            </div>
            <div style="font-size: 12px">
              ${item.extra}
            </div>
          `
        }
        itemElement.innerHTML = html
        return itemElement
      },
      minimumCharacters: 0,
    }
  }

  get variables() {
    return this.props.variables || ['{{first_name}}', '{{full_name}}']
  }

  getMentionUsers(query: string) {
    return this.props
      .mentionSuggestions!.filter((f) =>
        (f.name + f.extra).toLowerCase().includes(query.toLowerCase()),
      )
      .map((f) => ({
        id: `@${f.id}`,
        text: `@${f.name}`,
        name: f.name,
        extra: f.extra,
        avatar_url: f.avatar,
      }))
  }

  getUsers = () => {
    return this.props.team?.admins_and_members.map((f) => ({
      id: f.uuid,
      name: f.name,
      avatar: f.avatar_url,
    }))
  }

  onInit = () => {
    if (!this.ckeditor) return

    if (this.props.windowKey && window.QA) {
      window.CKE ||= {}
      window.CKE[this.props.windowKey] = this.ckeditor
    }

    if (this.props.ckEditorRef) {
      this.props.ckEditorRef.current = this.ckeditor
    }

    if (this.regenerationSnapshot) {
      const { data, position } = this.regenerationSnapshot
      this.ckeditor.data.set(data, {
        suppressErrorInCollaboration: true,
      } as any)
      position &&
        this.ckeditor.model.change((writer) => {
          writer.setSelectionFocus(position)
        })
      delete this.regenerationSnapshot
    }

    this.ckeditor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
      new ImageUploadAdapter(loader)

    this.ckeditor.editing.view.change((writer) => {
      const editorContent = this.ckeditor?.editing.view.document.getRoot()
      if (!editorContent) return

      writer.setStyle('min-height', this.props.minimal ? '50px' : '180px', editorContent)
    })

    if (this.state.loaded) {
      this.updateValue()
      this.props.onInit?.(this.ckeditor)
    }
  }

  initialize = () => {
    const editorInstance = document.getElementById(this.editorInstanceId)
    const EditorClass = this.useCollaborativeEditing
      ? ClassicCollaborativeCKEditor
      : ClassicCKEditor

    if (!editorInstance) return

    const config: Params = {
      companySlug: this.props.companySlug,
      link: {
        defaultProtocol: 'http://',
      },
      fontSize: {
        options: ['small', 'default', 'big', 'huge'],
      },
      placeholder: this.props.placeholder,
      extraButtons: {
        buttons: compact(concat(this.props.toolbarLeftButtons, this.props.toolbarRightButtons)),
        onClick: this.props.onToolbarButtonClick,
      },
      toolbar: this.props.minimal
        ? null
        : flatten(
            compact([
              this.props.toolbarLeftButtons?.map((f) => 'extra-button-' + f.command),
              ['undo', 'redo'],
              ['fontSize'],
              ['bold', 'italic', 'underline'],
              ['fontColor', 'fontBackgroundColor'],
              ['alignment', 'bulletedList', 'numberedList'],
              ['link', 'imageInsert'],
              ['variables', 'signature'],
              ['removeFormat'],
              this.props.toolbarRightButtons?.map((f) => 'extra-button-' + f.command),
            ]).reduce<string[][]>((prev, curr) => [...prev, ['|'], curr], []),
          ),
      fontBackgroundColor: {
        colors: colors,
        columns: 9,
      },
      fontColor: {
        colors: colors,
        columns: 9,
      },
      language: 'en',
      image: {
        styles: ['alignLeft', 'alignCenter', 'alignRight'],
        resizeUnit: 'px',
        resizeOptions: [
          {
            name: 'resizeImage:original',
            label: 'Original',
            value: null,
          },
          {
            name: 'resizeImage:300',
            label: '300px',
            value: '300',
          },
          {
            name: 'resizeImage:500',
            label: '500px',
            value: '500',
          },
        ],
        toolbar: [
          'imageStyle:inline',
          'imageStyle:wrapText',
          'imageStyle:breakText',
          '|',
          'resizeImage',
          'toggleImageCaption',
          'imageTextAlternative',
          // '|',
          // 'comment',
        ],
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableCellProperties',
          'tableProperties',
        ],
      },
      variables: this.props.allowVariables && this.variables,
      signature: this.props.allowSignature && (this.props.user.email_signature || ''),
      userId: this.props.user.uuid,
      user: {
        id: this.props.user.uuid,
        getUsers: this.getUsers.bind(this),
      },
      mention: {
        feeds: compact([this.emojiFeed, this.mentionFeed]),
      },
      paste_handler: {
        unset_color: true,
        dark_bg: darkTheme.layout.main_bg_color,
        light_bg: mainTheme.layout.main_bg_color,
        light_text: mainTheme.colors.primary,
        dark_text: darkTheme.colors.primary,
      },
      ...(this.props.documentId
        ? {
            licenseKey: '+IkuyHtXpSobpcgDvOVNekRS5XIflsZ7ztrv11KrxBIKVtk7dCCR/1HfMw==',
            cloudServices: {
              tokenUrl: `/api/ckeditor/${this.props.documentId}_v32?team_slug=${this.props.companySlug}`,
              uploadUrl: 'https://cke.getcabal.com/easyimage/upload/',
              webSocketUrl: `wss://cke.getcabal.com/ws`,
            },
            collaboration: {
              channelId: `${this.props.documentId}_v32`,
            },
            // comments: {
            //   editorConfig: {
            //     extraPlugins: [Mention],
            //     mention: {
            //       feeds: [this.mentionFeed, this.emojiFeed],
            //     },
            //   },
            // },
            presenceList: {
              container:
                this.props.presenceListContainer ||
                document.querySelector(`.default-presence-list-container`),
            },
          }
        : {}),
      flags: this.props.team?.flags,
    }

    this.ckeditor?.destroy()
    editorInstance.innerHTML = ''

    EditorClass.create(editorInstance, config)
      .then((editor) => {
        this.ckeditor = editor

        this.setState({
          loaded: true,
        })

        this.onInit()

        this.ckeditor.model.document.on('change:data', () => {
          this.props.onChange?.(this.ckeditor!.getData())
        })
      })
      .catch((err) => {
        if (
          err.message.includes('cloudservices-init') ||
          err.message.includes('cloudservices-reconnection')
        ) {
          this.setState(
            {
              collab: false,
            },
            this.initialize,
          )
        } else {
          ErrorLogger.error(err)
        }
      })
  }

  updateValue = (prev?: string, next = this.props.value) => {
    if (next !== prev) {
      this.ckeditor?.data?.set?.(next || '', {
        suppressErrorInCollaboration: true,
      } as any)
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (!this.ckeditor) {
      return true
    }

    if ('value' in nextProps && this.lastReceivedValue !== nextProps.value) {
      this.lastReceivedValue = nextProps.value
      const prevContents = this.ckeditor.getData()
      this.updateValue(prevContents, nextProps.value)
    }

    if (nextProps.user.email_signature !== this.props.user.email_signature) {
      this.ckeditor.plugins.get('signature').init?.()
      this.ckeditor.config.set('signature', nextProps.user.email_signature)
    }

    if (nextProps.height && nextProps.height !== this.props.height) {
      this.updateContentHeight(nextProps.height)
    }

    return (
      !isEqual(nextState, this.state) ||
      this.dirtyProps.some((prop) => {
        return !isEqual(get(nextProps, prop), get(this.props, prop))
      })
    )
  }

  updateContentHeight = (height: number) => {
    this.ckeditor?.editing.view.change((writer) => {
      const editorContent = this.ckeditor?.editing.view.document.getRoot()
      if (!editorContent) return

      writer.setStyle('min-height', `${height}px`, editorContent)
      writer.setStyle('max-height', `${height}px`, editorContent)
    })
  }

  shouldComponentRegenerate(prevProps: Props, prevState: State): boolean {
    // Whenever a `dirtyProp` changes, the editor needs reinstantiation.
    return this.dirtyProps.some((prop) => {
      const result = !isEqual(prevProps[prop], this.props[prop])

      return result
    })
  }

  componentDidMount() {
    this.initialize()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // If we're changing one of the `dirtyProps`, the entire CKEditor needs
    // to be re-instantiated. Regenerating the editor will cause the whole tree,
    // including the container, to be cleaned up and re-rendered from scratch.
    // Store the contents so they can be restored later.
    if (this.ckeditor && this.shouldComponentRegenerate(prevProps, prevState)) {
      this.initialize()
    }
  }

  get useCollaborativeEditing() {
    if (this.props.user?.flags?.disable_collab_editing) {
      return false
    }

    return this.props.documentId && this.state.collab
  }

  render = () => {
    return (
      <Wrapper loading={!this.state.loaded}>
        {!this.state.loaded && <StyledLoading />}
        <div className="default-presence-list-container hidden" />
        <div id={this.editorInstanceId} />
        {!this.state.collab && <i className="far fa-signal-slash absolute bottom-2 right-2" />}
      </Wrapper>
    )
  }
}

const CKEditor: React.VFC<TextEditorProps> = React.memo((props) => {
  const { user } = useCurrentUser()
  const { team } = useTeam(props.companySlug || null)

  return <CKEditorClass user={user} team={team} {...props} />
}, isEqual)

export default CKEditor

import React from 'react'

import Skeleton from 'react-loading-skeleton'

import DataCardV2 from 'ui-components/DataCardV2'
import GridWrapper from 'ui-components/GridWrapper'

import { cn } from 'utils/styles'

import 'react-loading-skeleton/dist/skeleton.css'

interface Props {
  view: 'list' | 'grid'
  itemCount: number
}

const SkeletonDataCard = ({ view, itemCount }: Props) => {
  const Card = () => {
    const isGrid = view === 'grid'
    const avatar = isGrid ? (
      <Skeleton circle height={72} width={72} />
    ) : (
      <Skeleton circle height={32} width={32} />
    )
    return (
      <DataCardV2
        title={<Skeleton height={isGrid ? 32 : 16} width={150} />}
        description={<Skeleton height={isGrid ? 16 : 14} width={200} containerClassName="flex-1" />}
        avatar={avatar}
        view={view}
        className={cn({
          'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded':
            !isGrid,
        })}
        cta={<Skeleton height={20} width={80} />}
      />
    )
  }

  return (
    <div>
      {view === 'grid' && (
        <GridWrapper>
          {Array.from({ length: itemCount }).map((_, index) => (
            <Card key={index} />
          ))}
        </GridWrapper>
      )}
      {view === 'list' && (
        <>
          {Array.from({ length: itemCount }).map((_, index) => (
            <Card key={index} />
          ))}
        </>
      )}
    </div>
  )
}

export default SkeletonDataCard

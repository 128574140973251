import React from 'react'

import { CKEditor as ReactCKEditor } from '@ckeditor/ckeditor5-react'

import axios from 'axios'
import { ClassicEditor } from 'ckeditor5'
import { EditorConfig, FileLoader } from 'ckeditor5'
import classNames from 'classnames'
import compact from 'lodash/compact'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import S3Upload from 'react-s3-uploader/s3upload'
import styled from 'styled-components'
import tw from 'twin.macro'
import { v4 as uuid } from 'uuid'

import Loading from 'global/Loading'
import { darkTheme } from 'global/darkTheme'
import { mainTheme } from 'global/theme'

import ErrorLogger from 'utils/ErrorLogger'
import api from 'utils/api'
import emojis from 'utils/constants/emoji'
import { CurrentUserProfile, Team } from 'utils/types'

import { ClassicCKEditor, ClassicCollaborativeCKEditor, colors } from './base'

export interface MentionSuggestion {
  id: number | string
  name: string
  extra?: string
  avatar?: string
}

const StyledLoading = styled(Loading)`
  ${tw`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
`

const Wrapper = styled.div<{ loading: boolean; minimal: boolean }>`
  ${tw`rounded-lg flex-1 flex-col flex z-10`}

  flex: 1 1 auto;
  min-height: 0px;

  contain: layout;
  ${({ loading }) => loading && `background: var(--ck-color-base-background);`}

  .ck.ck-reset.ck-editor {
    ${tw`flex-1`}

    min-height: 0px;
  }

  .ck-editor__main {
    ${tw`flex-1 overflow-y-auto rounded-t`}

    background: var(--ck-color-base-background);
  }

  .ck-placeholder:before {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.placeholder};
  }

  ${({ minimal }) =>
    minimal &&
    `
    .ck.ck-editor__top {
      display: none;
    }
  `}
`

class ImageUploadAdapter {
  loader: FileLoader

  constructor(loader: FileLoader) {
    this.loader = loader
  }

  async _onUploadFinish(data: { signedUrl: string }, file: File) {
    const url = data.signedUrl

    const {
      data: { uuid },
    } = await axios.post(`/api/uploads`, {
      url,
      name: file.name,
      size: file.size,
      mime_type: file.type,
    })

    return `${location.protocol}//${location.host}/api/uploads/${uuid}?cached=1`
  }

  async upload() {
    return this.loader.file.then(
      (file: File | null) =>
        new Promise<{ default: string }>((resolve, reject) => {
          if (!file) throw 'no file to upload'

          if (window.QA) {
            api.createQaUpload(file).then(({ data }) => {
              resolve({ default: data.api_url })
            })
            return
          }

          new S3Upload({
            files: [file],
            signingUrl: `/api/uploads/new`,
            signingUrlMethod: 'GET',
            contentDisposition: 'attachment',
            onProgress: (percent) => {
              this.loader.uploadTotal = 100
              this.loader.uploaded = percent < 90 ? percent : 90
            },
            onError: (err) => {
              reject(err)
            },
            onFinishS3Put: async (data: { signedUrl: string }) => {
              try {
                resolve({
                  default: await this._onUploadFinish(data, file),
                })
              } catch (e) {
                reject(e)
              }
            },
            uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
            server: '',
            scrubFilename: (filename) => {
              return filename.replace(/[^\w\d_\-\.]+/gi, '')
            },
          })
        }),
    )
  }

  abort() {
    return
  }
}

export interface TextEditorProps {
  windowKey?: string
  value?: string
  onChange?: (val: string) => void
  onTyping?: (isTyping: boolean) => void
  variables?: string[]
  allowVariables?: boolean
  allowSignature?: boolean
  companySlug?: string
  message_uuid?: string
  presenceListContainer?: HTMLDivElement
  onInit?: () => void
  height?: number
  fixHeightOnLoad?: boolean
  ckEditorRef?: React.MutableRefObject<ClassicCKEditor | undefined>
  minimal?: boolean
  mentionSuggestions?: MentionSuggestion[]
  placeholder?: string
  minHeight?: string
}

interface Props extends TextEditorProps {
  user?: CurrentUserProfile
  team?: Team
}

interface State {
  loaded: boolean
  generation: number
  config?: EditorConfig
  editorId: string
  disabled: boolean
  mounted: boolean
}

class CKEditor extends React.Component<Props, State> {
  ckeditor?: ClassicCKEditor
  state: State = {
    loaded: false,
    generation: 0,
    editorId: 'editor' + uuid().split('-')[0],
    disabled: true,
    mounted: true,
  }
  lastReceivedValue?: string
  checkIfOnChangeWasCalledInterval: number | undefined
  lastOnChangeData?: string

  static defaultProps: Partial<Props> = {
    minHeight: '50px',
  }

  /*
  Changing one of these props should cause a full re-render and a
  re-instantiation of the CKEditor.
  */
  dirtyProps: (keyof Props)[] = [
    'allowVariables',
    'companySlug',
    'message_uuid',
    'variables',
    'team',
    'presenceListContainer',
  ]

  /*
  Changing one of these props should cause a regular update. These are mostly
  props that act on the container, rather than the quillized editing area.
  */
  cleanProps: (keyof Props)[] = ['onChange']

  constructor(props: Props) {
    super(props)
  }

  get emojiFeed() {
    return {
      marker: ':',
      feed: (query: string) => {
        return emojis
          .filter((e) => e.shortname.includes(query))
          .splice(0, 10)
          .map((e) => ({
            id: e.shortname,
            text: e.emoji,
          }))
      },
      itemRenderer: (item: { id: string; text: string }) => {
        const itemElement = document.createElement('div')
        itemElement.innerHTML = `${item.text} ${item.id}`
        return itemElement
      },
      minimumCharacters: 0,
    }
  }

  get mentionFeed() {
    if (!this.props.mentionSuggestions?.length) return null

    return {
      marker: '@',
      feed: this.getMentionUsers.bind(this),
      itemRenderer: (item: {
        id: string
        text: string
        avatar_url?: string
        name: string
        extra?: string
      }) => {
        const itemElement = document.createElement('div')
        let html = `${item.name}`
        if (item.avatar_url) {
          html = `
            <div>
              <img
                src="${item.avatar_url}"
                class="rounded-full"
                style="width: 1rem; display: inline-block; margin-right: 5px"
              /> ${html}
            </div>
            <div style="font-size: 12px">
              ${item.extra}
            </div>
          `
        }
        itemElement.innerHTML = html
        return itemElement
      },
      minimumCharacters: 0,
    }
  }

  get variables() {
    return this.props.variables || ['{{first_name}}', '{{full_name}}']
  }

  getMentionUsers(query: string) {
    return this.props
      .mentionSuggestions!.filter((f) =>
        (f.name + f.extra).toLowerCase().includes(query.toLowerCase()),
      )
      .map((f) => ({
        id: `@${f.id}`,
        text: `@${f.name}`,
        name: f.name,
        extra: f.extra,
        avatar_url: f.avatar,
      }))
  }

  getUsers = () => {
    return this.props.team?.admins_and_members.map((f) => ({
      id: f.uuid,
      name: f.name,
      avatar: f.avatar_url,
    }))
  }

  checkIfOnChangeWasCalled = () => {
    // in case the component is unmounted
    if (!this || this.state.disabled || !this.state.mounted) return

    const { ckeditor } = this

    if (!ckeditor) {
      // cabalToast({
      //   content:
      //     `There was a system error saving your message.` +
      //     ` Please copy the contents of your message and refresh the page.`,
      //   style: 'error',
      // })
      ErrorLogger.error('CKEditor is not defined')
      return
    }

    const data = ckeditor.data.get()

    if (!data && !this.lastOnChangeData) return

    if (this.lastOnChangeData !== data) {
      this.setState({ disabled: true })

      // cabalToast({
      //   content:
      //     `There was a system error saving your message.` +
      //     ` Please copy the contents of your message and refresh the page.`,
      //   style: 'error',
      // })
      ErrorLogger.error('CKEditor onChange was not called', {
        lastData: this.lastOnChangeData,
        currentData: data,
      })
      // window.Intercom?.(
      //   'showNewMessage',
      //   'Hi, I was just alerted about the message not saving, please assist.',
      // )
    }
  }

  onInit = (editor: ClassicCKEditor) => {
    if (!editor || editor.config.get('cke_editor_id') !== this.state.config?.cke_editor_id) return
    this.ckeditor = editor

    if (this.props.windowKey && window.QA) {
      window.CKE ||= {}
      window.CKE[this.props.windowKey] = editor
    }

    if (this.props.ckEditorRef) {
      this.props.ckEditorRef.current = this.ckeditor
    }

    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
      new ImageUploadAdapter(loader)

    editor.editing.view.change((writer) => {
      const editorContent = editor.editing.view.document.getRoot()
      if (!editorContent) return

      writer.setStyle(
        'min-height',
        this.props.minHeight || (this.props.minimal ? '50px' : '180px'),
        editorContent,
      )
      writer.setStyle('height', '100%', editorContent)
      // writer.setStyle('max-height', '60vh', editorContent)
    })

    // const annotationPlugin = editor.plugins.get('AnnotationsUIs') as any
    // annotationPlugin.switchTo('narrowSidebar')

    if (!this.state.loaded) {
      this.setState({ loaded: true, disabled: false }, () => {
        this.updateValue()
      })
      this.props.onInit?.()
    }
  }

  get messageUuid() {
    if (window.QA) return

    return this.props.message_uuid
  }

  setConfig = () => {
    const editorId = 'editor-' + uuid().split('-')[0]
    const config: EditorConfig = {
      companySlug: this.props.companySlug,
      link: {
        defaultProtocol: 'http://',
      },
      fontSize: {
        options: ['small', 'default', 'big', 'huge'],
      },
      placeholder: this.props.placeholder,
      toolbar: this.props.minimal
        ? undefined
        : [
            'undo',
            'redo',
            '|',
            'fontSize',
            '|',
            'bold',
            'italic',
            'underline',
            '|',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'alignment',
            'bulletedList',
            'numberedList',
            'indent',
            'outdent',
            '|',
            'link',
            'insertImage',
            '|',
            'variables',
            'signature',
            '|',
            'removeFormat',
          ],
      fontBackgroundColor: {
        colors: colors,
        columns: 9,
      },
      fontColor: {
        colors: colors,
        columns: 9,
      },
      language: 'en',
      image: {
        resizeUnit: 'px',
        resizeOptions: [
          {
            name: 'resizeImage:original',
            label: 'Original',
            value: null,
          },
          {
            name: 'resizeImage:300',
            label: '300px',
            value: '300',
          },
          {
            name: 'resizeImage:500',
            label: '500px',
            value: '500',
          },
        ],
        toolbar: [
          'imageStyle:inline',
          'imageStyle:wrapText',
          'imageStyle:breakText',
          '|',
          'resizeImage',
          'toggleImageCaption',
          'imageTextAlternative',
        ],
        insert: {
          integrations: ['upload', 'url'],
          type: 'inline',
        },
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableCellProperties',
          'tableProperties',
        ],
      },
      variables: this.props.allowVariables && this.variables,
      signature: this.props.allowSignature && (this.props.user?.email_signature || ''),
      userId: this.props.user?.uuid,
      user: {
        id: this.props.user?.uuid,
        getUsers: this.getUsers.bind(this),
      },
      mention: {
        feeds: compact([this.emojiFeed, this.mentionFeed]),
      },
      paste_handler: {
        unset_color: true,
        dark_bg: darkTheme.layout.main_bg_color,
        light_bg: mainTheme.layout.main_bg_color,
        light_text: mainTheme.colors.primary,
        dark_text: darkTheme.colors.primary,
      },
      ...(this.messageUuid
        ? {
            licenseKey: '+IkuyHtXpSobpcgDvOVNekRS5XIflsZ7ztrv11KrxBIKVtk7dCCR/1HfMw==',
            cloudServices: {
              tokenUrl: `/api/ckeditor/${this.props.message_uuid}_v32?team_slug=${this.props.companySlug}`,
              uploadUrl: 'https://cke.getcabal.com/easyimage/upload/',
              webSocketUrl: `wss://cke.getcabal.com/ws`,
            },
            collaboration: {
              channelId: `${this.props.message_uuid}_v32`,
            },
            presenceList: {
              container:
                this.props.presenceListContainer ||
                document.querySelector(`.default-presence-list-container`),
            },
          }
        : {}),
      flags: this.props.team?.features,
      cke_editor_id: editorId,
    }

    this.setState({
      editorId,
      config: config,
      loaded: false,
    })
  }

  updateValue = (prev?: string, next = this.props.value) => {
    if (next !== prev) {
      this.ckeditor?.data.set(next || '', {
        suppressErrorInCollaboration: true,
      } as any)
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (!this.ckeditor) {
      return true
    }

    if ('value' in nextProps && this.lastReceivedValue !== nextProps.value) {
      this.lastReceivedValue = nextProps.value
      const prevContents = this.ckeditor.getData()
      this.updateValue(prevContents, nextProps.value)
    }

    if (nextProps.user?.email_signature !== this.props.user?.email_signature) {
      this.ckeditor.plugins.get('signature').init?.()
      this.ckeditor.config.set('signature', nextProps.user?.email_signature)
    }

    if (nextProps.height && nextProps.height !== this.props.height) {
      this.updateContentHeight(nextProps.height)
    }

    return (
      !isEqual(nextState, this.state) ||
      [...this.cleanProps, ...this.dirtyProps].some((prop) => {
        return !isEqual(get(nextProps, prop), get(this.props, prop))
      })
    )
  }

  updateContentHeight = (height: number) => {
    this.ckeditor?.editing.view.change((writer) => {
      const editorContent = this.ckeditor?.editing.view.document.getRoot()
      if (!editorContent) return

      writer.setStyle('min-height', `${height}px`, editorContent)
      writer.setStyle('max-height', `${height}px`, editorContent)
    })
  }

  shouldComponentRegenerate(prevProps: Props): boolean {
    // Whenever a `dirtyProp` changes, the editor needs reinstantiation.
    if (this.state.config?.presenceList && this.props.presenceListContainer) {
      if ((this.state.config?.presenceList as any).container !== this.props.presenceListContainer) {
        return true
      }
    }
    return this.dirtyProps.some((prop) => {
      return !isEqual(prevProps[prop], this.props[prop])
    })
  }

  componentDidMount() {
    this.setConfig()
    this.checkIfOnChangeWasCalledInterval = window.setInterval(this.checkIfOnChangeWasCalled, 2000)
  }

  componentDidUpdate(prevProps: Props) {
    // If we're changing one of the `dirtyProps`, the entire CKEditor needs
    // to be re-instantiated. Regenerating the editor will cause the whole tree,
    // including the container, to be cleaned up and re-rendered from scratch.
    // Store the contents so they can be restored later.
    if (this.ckeditor && this.shouldComponentRegenerate(prevProps)) {
      this.setConfig()
    }
  }

  componentWillUnmount(): void {
    this.setState({ mounted: false })
    window.clearInterval(this.checkIfOnChangeWasCalledInterval)
  }

  handleWrapperEvents = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (this.state.disabled) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  render = () => {
    let editor = this.messageUuid ? ClassicCollaborativeCKEditor : ClassicCKEditor
    if (this.props.user?.flags?.disable_collab_editing) {
      editor = ClassicCKEditor
    }

    return (
      <Wrapper
        id={`${this.state.editorId}`}
        loading={!this.state.loaded}
        minimal={this.props.minimal}
        className={classNames({
          'cursor-not-allowed': this.state.disabled && this.state.loaded,
          'cursor-default': !this.state.loaded,
          'opacity-50': this.state.disabled,
        })}
        onClick={this.handleWrapperEvents}
        onKeyDown={this.handleWrapperEvents}
        onKeyUp={this.handleWrapperEvents}
        onMouseDown={this.handleWrapperEvents}
        onMouseUp={this.handleWrapperEvents}
        onKeyPress={this.handleWrapperEvents}
        onDragStart={this.handleWrapperEvents}
        onDragEnd={this.handleWrapperEvents}
        onDrag={this.handleWrapperEvents}
        onDragOver={this.handleWrapperEvents}
        onDragEnter={this.handleWrapperEvents}
        onDragLeave={this.handleWrapperEvents}
        onDrop={this.handleWrapperEvents}
        data-testid="ckeditor"
      >
        {!this.state.loaded && <StyledLoading data-testid="ckeditor-loading" />}
        <div className="default-presence-list-container hidden" />
        {this.state.config && (
          <ReactCKEditor
            id={this.state.editorId}
            editor={editor}
            data={this.props.value}
            config={this.state.config}
            disabled={this.state.disabled}
            onReady={this.onInit}
            onChange={(event: unknown, editor: ClassicEditor) => {
              const data = editor.data.get()
              this.props.onChange?.(data)
              this.lastOnChangeData = data
              // const range = editor.model.document.selection.getFirstRange()
              // editor.execute('selectAll')
              // editor.execute('fontColor', { value: null })
              // editor.execute('fontBackgroundColor', { value: null })
              // editor.execute('tableBackgroundColor', { value: null })
              // editor.execute('tableCellBackgroundColor', { value: null })
              // editor.model.change((writer: any) => {
              //   writer.setSelection(range)
              // })
            }}
            onError={(err: any) => {
              ErrorLogger.error(err)
              console.error(err)
            }}
          />
        )}
        {/* <div className="revision-viewer-container">
          <div className="flex">
            <div className="revision-viewer-editor flex-1" />
            <div className="revision-viewer-sidebar flex-initial" />
          </div>
        </div> */}
      </Wrapper>
    )
  }
}

export default CKEditor

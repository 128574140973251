import React from 'react'

import styled from 'styled-components'

import CrmIcon from 'components/CrmIcon'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

interface Props {
  onHide: () => void
  teamSlug: string
}

const OptionWrap = styled.div<{ active: boolean }>`
  background: ${({ theme }) => theme.colors.mist};
  border: 1px solid ${({ theme, active }) => (active ? theme.colors.purple : theme.colors.border)};

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.purple};
  }
`

const Option = ({
  onClick,
  title,
  icon,
  active,
  testId,
}: {
  onClick: () => void
  title: string | React.ReactNode
  icon: React.ReactNode
  active: boolean
  testId?: string
}) => {
  return (
    <OptionWrap
      active={active}
      className="flex flex-col justify-between cursor-pointer h-[120px] p-4 rounded-lg"
      onClick={onClick}
      data-testid={testId}
    >
      <Typography color={active ? 'purple' : 'fog'} fontSize="32">
        {icon}
      </Typography>
      <Typography
        color={active ? 'purple' : 'fog'}
        fontWeight={active ? 600 : 400}
        fontSize="14"
        className="mt-2"
        lineHeight={1.2}
      >
        {title}
      </Typography>
    </OptionWrap>
  )
}

const CrmSelectModal = ({ onHide, teamSlug }: Props) => {
  return (
    <Modal header="Connect CRM" onHide={onHide} show>
      <div>
        <Typography color="fog" fontSize="14">
          Select your CRM platform
        </Typography>
      </div>
      <div className="grid gap-1 sm:gap-4 grid-cols-3 my-6 mx-0">
        <Option
          icon={<CrmIcon crm="salesforce" style={{ fontSize: '32px' }} />}
          title="Connect Salesforce"
          onClick={() => {
            cabalToast({
              content: 'Redirecting you to Salesforce...',
              passive: true,
            })
            setTimeout(
              () =>
                window.location.assign(
                  `/salesforce_auth?return_to=/share-list/${teamSlug}/salesforce`,
                ),
              3000,
            )
          }}
          active={false}
        />
        <Option
          icon={<CrmIcon crm="hubspot" style={{ fontSize: '32px' }} />}
          title="Connect Hubspot"
          onClick={() => {
            cabalToast({
              content: 'Redirecting you to HubSpot...',
              passive: true,
            })
            setTimeout(
              () =>
                window.location.assign(`/auth/hubspot?return_to=/share-list/${teamSlug}/hubspot`),
              3000,
            )
          }}
          active={false}
        />
      </div>
    </Modal>
  )
}

export default CrmSelectModal

import React, { useState } from 'react'

import styled from 'styled-components'

import { IResourceDiscount } from 'containers/ListIndexView/ResourcesList/types'
import { WidgetTab } from 'containers/ListIndexView/TalentList/PeopleList/AddCandidate'
import Modal from 'global/Modal'

import CreateNewSection from './CreateNewSection'
import ImportSection from './ImportSection'

const TabWrapper = styled.div`
  border-bottom: ${({ theme }) => theme.border};
`

interface Props {
  onHide: () => void
  teamSlug: string
  item?: IResourceDiscount
  onSave: ({ resourceUuid, resource }) => void
  tags: string[]
  listUuid: string
  fetchListData: () => void
}

const ResourceDiscountAddModal = ({
  onHide,
  teamSlug,
  onSave,
  item,
  tags,
  listUuid,
  fetchListData,
}: Props) => {
  const [tab, setTab] = useState<string>('create_new')
  const [discount, setDiscount] = useState<Partial<IResourceDiscount>>(item || {})

  const tabs = [
    {
      key: 'create_new',
      label: 'Create new',
      iconClass: 'far fa-plus',
      component: (
        <CreateNewSection
          discount={discount}
          setDiscount={setDiscount}
          onSave={onSave}
          onHide={onHide}
          tags={tags}
        />
      ),
    },
    {
      key: 'import',
      label: 'Import',
      iconClass: 'far fa-file-csv',
      component: (
        <ImportSection setOnClose={onHide} listUuid={listUuid} fetchListData={fetchListData} />
      ),
    },
  ]
  return (
    <div>
      <Modal header="Add Discount" show={true} onHide={onHide} dangerouslyBypassFocusLock>
        <TabWrapper className="flex gap-8 mt-6">
          {tabs.map((tabConfig) => (
            <WidgetTab
              key={tabConfig.key}
              active={tab === tabConfig.key}
              onClick={() => setTab(tabConfig.key)}
            >
              <i className={`${tabConfig.iconClass} mr-1 fa-sm`} /> {tabConfig.label}
            </WidgetTab>
          ))}
        </TabWrapper>

        {tabs.map((tabConfig) => tab === tabConfig.key && tabConfig.component)}
      </Modal>
    </div>
  )
}

export default ResourceDiscountAddModal

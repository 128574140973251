import React, { useEffect, useState } from 'react'

import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import ActivityWidget from 'containers/CompanyHomeV2/ActivityWidget'
import Faqs from 'containers/CompanyHomeV2/Faqs'
import HomeCharts from 'containers/CompanyHomeV2/HomeCharts'
import InviteMembers from 'containers/CompanyHomeV2/InviteMembers'
import Metrics from 'containers/CompanyHomeV2/Metrics'
import ConnectionsWidget from 'containers/CompanyPage/ConnectionsWidget'
import { CreateSalesListModal } from 'containers/IntroRequestModal/components/Modals/Sales/CreateSalesListModal'
import { IRequestor, ISalesTarget } from 'containers/IntroRequestModal/types'
import { IntroConnection } from 'containers/Portfolio/MemberView/types/IntroConnection'
import { TextArea } from 'global/Input'
import Loading from 'global/Loading'
import Modal, { useModal } from 'global/Modal'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { useSlug } from 'utils/hooks/useSlug'

export const StyledTextArea = styled(TextArea)`
  ${tw`w-full`}
`

const CompanyHomeV2 = () => {
  const { isLoading: slugLoading, teamSlug } = useSlug()
  const { team, isLoading: teamLoading } = useTeam(teamSlug)
  const [connectionsShared, setConnectionsShared] = React.useState(false)
  const { user } = useCurrentUser()
  const { showModal, closeModal } = useModal()

  const isLoading = slugLoading || teamLoading

  if (isLoading) {
    return (
      <div className="my-10">
        <Loading />
      </div>
    )
  }

  const getGreeting = () => {
    let msg = ''

    const today = new Date()
    const curHr = today.getHours()

    if (curHr < 12) {
      msg += `Good morning, ${user.first_name} `
    } else if (curHr < 18) {
      msg += `Good afternoon, ${user.first_name} `
    } else {
      msg += `Good evening, ${user.first_name} `
    }

    return msg
  }

  const history = useHistory()
  const [facilitatorUuid, setFacilitatorUuid] = useState(null)
  const [requestableUuid, setRequestableUuid] = useState(null)
  const [messageUuid, setMessageUuid] = useState(null)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const facilitator = params.get('facilitator')
    const requestable = params.get('requestable')
    const message = params.get('message')

    if (facilitator) {
      setFacilitatorUuid(facilitator)
      params.delete('facilitator')
    }

    if (requestable) {
      setRequestableUuid(requestable)
      params.delete('requestable')
    }

    if (message) {
      setMessageUuid(message)
      params.delete('message')
    }

    if (facilitator && requestable && message) {
      history.replace({ search: params.toString() })
    }
  }, [history])

  const {
    data: actorsData,
    isSuccess: actorsFetchSuccess,
    isLoading: actorsFetchLoading,
  } = useQuery({
    queryKey: ['getIntroRequestActors', facilitatorUuid, requestableUuid],
    queryFn: () => callApi(api.getIntroRequestActors, facilitatorUuid, requestableUuid),
    enabled: !!facilitatorUuid && !!requestableUuid,
  })

  useEffect(() => {
    if (actorsFetchSuccess && facilitatorUuid && requestableUuid && messageUuid) {
      openIntroRequestModal(requestableUuid, facilitatorUuid, user)
    }
  }, [actorsFetchSuccess, facilitatorUuid, requestableUuid, messageUuid])

  const openIntroRequestModal = (
    requestable: ISalesTarget,
    facilitator: IntroConnection,
    requestor: IRequestor,
  ) => {
    if (!requestor || !facilitator || !requestable) {
      console.warn('Missing required parameters to open the modal.')
      return
    }

    showModal(
      () => (
        <CreateSalesListModal
          requestable={actorsData.requestable}
          facilitator={actorsData.facilitator}
          requestor={actorsData.requestor}
          companyListObject={null}
        />
      ),
      'create-sales-list-handler',
    )
  }

  return (
    <PageWrapper title={`${team?.name} home`}>
      <div className="mt-8">
        <Typography fontSize="28" fontWeight={600} component="p" lineHeight={1}>
          {getGreeting()}
        </Typography>
      </div>
      <div className="mt-6">
        <HomeCharts />
      </div>

      <div className="mt-8 flex justify-between">
        <div className="flex flex-col flex-1 gap-8 max-w-[630px]">
          {/* {showGettingStartedWidget && (
            <GettingStartedWidget teamSlug={teamSlug!} connectionsShared={connectionsShared} />
          )} */}
          {/* <ForYouWidget /> */}
          {/* <Metrics team={team!} /> */}
          <ActivityWidget />
        </div>
        <div className="w-80 flex-shrink-0 flex gap-8 flex-col">
          <ConnectionsWidget location="dashboard" setConnectionsShared={setConnectionsShared} />
          {/* {connectionsShared && <InviteMembers />} */}
          <Faqs teamSlug={teamSlug!} />
        </div>
      </div>
    </PageWrapper>
  )
}

export default CompanyHomeV2

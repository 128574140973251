import * as React from 'react'
import { useState } from 'react'

import isEqual from 'lodash/isEqual'
import styled from 'styled-components'
import tw from 'twin.macro'

import Filters from 'components/Filters'
import PageHeader from 'containers/ItemDetailPage/PageHeader'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import PageWrapper from 'global/PageWrapper'
import Table, { Column, Row, TrProps } from 'global/Table'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import Widget from 'ui-components/Widget'

import profile from 'utils/api/profile'
import { AdvisorModel } from 'utils/types'

interface Props {
  rows: any[]
  onClose: () => void
}

const ConnectionsTable: React.FC<Props> = React.memo(({ rows, onClose }) => {
  const [filters, setFilters] = useState('')
  const [showWidget, setShowWidget] = useState(false)
  const teamSlug = useTeamSlug()
  return (
    <PageWrapper title="My Connections">
      <PageHeader
        data={{
          title: 'My Connections',
        }}
        actions={{
          share: false,
          others: (
            <div className="inline-flex justify-end">
              {/* <CabalButton
                variant="link"
                leftIcon={<i className="far fa-plus fa-fw" />}
                onClick={() => setShowWidget(!showWidget)}
              >
                Add
              </CabalButton> */}

              <CabalButton
                variant="link"
                leftIcon={<i className="far fa-upload fa-fw" />}
                onClick={() => window.open('/addconnections', '_blank')}
              >
                Import Connections
              </CabalButton>
            </div>
          ),
        }}
        backUrl={`/${profile}`}
      />

      {/* {showWidget && (
        <Widget
          title="Add Connection"
          description="Fill in the details to add a new connection."
          cta={
            onClose && (
              <CabalButton
                variant="tertiary"
                onClick={(e) => {
                  e.preventDefault()
                  onClose()
                }}
                padding={'0'}
                leftIcon={<i className="far fa-times" />}
              ></CabalButton>
            )
          }
        ></Widget>
      )} */}

      <div className="flex-1 overflow-auto mt-2">
        {rows.map((row) => (
          <DataCardV2
            key={row.id}
            title={row.person.name}
            description={row.person.headline}
            avatar={<Avatar src={row.person.image_url || ''} name={row.person.name} />}
          />
        ))}
      </div>
    </PageWrapper>
  )
})

export default ConnectionsTable

import React, { useEffect } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { useMutation, useQuery } from 'react-query'

import Modal from 'global/Modal'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import useTeamSetting from 'utils/hooks/useTeamSetting'
import { InvestorCompany } from 'utils/types/investor'

import CompanyInfo from './CompanyInfo'
import ContactsSettings from './ContactsSettings'
import OptionsSection from './OptionsSection'

interface Props {
  onHide: () => void
  teamSlug: string
  refetchPortfolioList: () => void
  listUuid: string
  investor_company_uuids?: string
  name?: string
}

const PortfolioCompanyEditOrAddModal: React.FC<Props> = ({
  onHide,
  teamSlug,
  investor_company_uuids,
  refetchPortfolioList,
  listUuid,
  name,
}) => {
  const [step, setStep] = React.useState(1)
  const [parent] = useAutoAnimate()
  const [enablePortfolioJobs] = useTeamSetting(teamSlug, 'enable_portfolio_jobs')
  const [companyData, setCompanyData] = React.useState<InvestorCompany>({
    company_name: name || '',
    domain: '',
    tags: [],
  })

  const addCompanyMutation = useMutation(
    ['addCompany'],
    (portfolioCompany: InvestorCompany) => {
      return callApi(api.portfolioCreateCompany, portfolioCompany, listUuid)
    },
    {
      onSuccess: () => {
        refetchPortfolioList()
        cabalToast({ style: 'success', content: 'Company added successfully!' })
        onHide()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong while adding!' })
      },
    },
  )

  const updateCompanyMutation = useMutation(
    ['updateInvestorCompany', teamSlug],
    (portfolioCompany: InvestorCompany) => {
      return callApi(api.portfolioUpdateCompany, portfolioCompany, listUuid).then(() => {
        refetchPortfolioCompany()
        refetchPortfolioList()
      })
    },
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Company updated successfully!' })
        onHide()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong while updating!' })
      },
    },
  )

  const { mutate: deleteInvestorCompanyMutation, isLoading: isDeleting } = useMutation(
    ['deleteInvestorCompany', companyData?.uuid],
    () =>
      callApi(api.deleteInvestorCompany, companyData!.uuid, teamSlug).then(() => {
        refetchPortfolioList()
      }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Company successfully deleted' })
        onHide()
      },
    },
  )

  const {
    isFetching: isFetchingCompany,
    refetch: refetchPortfolioCompany,
    data: portfolioCompany,
  } = useQuery(
    ['getInvestorCompany', investor_company_uuids],
    () => callApi(api.getInvestorCompany, investor_company_uuids!, teamSlug),
    {
      onSuccess: (data) => {
        setCompanyData(data.company)
      },
      enabled: !!investor_company_uuids,
      cacheTime: 0,
    },
  )

  if (isFetchingCompany && investor_company_uuids) return null

  return (
    <div>
      <Modal onHide={onHide} show={true}>
        <Typography fontSize="12" fontWeight={600} className="mt-4 mb-2" component="p">
          {investor_company_uuids ? 'Edit Company' : 'Create Company'}
        </Typography>
        <OptionsSection step={step} setStep={setStep} />
        <div className="mt-7" ref={parent}>
          {step === 1 && (
            <CompanyInfo
              onHide={onHide}
              companyData={companyData}
              setCompanyData={setCompanyData}
              updateCompanyMutation={
                investor_company_uuids ? updateCompanyMutation : addCompanyMutation
              }
              deleteInvestorCompanyMutation={deleteInvestorCompanyMutation}
              isDeleting={isDeleting}
            />
          )}
          {step === 2 && (
            <ContactsSettings
              onHide={onHide}
              companyData={companyData}
              setCompanyData={setCompanyData}
              teamSlug={teamSlug}
              updateCompanyMutation={
                investor_company_uuids ? updateCompanyMutation : addCompanyMutation
              }
              deleteInvestorCompanyMutation={deleteInvestorCompanyMutation}
              isDeleting={isDeleting}
            />
          )}
        </div>
      </Modal>
    </div>
  )
}

export default PortfolioCompanyEditOrAddModal

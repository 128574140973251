import { Plugin } from 'ckeditor5'

import emojis from 'utils/constants/emoji'

export default class RemoveEmojiMentionsWrapper extends Plugin {
  init() {
    // The upcast converter will convert view <span class="mention" href="" data-user-id="">
    // elements to the model 'mention' text attribute.
    this.editor.conversion.for('upcast').elementToAttribute({
      view: {
        name: 'span',
        classes: 'mention',
      },
      model: {
        key: 'mention',
        value: (viewItem: Element) => {
          const mentionAttribute = this.editor.plugins
            .get('Mention')
            .toMentionAttribute(viewItem as any)

          return mentionAttribute
        },
      } as any,
      converterPriority: 'high',
    })

    // Downcast the model 'mention' text attribute to a view <span> element when it has emoji.
    this.editor.conversion.for('downcast').attributeToElement({
      model: 'mention',
      view: (modelAttributeValue: any, { writer }) => {
        // Do not convert empty attributes (lack of value means no mention).
        if (!modelAttributeValue || !emojis.find((e) => e.shortname === modelAttributeValue.id)) {
          return
        }

        return writer.createAttributeElement('span')
      },
      converterPriority: 'high',
    })
  }
}

import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import { SeeMore } from 'containers/CandidateProfile'
import { CreatePortfolioListModal } from 'containers/IntroRequestModal/components/Modals/Portfolio/CreatePortfolioListModal'
import PortfolioCompanyEditOrAddModal from 'containers/ListIndexView/PortfolioList/PortfolioCompanyEditOrAddModal'
import { FacilitateIntroModal } from 'containers/Portfolio/MemberView/FacilitateIntroModal'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'

import { cn } from 'utils/styles'
import { CompanyListBlueprint } from 'utils/types'
import { InvestorCompany } from 'utils/types/investor'

interface Props {
  company: InvestorCompany
  companyList: CompanyListBlueprint
  teamSlug: string
  companyUuid: string
  refetch: () => void
}

const PortfolioHeader: React.FC<Props> = ({
  company,
  companyList,
  teamSlug,
  companyUuid,
  refetch,
}) => {
  const history = useHistory()
  const { showModal } = useModal()
  const { team } = useTeam(teamSlug)
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const showIntro = useSearchParam('intro')
  const { user } = useCurrentUser()

  useEffect(() => {
    if (showIntro && !isAdminOrEmployee && companyList?.cta_type === 'request_intro') {
      showModal(renderOpenRequestIntroModal, 'create-portfolio-intro-modal')
      history.replace({ search: '' })
    }
  }, [])

  const networkingOnly = (company) => {
    if (company && company.opportunity_type) {
      const oppType = company.opportunity_type

      if (oppType.length === 1 && oppType[0] === 'networking') {
        return true
      }
    }
    return false
  }

  const renderEditPortfolioCompanyModal = (resolve: any) => {
    return (
      <PortfolioCompanyEditOrAddModal
        onHide={resolve}
        investor_company_uuids={companyUuid}
        teamSlug={teamSlug}
        refetchPortfolioList={refetch!}
        listUuid={companyList?.uuid}
      />
    )
  }

  const renderOpenRequestIntroModal = (resolve: any) => {
    return (
      <CreatePortfolioListModal
        requestable={{
          ...company,
          item_type: 'Company',
          uuid: company.company_uuid,
          name: company.company_name,
        }}
        requestor={user}
        initiator={user}
        companyList={companyList}
        resolve={resolve}
        facilitator={companyList?.owning_team}
      />
    )
  }

  return (
    <div className="mt-4 flex justify-between items-top">
      <div className="flex items-center ">
        <div className="flex items-center ml-2">
          <Typography component="button" color="fog" onClick={() => history.goBack()}>
            <i className="far fa-chevron-left"></i>
          </Typography>
        </div>
        <div className={cn('flex ml-3')}>
          <div className="flex flex-shrink-0">
            <Avatar
              src={
                company.logo_url ? company.logo_url : `https://logo.clearbit.com/${company.domain}`
              }
              name={company.company_name}
              size={'72px'}
            />
          </div>
          <div className="flex flex-col ml-3">
            <div className="flex items-baseline gap-2">
              <Typography
                lineHeight={1.25}
                fontSize="36"
                fontWeight={600}
                className={
                  company.team_slug
                    ? 'cursor-pointer hover:text-[#5c69d2] transition-colors duration-100'
                    : ''
                }
                onClick={() => {
                  if (company.team_slug) {
                    history.push(`/${company.team_slug}`)
                  }
                }}
              >
                {company.company_name}
              </Typography>
              {company.team_slug && (
                <Typography
                  className="cursor-pointer"
                  fontSize="20"
                  color="purple"
                  lineHeight={1}
                  onClick={() => {
                    history.push(`/${company.team_slug}`)
                  }}
                >
                  <i className="fa fa-badge-check fa-fw -mr-1" />
                </Typography>
              )}
              {company.linkedin_url && (
                <Typography
                  component="a"
                  color="fog"
                  fontSize="20"
                  href={company.linkedin_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin"></i>
                </Typography>
              )}
              {company.data?.twitter_url && (
                <Typography
                  component="a"
                  color="fog"
                  fontSize="20"
                  href={company.data?.twitter_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-x-twitter"></i>
                </Typography>
              )}
            </div>
            <div>
              {company.domain && (
                <CabalButton
                  variant="tertiary"
                  padding="0"
                  onClick={() => {
                    const formattedLink = !/^https?:\/\//i.test(company.domain)
                      ? `http://${company.domain}`
                      : company.domain
                    window.open(formattedLink, '_blank', 'noreferrer')
                  }}
                >
                  <Typography
                    fontSize="16"
                    color="fog"
                    lineHeight={1}
                    className="mt-1 hover:text-[#5c69d2] transition-colors duration-100"
                  >
                    {company.domain}
                  </Typography>
                </CabalButton>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        {isAdminOrEmployee && companyList?.owned && (
          <CabalButton
            variant="link"
            onClick={() =>
              showModal(renderEditPortfolioCompanyModal, 'render_edit_portfolio_company_modal')
            }
            leftIcon={<i className="far fa-pen"></i>}
          >
            Edit
          </CabalButton>
        )}
        {!isAdminOrEmployee && companyList?.cta_type === 'request_intro' && (
          <CabalButton
            variant="primary"
            onClick={() => showModal(renderOpenRequestIntroModal, 'create-portfolio-intro-modal')}
          >
            {companyList?.cta_type
              ?.split('_')
              ?.map((type) => type.charAt(0).toUpperCase() + type.slice(1))
              .join(' ')}{' '}
          </CabalButton>
        )}
      </div>
    </div>
  )
}

export default PortfolioHeader

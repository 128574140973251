import React from 'react'

import AddWidget from 'containers/ListIndexView/Components/AddWidget'
import AddIndividualSection from './AddIndividualSection'
import ImportSection
  from 'containers/ListIndexView/ResourcesList/ResourcePeopleList/ResourcePeopleAddWidget/ImportSection'

interface Props {
  onClose: () => void
  listUuid: string
  fetchListData: () => void
}

const ResourcePeopleAddWidget = ({ onClose, listUuid, fetchListData }: Props) => {
  const tabs = [
    {
      key: 'add_individually',
      label: 'Add Individually',
      iconClass: 'far fa-plus',
      component: <AddIndividualSection setOnClose={onClose} listUuid={listUuid} />,
    },
    {
      key: 'import',
      label: 'Import',
      iconClass: 'far fa-file-csv',
      component: (
        <ImportSection setOnClose={onClose} listUuid={listUuid} fetchListData={fetchListData} />
      ),
    },
  ]

  return <AddWidget onClose={onClose} tabs={tabs} title="Add person" />
}

export default ResourcePeopleAddWidget

import React, { useEffect } from 'react'

import cx from 'classnames'
import { sanitize } from 'dompurify'
import pluralize from 'pluralize'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import PortfolioDataTags from 'containers/CompanyPage/SharedListsSection/PortfolioDataTags'
import { CreatePortfolioListModal } from 'containers/IntroRequestModal/components/Modals/Portfolio/CreatePortfolioListModal'
import ListOtherGridModal from 'containers/ListView/Companies/ListOtherGridModal'
import { FacilitateIntroModal } from 'containers/Portfolio/MemberView/FacilitateIntroModal'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'

import avatars from 'utils/DefaultPersonAvatars'
import api, { callApi } from 'utils/api'
import { htmlTextContent } from 'utils/html'
import { cn } from 'utils/styles'
import { CompanyBlueprintWithConnections, CompanyListBlueprint } from 'utils/types'
import { formatCompact } from 'utils/ui'

import CandidateDataTags from './CandidateDataTags'
import ConnectionRow from './ConnectionRow'

const GridCard = styled.div`
  ${tw`my-2 p-2 rounded-lg gap-2 shadow-sm hover:shadow-md`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: 1px solid ${({ theme }) => theme.pill.border};
`

const DefaultAvatar = styled(Typography)`
  background-color: ${({ theme }) => theme.colors.mist};
`

const StyledClock = styled.i`
  color: ${({ theme }) => theme.colors.fog};
`

interface Props {
  company: CompanyBlueprintWithConnections
  teamSlug: string
  peopleVotes?: Record<number, any>
  companyVotes?: Record<number, any>
  people: any[]
  listUuid: string
  icps: string[]
  gridView?: boolean
  listSlug: string
  advisorPreview?: boolean
  companyList?: CompanyListBlueprint
  isTalent?: boolean
  advisorListView?: boolean
  refetchLists?: () => void
}

const CompanyCard: React.FC<Props> = ({
  company,
  teamSlug,
  peopleVotes = {},
  companyVotes,
  advisorPreview,
  people,
  listUuid,
  icps,
  gridView,
  listSlug,
  companyList,
  isTalent,
  advisorListView,
  refetchLists,
}) => {
  const history = useHistory()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const { showModal, closeModal } = useModal()
  const { team } = useTeam(teamSlug)
  const [cutOff, setCutOff] = React.useState(advisorPreview ? 5 : gridView ? 4 : 3)
  const [vote, setVote] = React.useState<1 | 0 | -1>(company.voted ? 1 : 0)
  const queryClient = useQueryClient()
  const { user } = useCurrentUser()

  const openListOtherGridModal = () => {
    showModal(
      (resolve) => (
        <ListOtherGridModal
          onHide={resolve}
          description={company.description}
          title={company.name}
          cta={company.cta}
          link={company.link}
        />
      ),
      'list-other-grid-modal',
    )
  }

  const { mutate: sendVote, isLoading: sendingVote } = useMutation(
    ['createVote'],
    (vote: 1 | -1 | 0) =>
      callApi(api.createVote, {
        team_slug: teamSlug,
        votable_type: companyList?.list_type === 'people' ? 'GlobalPerson' : 'Company',
        votable_id: companyList?.list_type === 'people' ? company.global_person_uuid : company.uuid,
        vote,
        company_id: null,
        list_uuid: listUuid,
      }),
    {
      onSuccess: (_, variables) => {
        queryClient.refetchQueries(['get_home_lists', teamSlug])
        queryClient.refetchQueries(['get_page_lists', teamSlug])
        setVote(variables)
      },
    },
  )

  const getAvatarUrl = () => {
    if (company.domain && (company.item_type === 'company' || company.item_type === 'job')) {
      return `https://logo.clearbit.com/${company.domain}`
    } else if (company.logo_url) {
      return company.logo_url
    } else if (company.avatar_upload_uuid) {
      return `/api/uploads/${company.avatar_upload_uuid}`
    } else if (company.item_type === 'persona') {
      const hash = Array.from(company.name).reduce(
        (acc, char) => (Math.imul(67, acc) + char.charCodeAt(0)) | 0,
        0,
      )
      const index = Math.abs(hash) % 34
      return avatars[index]
    } else {
      return undefined
    }
  }

  const getDefaultAvatar = () => {
    if (company.item_type === 'company') {
      return <i className="far fa-building" />
    } else if (company.item_type === 'persona') {
      return <i className="far fa-user" />
    } else if (company.item_type === 'job') {
      return <i className="far fa-briefcase" />
    } else if (company.item_type === 'mail') {
      return <i className="far fa-envelope" />
    } else if (company.item_type === 'GlobalPerson') {
      return <i className="far fa-user" />
    } else {
      return <i className="far fa-other" />
    }
  }

  const avatarUrl = getAvatarUrl()
  const defaultAvatar = getDefaultAvatar()

  const renderPeople = () => {
    const peopleToShow = company.global_people_ids.slice(0, cutOff)
    return peopleToShow.map((person_id: number, index: number) => {
      if (!people[person_id]) {
        return
      } else {
        const connected = (company.connected_global_people_ids || []).includes(person_id)
        // Only show 1st degree connections
        return (
          <>
            {connected && (
              <ConnectionRow
                key={person_id}
                connection={{
                  source_type: people[person_id].source || '',
                }}
                company={company}
                person={{
                  ...people[person_id],
                  id: person_id,
                }}
                teamSlug={teamSlug}
                existingVote={peopleVotes[person_id]}
                icps={icps}
                connected={connected}
                listUuid={listUuid}
                data-testid="connection-row"
              />
            )}
          </>
        )
      }
    })
  }

  const renderViewMore = () => {
    return (
      <>
        <div className={cn('pb-4 pl-3')}>
          {!advisorPreview && (
            <CabalButton
              variant="link"
              padding="0"
              onClick={() => history.push(`/${teamSlug}/lists/${listSlug}/${company.uuid}`)}
            >
              {isAdminOrEmployee ? (
                <>
                  View all {formatCompact(company?.people_count)}{' '}
                  {pluralize('connections', company?.people_count)}
                </>
              ) : (
                <>
                  {formatCompact(company?.connected_count)}{' '}
                  {pluralize('connections', company?.connected_count)}
                </>
              )}
            </CabalButton>
          )}

          {advisorPreview && company?.connected_count > 5 && company?.connected_count > cutOff && (
            <CabalButton
              variant="link"
              padding="0"
              onClick={() =>
                company?.connected_count <= 10
                  ? setCutOff(10)
                  : history.push(`/${teamSlug}/lists/${listSlug}/${company.uuid}`)
              }
            >
              See all {formatCompact(company?.connected_count)} connections
            </CabalButton>
          )}
        </div>
      </>
    )
  }

  const networkingOnly = (company) => {
    if (company && company.opportunity_type) {
      const oppType = company.opportunity_type

      if (oppType.length === 1 && oppType[0] === 'networking') {
        return true
      }
    }
    return false
  }

  const openPortfolioRequestIntroModal = () => {
    showModal(
      (resolve) => (
        <CreatePortfolioListModal
          requestable={company}
          requestor={user}
          initiator={user}
          companyList={companyList}
          resolve={resolve}
          facilitator={team}
        />
      ),
      'create-portfolio-intro-modal',
    )
  }

  const openRequestIntroModal = () => {
    closeModal('request-intro-modal')
    showModal(
      (resolve) => (
        <FacilitateIntroModal
          requestable={company}
          companyList={companyList}
          resolve={resolve}
          gatekeeperId={companyList?.gatekeeper_id}
          facilitator={team}
          includeInitialNote={false}
          networkingOnly={networkingOnly(company)}
          requestType={'external'}
        />
      ),
      'facilitate-intro-modal',
    )
  }

  const hideConnections =
    companyList?.cta_type === 'request_intro' && companyList?.list_type === 'companies'

  const goToPortfolioDetailPage =
    companyList?.cta_type === 'request_intro' &&
    companyList?.list_type === 'companies' &&
    !!companyList.portfolio

  const isTalentJob = company.item_type === 'job' && isTalent

  let onCardClick = undefined
  if (goToPortfolioDetailPage) {
    onCardClick = () => history.push(`/${teamSlug}/lists/${listSlug}/${company.uuid}`)
  } else if (company.type == 'other' && !!company.link) {
    onCardClick = () => {
      const link =
        company.link && company.link.startsWith('http') ? company.link : `https://${company.link}`
      if (company.link?.includes('getcabal.com')) {
        window.open(link, '_self')
      } else {
        window.open(link, '_blank', 'noopener, noreferrer')
      }
    }
  } else if (company.type == 'other' && !company.link) {
    onCardClick = () => openListOtherGridModal()
  } else if (company.item_type === 'GlobalPerson' && isTalent) {
    onCardClick = () =>
      window.open(
        `/${teamSlug}/lists/${listSlug}/${company.uuid}`,
        '_blank',
        'noopener, noreferrer',
      )
  } else if (isTalentJob) {
    onCardClick = () => history.push(`/${teamSlug}/lists/${companyList.slug}/${company.uuid}`)
  } else if (company.item_type === 'GlobalPerson' && !isTalent && company?.profile_url) {
    onCardClick = () => {
      window.open(company?.profile_url, '_blank', 'noopener, noreferrer')
    }
  } else {
    onCardClick = () => history.push(`/${teamSlug}/lists/${listSlug}/${company.uuid}`)
  }

  const canUseExternalPeopleIntroRequest =
    team?.features?.external_intro_request &&
    companyList?.list_type === 'people' &&
    companyList?.cta_type === 'request_intro'

  const canUseExternalCompanyIntroRequest =
    team?.features?.external_company_intro_request &&
    companyList?.list_type === 'companies' &&
    companyList?.cta_type === 'request_intro'

  const getCta = (grid: boolean) => {
    if (canUseExternalCompanyIntroRequest || canUseExternalPeopleIntroRequest || isTalentJob) {
      return (
        <CabalButton
          variant={grid ? 'primary' : 'link'}
          onClick={() => {
            if (
              companyList &&
              companyList.list_type === 'companies' &&
              (companyList.list_category === 'portfolio' || companyList.portfolio)
            ) {
              openPortfolioRequestIntroModal()
            } else {
              openRequestIntroModal()
            }
          }}
          padding={!grid ? '0' : undefined}
          className="w-full"
        >
          {companyList.cta_type
            .split('_')
            .map((type) => type.charAt(0).toUpperCase() + type.slice(1))
            .join(' ')}{' '}
        </CabalButton>
      )
    } else if (companyList?.cta_type === 'view' && companyList?.list_type === 'people') {
      return (
        <CabalButton
          variant={grid ? 'primary' : 'link'}
          padding={!grid ? '0' : undefined}
          disabled={sendingVote}
          rightIcon={
            vote === 1 ? <i className="fas fa-thumbs-up" /> : <i className="far fa-thumbs-up" />
          }
          onClick={() => {
            sendVote(vote === 0 ? 1 : 0)
          }}
          className="w-full"
        >
          {vote === 1 ? 'Offered' : 'Offer intro'}
        </CabalButton>
      )
    } else if (
      company.item_type === 'Discount' &&
      company.cta_text != '' &&
      company.cta_url != ''
    ) {
      const formattedLink =
        company.cta_url && !/^https?:\/\//i.test(company.cta_url)
          ? `http://${company.cta_url}`
          : company.cta_url
      return (
        <CabalButton
          variant={grid ? 'primary' : 'link'}
          onClick={() => {
            window.open(formattedLink, '_blank')
          }}
          padding="0"
        >
          {company.cta_text}
        </CabalButton>
      )
    } else if (company.type == 'other' && company.cta != '' && company.link != '') {
      const formattedLink =
        company.link && !/^https?:\/\//i.test(company.link)
          ? `http://${company.link}`
          : company.link
      return (
        <CabalButton
          variant={grid ? 'primary' : 'link'}
          onClick={() => {
            window.open(formattedLink, '_blank')
          }}
          padding={!grid ? '0' : undefined}
          className="w-full"
        >
          {company.cta}
        </CabalButton>
      )
    } else {
      return (
        <CabalButton
          variant={grid ? 'primary' : 'link'}
          padding={!grid ? '0' : undefined}
          onClick={onCardClick}
          className="w-full"
        >
          View
        </CabalButton>
      )
    }
  }

  const getDescriptionForOther = (description: string) => {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = sanitize(company.description || '')
    return [
      <Typography
        key={company.description}
        component="div"
        className="truncate"
        color="fog"
        fontSize="12"
      >
        {tempDiv.textContent}
      </Typography>,
    ]
  }

  if (gridView && advisorListView) {
    return (
      <DataCardV2
        onClick={onCardClick}
        view={'grid'}
        title={
          <>
            {company.name}

            {company.linkedin_url && (
              <Typography
                component="button"
                onClick={(e) => {
                  e.stopPropagation()
                  window.open(company.linkedin_url, '_blank', 'noopener noreferrer')
                }}
                className="ml-2"
                fontSize="14"
                color="fog"
                lineHeight={1}
              >
                <i className="fab fa-linkedin" />
              </Typography>
            )}
            {vote === 1 && (
              <Typography className="ml-2" fontSize="14" color="purple" lineHeight={1}>
                <i className="fas fa-thumbs-up" />
              </Typography>
            )}
          </>
        }
        description={
          company.description && !companyList?.portfolio ? (
            <Typography color="fog" className="text-center truncate" component="p" fontSize="12">
              {company.item_type === 'other'
                ? getDescriptionForOther(company.description)
                : company.description}
            </Typography>
          ) : undefined
        }
        descriptionWithoutTruncate={
          companyList?.portfolio && true && <p className="line-clamp-3">{company.description}</p>
        }
        descriptionBottom={
          <div className="flex flex-col items-start gap-2.5">
            {company.company && (
              <Typography lineHeight={1}>
                <i className="far fa-building" /> {company.company}
              </Typography>
            )}
            {company.location && (
              <Typography lineHeight={1}>
                <i className="far fa-location-dot" /> {company.location}
              </Typography>
            )}

            <CandidateDataTags company={company} />
          </div>
        }
        gridPillsSection={companyList?.portfolio && <PortfolioDataTags company={company} />}
        avatar={
          avatarUrl ? (
            <Avatar src={avatarUrl} name={company.name} size={'72'} />
          ) : (
            <DefaultAvatar
              color="fog"
              className="flex items-center justify-center w-12 h-12 rounded-full"
            >
              <Typography fontSize="20">{defaultAvatar}</Typography>
            </DefaultAvatar>
          )
        }
        topRightData={
          <>
            {company.last_viewed && (
              <Tooltip label={`Last viewed: ${new Date(company.last_viewed).toLocaleDateString()}`}>
                <StyledClock className="far fa-clock" />
              </Tooltip>
            )}
          </>
        }
        cta={getCta(true)}
      />
    )
  }

  if (gridView && !advisorListView) {
    return (
      <GridCard
        className={cx('h-[120px] truncate', { 'cursor-pointer': true })}
        onClick={onCardClick}
      >
        <div className="flex items-center justify-center w-full my-2">
          {avatarUrl ? (
            <Avatar src={avatarUrl} />
          ) : (
            <DefaultAvatar
              color="fog"
              className="flex items-center justify-center w-8 h-8 rounded-full"
            >
              <Typography fontSize="20">{defaultAvatar}</Typography>
            </DefaultAvatar>
          )}
        </div>
        <Typography
          className="text-center truncate pt-1 "
          component="div"
          fontSize="12"
          fontWeight={600}
          data-testid="team-home-company-name"
        >
          {company.name}
        </Typography>
        {company.description && (
          <Typography color="fog" className="text-center truncate" component="p" fontSize="12">
            {company.item_type === 'other'
              ? getDescriptionForOther(company.description)
              : company.description}
          </Typography>
        )}
      </GridCard>
    )
  }

  return (
    <>
      <DataCardV2
        className="h-[48px] flex items-center justify-between"
        onClick={onCardClick}
        title={
          <Typography
            fontSize="16"
            fontWeight={500}
            lineHeight={1}
            component="button"
            data-testid="team-home-company-name"
          >
            {company.name}
          </Typography>
        }
        avatar={
          avatarUrl ? (
            <Avatar src={avatarUrl} />
          ) : (
            <DefaultAvatar
              color="fog"
              className="flex items-center justify-center w-8 h-8 rounded-full"
            >
              {defaultAvatar}
            </DefaultAvatar>
          )
        }
        description={
          company.description ? (
            <Typography color="fog" className="text-center truncate" component="p" fontSize="12">
              {company.item_type === 'other'
                ? getDescriptionForOther(company.description)
                : htmlTextContent(company.description)}
            </Typography>
          ) : undefined
        }
        cta={getCta(false)}
      />
      {!hideConnections && (
        <div className="pl-[32px]">
          {company.global_people_ids?.length > 0 && (
            <div className="ml-2.5">
              {renderPeople()}
              {renderViewMore()}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default CompanyCard

import React from 'react'

import Border from 'polished/lib/shorthands/border'
import styled from 'styled-components'

import useCrmStatus from 'components/CrmStatus'
import { EditListProps, EditListType } from 'components/EditList'
import { useAccessControl } from 'global/AccessControl'
import Typography from 'global/Typography'

const CardWrapper = styled.div`
  border: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.colors.home_pill};
  &:hover {
    border-color: ${({ theme }) => theme.colors.purple};
  }
`

const StyledBorder = styled.div`
  border-left: ${({ theme }) => theme.border};
`

const Card = ({
  icon,
  title,
  description,
  onClick,
}: {
  icon: React.ReactNode
  title: string
  description: string
  onClick: () => void
}) => {
  return (
    <CardWrapper className="px-4 py-2 rounded cursor-pointer" onClick={onClick}>
      <div className="flex items-start gap-2.5">
        <Typography color="purple">{icon}</Typography>
        <div className="flex flex-col">
          <Typography color="primary" fontWeight={600} fontSize="14">
            {title}
          </Typography>
          <Typography color="fog" lineHeight={1.2} fontSize="12">
            {description}
          </Typography>
        </div>
      </div>
    </CardWrapper>
  )
}

interface Props {
  onSetList: (list: EditListType | ((prev: EditListType) => EditListType)) => void
  setNextStep: () => void
  teamSlug: string
}

const ListSelectorV2: React.FC<Props> = ({ setNextStep, onSetList, teamSlug }) => {
  const { salesforceConnected, hubspotConnected } = useCrmStatus(teamSlug)
  const { canViewPortfolio: isVCFirm } = useAccessControl(teamSlug)

  const cards = [
    {
      icon: <i className="far fa-rocket" />,
      title: 'Sales',
      description: 'Find and request warm intros to new customers',
      listProps: {
        list_category: 'sales',
        list_type: 'companies' as const,
        display_mode: 'list',
        cta_type: 'view',
        dynamic: false,
        provider: null,
      },
    },
    {
      icon: <i className="far fa-money-check-dollar-pen" />,
      title: 'Fundraise',
      description: 'Find and request warm intros to investors',
      listProps: {
        list_category: 'fundraise',
        list_type: 'companies' as const,
        display_mode: 'list',
        cta_type: 'view',
        dynamic: false,
        provider: null,
      },
    },
    {
      icon: <i className="far fa-star" />,
      title: 'Talent',
      description: 'Get help with open roles or offer access to candidates',
      listProps: {
        list_category: 'talent',
        list_type: 'people' as const,
        is_talent: true,
        cta_type: 'request_intro',
        display_mode: 'grid',
        dynamic: false,
        provider: null,
      },
    },
    {
      icon: <i className="far fa-screwdriver-wrench" />,
      title: 'Resources',
      description: 'Offer resources to your members',
      listProps: {
        list_category: 'resources',
        list_type: 'people' as const,
        cta_type: 'request_intro',
        display_mode: 'grid',
        is_talent: false,
        dynamic: false,
        provider: null,
      },
    },
  ]

  const orderedCards = isVCFirm ? [...cards].reverse() : cards

  return (
    <div className="flex py-12 items-center gap-2 h-full">
      <div className="h-full flex flex-col max-w-56 -mb-6">
        <div className="flex items-center gap-4 -mt-10">
          <Typography color="fog">
            <i className="far fa-list" />
          </Typography>
          <Typography fontSize="24" fontWeight={600}>
            Create a List
          </Typography>
        </div>
        <Typography fontSize="14" className="mt-2" lineHeight={1.2}>
          Create Lists to share asks, updates and offers.
        </Typography>
      </div>

      <StyledBorder className="h-[400px] pl-5 -my-10" />

      <div className="flex flex-col w-full">
        <Typography color="fog" fontSize="14" className="mb-4">
          Choose a template
        </Typography>
        <div className="flex flex-col gap-4">
          {orderedCards.map((card, index) => (
            <Card
              key={index}
              icon={card.icon}
              title={card.title}
              description={card.description}
              onClick={() => {
                onSetList((prev) => ({
                  ...prev,
                  ...card.listProps,
                }))
                setNextStep()
              }}
            />
          ))}
        </div>
        <div className="mt-8 flex justify-between items-center">
          <Typography
            color="fog"
            fontSize="12"
            component="button"
            className="hover:text-[#5C69D1]"
            onClick={() => window.open('/explore', '_blank', 'noopener,noreferrer')}
          >
            <i className="far fa-compass mr-1 fa-sm" /> Explore community lists
          </Typography>
          {!salesforceConnected && !hubspotConnected && (
            <Typography
              color="fog"
              fontSize="12"
              component="button"
              className="hover:text-[#5C69D1]"
              onClick={() =>
                window.open(`/${teamSlug}/admin/integrations`, '_blank', 'noopener,noreferrer')
              }
            >
              Connect CRM
            </Typography>
          )}
        </div>
      </div>
    </div>
  )
}

export default ListSelectorV2

import React, { useState } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useSetState } from 'react-use'

import FacilitatedBy from 'containers/IntroRequestModal/components/FacilitatedBy'
import UsersInfo from 'containers/IntroRequestModal/components/UsersInfo'
import {
  IRCompanyListObject,
  IRPayload,
  IRequestor,
  ISalesTarget,
} from 'containers/IntroRequestModal/types'
import { IntroConnection } from 'containers/Portfolio/MemberView/types/IntroConnection'
import CabalButton from 'global/CabalButton'
import { TextArea } from 'global/Input'
import Modal, { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import PillV2 from 'ui-components/PillV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { IFacilitateIntroResponse } from 'utils/types/IFacilitateIntro'

interface IProps {
  requestable: ISalesTarget
  companyList: IRCompanyListObject
  facilitator: IntroConnection
  requestor: IRequestor
  networkingOnly?: boolean
  introRequestUuid?: string | null
}

interface IList {
  company_list_id: string
  team_slug: string
  note: string
  requestable_id: string
  facilitator_id: string
  gatekeeper_id: string
  requestable_type: string
  requestor_uuid: string
  request_type: string
  request_reason: string
}

interface IRequestReasons {
  [key: string]: string
}

const icons: { [key: string]: JSX.Element } = {
  sales_partnership: <i className="fa-regular fa-rocket"></i>,
  job: <i className="fa-light fa-star"></i>,
  networking: <i className="fa-light fa-arrows-left-right"></i>,
  other_request_reason: <i className="fa-light fa-brain"></i>,
  fundraising: <i className="fa-regular fa-dollar-sign"></i>,
}

export function CreatePortfolioListModal({
  requestable,
  companyList,
  facilitator,
  requestor,
  networkingOnly = false,
}: IProps) {
  const { user } = useCurrentUser()
  const teamSlug = companyList?.owning_team?.slug || user?.team?.slug

  const [disableSendButton, setDisableSendButton] = useState(true)

  const [list, setList] = useSetState<IList>({
    team_slug: teamSlug,
    company_list_id: companyList.uuid,
    note: '',
    requestable_id: requestable.uuid,
    facilitator_id: facilitator.uuid,
    gatekeeper_id: facilitator.uuid,
    requestable_type: requestable.item_type,
    requestor_uuid: requestor.uuid,
    request_type: 'external',
  })

  const { mutate: introRequestMutate } = useMutation({
    mutationFn: (payload: IRPayload) => callApi(api.createIntroRequestV2, payload),
  })

  const { data: requestReasonsOptions, isLoading: requestReasonsFetchLoading } =
    useQuery<IRequestReasons>(['requestReasons'], () =>
      callApi(api.getIntroRequestReasons, companyList.uuid),
    )

  const [selectedReason, setSelectedReason] = useState<string | null>(null)
  const [note, setNote] = useState<string>('')

  const handleReasonClick = (reason: string) => {
    setSelectedReason(reason)
    setList({ ...list, request_reason: reason })
    setDisableSendButton(false)
  }

  const handleNoteChange = (e) => {
    const newNote = e.target.value
    setNote(newNote)
    setList({ ...list, note: newNote })
  }

  const { closeModal } = useModal()

  const handleSubmit = () => {
    introRequestMutate(list, {
      onSuccess: (data: IFacilitateIntroResponse) => {
        cabalToast({ style: 'success', content: 'Intro requested!' })
        closeModal('create-portfolio-intro-modal')
      },
      onError: () => {
        console.log('Error')
      },
    })
  }

  return (
    <Modal
      className="max-w-lg"
      show={true}
      onHide={() => closeModal('create-portfolio-intro-modal')}
      header={
        <Typography fontSize="16" fontWeight={600} lineHeight="1.2" className="w-full text-center">
          Request intro to {requestable.name}
        </Typography>
      }
      centerActions={
        <div className="pb-2">
          <CabalButton onClick={handleSubmit} className="mr-2" disabled={disableSendButton}>
            Send request to {facilitator.name}
          </CabalButton>
        </div>
      }
    >
      {requestReasonsFetchLoading ? (
        <Typography fontSize="16" fontWeight={600} lineHeight="1.2" className="w-full text-center">
          Loading...
        </Typography>
      ) : (
        <>
          <UsersInfo requestor={requestor} target={requestable}></UsersInfo>

          <FacilitatedBy facilitator={facilitator} />

          <div>
            <Typography fontSize="12" fontWeight={400} lineHeight="1" className="text-center">
              Purpose for request (choose one)
            </Typography>
          </div>

          <div className="flex flex-wrap justify-around items-center gap-2 mt-2 mb-2">
            {requestReasonsOptions &&
              Object.entries(requestReasonsOptions).map(([key, value], index) => (
                <PillV2
                  key={index}
                  title={
                    <Typography fontSize="12" lineHeight={1}>
                      {icons[key]} {value}
                    </Typography>
                  }
                  active={value === selectedReason}
                  onClick={() => handleReasonClick(value)}
                />
              ))}
          </div>

          {networkingOnly && (
            <div className="mt-6 mb-6 text-center">
              <Typography fontSize={'16'} color={'rain'} fontWeight={600}>
                <i className="fa fa-info-circle mr-1" />{' '}
                {requestable?.first_name || requestable.name} is only looking to network
              </Typography>
            </div>
          )}
          <div className="space-y-2 mb-2">
            <>
              <Typography fontSize="12" fontWeight={400} lineHeight="1" className="text-center">
                Note for {facilitator.name}
              </Typography>
              <TextArea
                value={note}
                className="w-full mt-1"
                rows={3}
                data-testid="request-talent-intro-modal-context"
                onChange={(e) => handleNoteChange(e)}
              />
            </>
          </div>
        </>
      )}
    </Modal>
  )
}

import React, { useEffect, useState } from 'react'

import { fa } from '@faker-js/faker'

import { EditListType } from 'components/EditList'
import { CheckBox, Select } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import InfoTooltip from 'ui-components/InfoTooltip'

interface IInitializeProps {
  selectedUser: string
  approvalRequired: boolean
  enableRouting: boolean
}

interface IRoutingOptions {
  enabled: boolean
  text: string
}

interface FacilitatorSectionProps {
  title: string
  adminAndMembersValues: Array<{ label: string; value: string }>
  selectedValue?: () => void
  defaultOptions: Array<{ label: string; value: string }>
  facilitatorType: 'talent' | 'sales' | 'resources'
  initializeOptions: IInitializeProps
  routingOptions: IRoutingOptions
}

const FacilitatorSection: React.FC<FacilitatorSectionProps> = ({
  title,
  adminAndMembersValues,
  selectedValue,
  defaultOptions,
  facilitatorType,
  initializeOptions,
  routingOptions,
}) => {
  const options = [...defaultOptions, ...adminAndMembersValues]
  const [disableRoutingCheckbox, setDisableRoutingCheckbox] = useState(
    facilitatorType === 'resources' ? false : !initializeOptions.enableRouting || false,
  )

  const resourcesFacilitator = facilitatorType === 'resources'

  const handleSelected = (selectedUserUuid: string, checked: boolean) => {
    setCheckedValue(checked)
    setSelectedFacilitator(selectedUserUuid)
    selectedValue({
      selectedFacilitator: selectedUserUuid,
      approvalRequired: checked,
      enableRouting: routingChecked,
    })
  }

  const selectedUser = options.find((user) => user.value === initializeOptions.selectedUser)

  const { user: currentUser } = useCurrentUser()

  const initializeUser = adminAndMembersValues.find((user) =>
    user.label.includes(currentUser.email),
  )

  const [checkedValue, setCheckedValue] = React.useState(
    initializeOptions.approvalRequired || false,
  )

  const [selectedFacilitator, setSelectedFacilitator] = useState(
    selectedUser?.value || initializeUser.value,
  )

  const [routingChecked, setRoutingChecked] = useState(initializeOptions.enableRouting || false)

  const handleEnableRouting = (checked: boolean) => {
    setDisableRoutingCheckbox(!checked)
    setRoutingChecked(checked)
    selectedValue({ selectedFacilitator, approvalRequired: checkedValue, enableRouting: checked })
  }

  return (
    <>
      {routingOptions.enabled && (
        <ModalInputWrapper>
          <ModalInputLabel>
            Enable {title} Routing
            <InfoTooltip className="ml-2">
              <Typography lineHeight={1.2} fontSize="13" className="pb-1" component="p">
                {routingOptions.text}
              </Typography>
            </InfoTooltip>
          </ModalInputLabel>

          <CheckBox
            checked={routingChecked}
            onChange={(e) => handleEnableRouting(e.target.checked)}
          />
        </ModalInputWrapper>
      )}
      {(routingChecked || resourcesFacilitator) && (
        <>
          <ModalInputWrapper>
            <ModalInputLabel>{title} Facilitator</ModalInputLabel>
            <Select
              options={options}
              noMinWidth
              className="w-full"
              value={selectedFacilitator || 'dynamic_gatekeeper'}
              onChange={(v) => handleSelected(v, checkedValue)}
              disabled={disableRoutingCheckbox}
            />
          </ModalInputWrapper>

          <ModalInputWrapper>
            <ModalInputLabel>
              Require Facilitator Approval
              <InfoTooltip className="ml-2">
                <Typography lineHeight={1.2} fontSize="13" className="pb-1" component="p">
                  If checked, intro requests are first sent to the Facilitator for review.
                  <br />
                  If unchecked, intro requests will be sent directly to the requested person or
                  company.
                </Typography>
              </InfoTooltip>
            </ModalInputLabel>

            <CheckBox
              checked={checkedValue || false}
              onChange={(e) => handleSelected(selectedFacilitator, e.target.checked)}
              disabled={disableRoutingCheckbox}
            />
          </ModalInputWrapper>
        </>
      )}
    </>
  )
}

export default FacilitatorSection

import { omit, pick, throttle } from 'lodash'
import React, {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useMutation, useQuery } from 'react-query'
import useStateRef from 'react-usestateref'
import { useDeepCompareMemo } from 'use-deep-compare'

import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'

import Typography from 'global/Typography'
import { useAdvisors, useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import {
  DraftMessageBlueprint,
  MessagePersonalizationBlueprint,
  PresendCheckParams,
} from 'utils/types'

import PresendCheckModal from '../PresendCheckModal'
import { PersonalizationRecipient } from '../Sidebar/Personalizations'
import { ComposerSidebarBaseProps } from '../SidebarBase'
import { AutoSaver } from './AutoSaver'
import getMessageParams from './getMessageParams'
import { individualRecipients as _individualRecipients } from './individualRecipients'
import { ClassicCKEditor } from 'global/TextEditor/ckeditor/base'

export interface ComposerState {
  message: DraftMessageBlueprint
  presenceList: ComposerSidebarBaseProps['activeUsers'][]
  personalizations: MessagePersonalizationBlueprint[]
  currentPersonalizationUuid: string | undefined
}

export interface ComposerStateContextValue {
  composerState: ComposerState | undefined
  setComposerState: Dispatch<SetStateAction<ComposerState | undefined>>
  setComposerMessage: Dispatch<Partial<DraftMessageBlueprint>>
  draftAutoSaveRef: MutableRefObject<AutoSaver<DraftMessageBlueprint> | null>
  ckeditorRef: MutableRefObject<ClassicCKEditor | null>
  composerStateRef: RefObject<ComposerState | undefined>
  personalizations: MessagePersonalizationBlueprint[]
  currentPersonalization?: MessagePersonalizationBlueprint
  refetchPersonalizations: () => void
  setPersonalization: (
    uuid: string,
    value: Partial<MessagePersonalizationBlueprint> | undefined,
  ) => void
  setCurrentPersonalizationUuid: Dispatch<string | undefined>
  individualRecipients: PersonalizationRecipient[]
  sendMessage: () => void
  sendState: 'sending' | 'testing' | 'presend' | undefined
  sendTest: () => void
}

const ComposerStateContext = createContext<ComposerStateContextValue | undefined>(undefined)

export const ComposerStatesContextProvider: React.FC = (props) => {
  const { showModal } = useModal()
  const [composerState, setComposerState, composerStateRef] = useStateRef<
    ComposerState | undefined
  >(undefined)
  const draftAutoSaveRef = useRef<AutoSaver<DraftMessageBlueprint> | null>(null)
  const ckeditorRef = useRef<ClassicCKEditor | null>(null)

  const message = composerState?.message
  const teamSlug = message?.team_slug
  const messageUuid = message?.uuid
  const messageRecipients = message?.to

  const { advisors: allAdvisors } = useAdvisors({ teamSlug: teamSlug! })
  const { advisors: groupAdvisors = [] } = useAdvisors({
    groupUuids:
      messageRecipients?.filter((r) => r.type === 'group').map((r) => r.value as string) || [],
  })
  const { team } = useTeam(teamSlug!)

  const setComposerMessage: ComposerStateContextValue['setComposerMessage'] = useCallback(
    (message) => {
      setComposerState({
        ...composerStateRef.current!,
        message: {
          ...composerStateRef.current!.message,
          ...message,
        },
      })
      draftAutoSaveRef.current?.sendChange(
        pick(
          message,
          'body',
          'cc',
          'bcc',
          'from',
          'notified_sender_uuids',
          'sender_uuid',
          'subject',
          'to',
          'uuid',
          'schedule_at',
          'attachments',
          'request',
        ),
      )
    },
    [composerStateRef, setComposerState],
  )

  const individualRecipients = useDeepCompareMemo(
    () => _individualRecipients(messageRecipients, allAdvisors, groupAdvisors, team),
    [allAdvisors, groupAdvisors.map((a) => a.uuid), messageRecipients, team?.admins_and_members],
  )

  const personalizations = useMemo(() => {
    const individualRecipientsUuid = individualRecipients.map((ir) => ir.uuid)
    return (composerState?.personalizations || []).filter((p) =>
      individualRecipientsUuid.includes(p.recipient.uuid),
    )
  }, [composerState?.personalizations, individualRecipients])

  const currentPersonalization = personalizations?.find(
    (p) => p.uuid === composerState?.currentPersonalizationUuid,
  )

  const setPersonalizations = useCallback((p: MessagePersonalizationBlueprint[]) => {
    setComposerState((prev) => ({ ...prev!, personalizations: p }))
  }, [])

  const setCurrentPersonalizationUuid = useCallback((uuid: string | undefined) => {
    setComposerState((prev) => ({ ...prev!, currentPersonalizationUuid: uuid }))
  }, [])

  const getMessagePersonalizationsQuery = useQuery(
    ['getMessagePersonalizations', messageUuid],
    () => callApi(api.getMessagePersonalizations, teamSlug!, messageUuid!),
    {
      onSuccess: ({ personalizations }) => {
        setPersonalizations(personalizations)
      },
      enabled: !!message,
    },
  )

  const updateMessagePersonalization = useCallback(
    throttle((uuid: string) => {
      const currentPersonalization = composerStateRef.current?.personalizations.find(
        (p) => p.uuid === uuid,
      )
      const message = composerStateRef.current?.message

      if (!message || !currentPersonalization) return

      api.updateMessagePersonalization(
        message.team_slug,
        message.uuid,
        currentPersonalization.uuid,
        currentPersonalization,
      )
    }, 3000),
    [],
  )

  const draftPresendCheckMutation = useMutation(
    ['draftPresendCheck', teamSlug],
    (params?: PresendCheckParams) => callApi(api.draftPresendCheck, team!.slug, message!, params),
    {
      onSuccess: ({ all_good, ...presendCheckResponse }, variables) => {
        if (all_good) {
          if (variables?.test) {
            sendTestMutation.mutate()
          } else {
            sendMessageMutation.mutate()
          }
        } else {
          showModal(
            (resolve) => (
              <PresendCheckModal
                onHide={resolve}
                onSubmit={(params) => {
                  draftPresendCheckMutation.mutate({ ...variables, ...params })
                }}
                setMessage={setComposerMessage}
                presendCheckResponse={presendCheckResponse}
              />
            ),
            'presend-check-modal',
          )
        }
      },
    },
  )

  const sendMessageMutation = useMutation(
    ['sendMessageMutation', teamSlug],
    () => callApi(api.createMessage, teamSlug!, getMessageParams(message!, user!) as any),
    {
      onSuccess: ({ message }) => {
        cabalToast({
          content: 'Message Sent!',
          style: 'success',
          cta: {
            variant: 'tertiary',
            content: 'view message',
            component: 'link',
            className: 'underline',
            to: `/${teamSlug}/messages/${message.uuid}`,
          },
        })
      },
    },
  )

  const sendTestMutation = useMutation(
    ['sendTestEmail', teamSlug],
    () =>
      callApi(api.sendTestEmail, {
        team_slug: teamSlug!,
        ...getMessageParams(message!, user!),
      }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Successfully sent the test mail' })
      },
    },
  )

  const sendState = useMemo(() => {
    if (draftPresendCheckMutation.isLoading) {
      return 'presend'
    }

    if (sendMessageMutation.isLoading) {
      return 'sending'
    }

    if (sendTestMutation.isLoading) {
      return 'testing'
    }

    return
  }, [
    draftPresendCheckMutation.isLoading,
    sendMessageMutation.isLoading,
    sendTestMutation.isLoading,
  ])

  const sendMessage = useCallback(() => {
    draftPresendCheckMutation.mutate({ test: false })
  }, [draftPresendCheckMutation])

  const sendTest = useCallback(() => {
    draftPresendCheckMutation.mutate({ test: true })
  }, [draftPresendCheckMutation])

  const setPersonalization: ComposerStateContextValue['setPersonalization'] = useCallback(
    (uuid, change) => {
      const personalization = personalizations.find((p) => p.uuid === uuid)
      setPersonalizations([
        ...personalizations.filter((p) => p.uuid !== uuid),
        { ...personalization!, ...change },
      ])
      updateMessagePersonalization(uuid)
    },
    [personalizations, setPersonalizations, updateMessagePersonalization],
  )

  const { user } = useCurrentUser()

  useEffect(() => {
    return () => {
      draftAutoSaveRef.current?.stop()
    }
  }, [])

  useEffect(() => {
    draftAutoSaveRef.current?.stop()
    draftAutoSaveRef.current = null

    if (!composerState) return

    draftAutoSaveRef.current = new AutoSaver<'updateDraftMessage'>({
      teamSlug: composerState.message.team_slug,
      objectId: composerState.message.uuid,
      userUuid: user.uuid,
      setObject: (message) => {
        setComposerState({
          ...composerStateRef.current!,
          message: {
            ...composerStateRef.current!.message,
            ...omit(message, 'body'),
          },
        })
      },
      apiName: 'updateDraftMessage',
    })
  }, [composerState?.message?.uuid])

  const children = useMemo(() => props.children, [props.children])

  const value: ComposerStateContextValue = useMemo(
    () => ({
      composerState,
      setComposerState,
      setComposerMessage,
      draftAutoSaveRef,
      ckeditorRef,
      composerStateRef,
      personalizations,
      currentPersonalization,
      setPersonalization,
      setCurrentPersonalizationUuid,
      refetchPersonalizations: () => getMessagePersonalizationsQuery.refetch(),
      individualRecipients,
      sendMessage,
      sendTest,
      sendState,
    }),
    [
      composerState,
      composerStateRef,
      currentPersonalization,
      getMessagePersonalizationsQuery,
      individualRecipients,
      personalizations,
      sendMessage,
      sendState,
      sendTest,
      setComposerMessage,
      setComposerState,
      setCurrentPersonalizationUuid,
      setPersonalization,
    ],
  )

  return <ComposerStateContext.Provider value={value}>{children}</ComposerStateContext.Provider>
}

export const useComposerState = () => {
  const context = React.useContext(ComposerStateContext)

  if (context === undefined) {
    throw new Error('useComposerStates must be used within a ComposerStatesContextProvider')
  }

  return context
}

export const withComposerStateContext = (Component: React.ComponentType<any>) => (props: any) =>
  (
    <ComposerStateContext.Consumer>
      {(context) => <Component composerStateContext={context} {...props} />}
    </ComposerStateContext.Consumer>
  )

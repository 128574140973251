import React from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { renderFilters } from 'components/Filters'
import {
  COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS,
  LEVEL_OPTIONS,
  OFFICE_WITH_OPEN_TO_ALL_OPTIONS,
  OPPORTUNITY_TYPE_OPTIONS,
  ROLE_FUNCTION_OPTIONS,
  STAGE_WITH_OPEN_TO_ALL_OPTIONS,
  STATUS_OPTIONS,
} from 'containers/ListIndexView/constants'
import { useAccessControl } from 'global/AccessControl'
import { CheckBox } from 'global/Input'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import Switch from 'ui-components/Switch'

import { cn } from 'utils/styles'

export const TALENT_FILTERS = [
  {
    key: 'query',
    type: 'search',
    icon: 'far fa-search',
    labelStr: 'Search',
    label: 'Search',
    skipLabelInAppliedPill: true,
    noPopover: true,
  },
  {
    key: 'function',
    type: 'dropdown[]',
    label: 'Function',
    options: ROLE_FUNCTION_OPTIONS,
  },
  {
    key: 'level',
    type: 'dropdown[]',
    label: 'Level',
    options: LEVEL_OPTIONS,
  },
  {
    key: 'location',
    type: 'location[]',
    label: 'Location',
  },
  {
    key: 'stage_preference',
    type: 'dropdown[]',
    label: 'Stage',
    options: STAGE_WITH_OPEN_TO_ALL_OPTIONS,
  },
  {
    key: 'opportunity_type',
    type: 'dropdown[]',
    label: 'Job type',
    options: OPPORTUNITY_TYPE_OPTIONS,
  },
  {
    key: 'ideal_company_size',
    type: 'dropdown[]',
    label: 'Company Size',
    options: COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS,
  },
  {
    key: 'office_preference',
    type: 'dropdown[]',
    label: 'Office',
    options: OFFICE_WITH_OPEN_TO_ALL_OPTIONS,
  },
  {
    key: 'status',
    type: 'dropdown',
    label: 'Status',
    options: STATUS_OPTIONS,
  },
]

interface Props {
  disabled: boolean
  setSelectedItems: (items: any) => void
  talentFiltersValue: any
  setTalentFiltersValue: (value: any) => void
  talentPeople: any
  filteredAdminView: boolean
}

const AllCandidateFilters = ({
  disabled,
  setSelectedItems,
  talentFiltersValue,
  setTalentFiltersValue,
  talentPeople,
  filteredAdminView,
}: Props) => {
  const teamSlug = useTeamSlug()

  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  const { isAdminOrEmployee } = useAccessControl(teamSlug)

  const adminView = isAdminOrEmployee

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: TALENT_FILTERS,
    value: talentFiltersValue as {
      query?: string
      function?: string[]
      level?: string[]
      location?: string[]
      function_preference?: string[]
      level_preference?: string[]
      stage_preference?: string[]
      opportunity_type?: string[]
      ideal_company_size?: string[]
      office_preference?: string[]
      status?: string
      member_view?: boolean
    },
    onChange: (newValues) => {
      setTalentFiltersValue(newValues)
    },
  })

  return (
    <div
      ref={parent}
      className={cn('flex-col w-full my-3', {
        'pointer-events-none opacity-50': disabled,
        'px-2.5': adminView,
      })}
    >
      <div className="flex flex-col sm:flex-row justify-between items-start">
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-wrap gap-2.5">
            {filteredAdminView && (
              <CheckBox
                onChange={(e) => {
                  setSelectedItems(e.target.checked ? talentPeople : [])
                }}
              />
            )}
            {renderedFilters}
          </div>
          {adminView && (
            <div className="flex items-center gap-2">
              <Typography fontSize="12" color="fog">
                Member View
              </Typography>
              <Switch
                checked={talentFiltersValue.member_view}
                onCheckedChange={(e) =>
                  setTalentFiltersValue({ ...talentFiltersValue, member_view: e })
                }
              />
            </div>
          )}
        </div>
      </div>
      {appliedFilters.length > 0 && (
        <div className="flex gap-1 flex-wrap mt-2">{appliedFilters}</div>
      )}
    </div>
  )
}

export default AllCandidateFilters

import React from 'react'

import pluralize from 'pluralize'

import ListHeader from 'containers/ListIndexView/Components/ListHeader'
import { useResourcesListContext } from 'containers/ListIndexView/ResourcesList'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'

import { CompanyListBlueprint } from 'utils/types'

interface Props {
  companyListData: CompanyListBlueprint
}

const PeopleListHeader: React.FC<Props> = ({ companyListData }) => {
  const descriptionPart = [
    <>
      <div className="flex items-center gap-1">
        {companyListData.owning_team?.logo_url && (
          <Avatar src={companyListData.owning_team.logo_url} size={'16'} />
        )}
        <Typography className="truncate">{companyListData.owning_team.name}</Typography>
      </div>
      <Typography className="mx-1.5">{'·'}</Typography>
      <Typography className="mx-1.5">
        <i className="far fa-star mr-1" />
        {companyListData.list_type.charAt(0).toUpperCase() + companyListData.list_type.slice(1)}
      </Typography>
      <Typography className="mx-1.5">{'·'}</Typography>
      <Typography className="truncate">{companyListData.description}</Typography>
    </>,
    `${companyListData.item_count} ${pluralize('Item', companyListData.item_count)}`,
  ]

  return <ListHeader title={companyListData.name} description={descriptionPart} />
}

export default PeopleListHeader

import React, { useEffect, useState } from 'react'

import { useInfiniteQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { ArrayParam } from 'use-query-params'

import Filters from 'components/Block/Filters'
import ConnectionsEmptyState from 'components/EmptyStates/ConnectionsEmptyState'
import PageHeader from 'containers/ItemDetailPage/PageHeader'
import ListStatusCards from 'containers/ListView/Companies/StatusCards'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import Loading from 'global/Loading'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useCurrentUser, useCurrentUserSettings, useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { useQueryParamsWithSettings } from 'utils/hooks/useQueryParamsWithSettings'
import { CompaniesFiltersType, CompanyListBlueprint, CompanySlugParam } from 'utils/types'

import ConnectionsTable from './ConnectionsTable'

interface Props {
  companyList?: CompanyListBlueprint
}

const Connections: React.FC<Props> = ({ companyList }) => {
  const { user } = useCurrentUser()
  const { company_slug: companySlug } = useParams<CompanySlugParam>()
  const { settings, updateSetting } = useCurrentUserSettings()
  const [view, setView] = useState<'list' | 'grid'>('list')
  const teamSlug = companySlug
  const { team } = useTeam(teamSlug)
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const defaultFilter: CompaniesFiltersType = {}

  const queryParamConfigMap = React.useMemo(() => {
    return {
      advisor_uuids: ArrayParam,
      group_uuids: ArrayParam,
      search: ArrayParam,
    }
  }, [])

  const [filters, setFilters_] = useQueryParamsWithSettings(
    queryParamConfigMap,
    'list_filters',
    teamSlug + '_' + 'connections',
    defaultFilter,
  )

  const connQueryKey = [
    companySlug,
    'connections',
    filters.search,
    filters.advisor_uuids,
    filters.group_uuids,
  ]

  const conn = useInfiniteQuery(
    connQueryKey,
    ({ pageParam: page = 1 }) =>
      callApi(
        api.getConnections,
        filters.search || '',
        companyList?.slug,
        (filters.advisor_uuids || []).concat(
          (filters.group_uuids || []).map((groupUuid) => `group:${groupUuid}`) || [],
        ),
        companySlug,
        false,
        page,
      ),
    {
      cacheTime: 0,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
    },
  )

  const connections = conn?.data?.pages?.flatMap((page) => page.people) ?? []
  const teamRequests = conn?.data?.pages?.length > 0 ? conn?.data?.pages[0].team_requests : []

  const showEmptyState = conn.isSuccess && connections.length === 0
  const showResults = conn.isSuccess && connections.length > 0
  const showLoading = conn.isLoading
  const refetch = conn.refetch

  const showRequestDemo = team && !team.enable_asks

  return (
    <PageWrapper title="All Connections">
      <PageHeader
        data={{
          title: 'All Connections',
        }}
        actions={{
          others: (
            <div className="inline-block w-[80px]">
              <CabalButton
                variant="link"
                leftIcon={
                  view === 'list' ? (
                    <i className="far fa-grid fa-fw" />
                  ) : (
                    <i className="far fa-equals fa-fw" />
                  )
                }
                onClick={() => setView(view === 'list' ? 'grid' : 'list')}
              >
                {view === 'list' ? 'Grid' : 'List'}
              </CabalButton>
            </div>
          ),
        }}
        backUrl={isAdminOrEmployee ? `/${companySlug}/lists` : `/${companySlug}`}
        renderFilters={
          <Filters
            teamSlug={companySlug}
            onChange={setFilters_}
            filterTypes={[
              {
                key: 'search',
                type: 'search',
                icon: 'far fa-search',
                labelStr: 'Search',
                skipLabelInAppliedPill: true,
                noPopover: true,
              },
              {
                key: 'members',
                local_options: 'groups_and_advisors',
                type: 'dropdown[]',
                label: 'Members',
                options: [],
              },
            ]}
            block_id={'connections'}
          />
        }
      />
      <ListStatusCards
        teamSlug={companySlug}
        refetch={refetch}
        allCompaniesOrAllConnections={true}
        showItemsIsSyncing={false}
      />
      {showRequestDemo ? (
        <EmptyState
          heading={
            <Typography fontSize="14" fontWeight={600}>
              Looks like your plan doesn't include access to this feature
            </Typography>
          }
          icon={<i className="fal fa-users"></i>}
          body={
            <div className="flex items-center justify-center mt-0.5">
              <Typography
                fontSize="14"
                color="purple"
                className="cursor-pointer"
                onClick={() => window.open('https://getcabal.com/demo')}
              >
                Request a Free Trial
              </Typography>
            </div>
          }
        />
      ) : (
        <>
          {showEmptyState && <ConnectionsEmptyState hasSyncedLinkedin={user.has_synced_linkedin} />}
          {showResults && (
            <>
              <ConnectionsTable
                people={connections}
                onRefetch={() => conn.refetch()}
                queryKey={connQueryKey}
                teamRequests={teamRequests}
                view={view}
              />

              {conn.hasNextPage && (
                <div className="flex justify-center my-6">
                  <CabalButton
                    disabled={conn.isFetchingNextPage}
                    working={conn.isFetchingNextPage}
                    onClick={() => conn.fetchNextPage()}
                  >
                    {'Load more'}
                  </CabalButton>
                </div>
              )}
            </>
          )}
        </>
      )}
      {showLoading && <Loading />}
    </PageWrapper>
  )
}

export default Connections

import React from 'react'

import pick from 'lodash/pick'
import { useSetState } from 'react-use'

import CabalButton from 'global/CabalButton'
import { Select } from 'global/Input'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug, useUpdateTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import { Team } from 'utils/types'

const Defaults: React.FC = () => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { updateTeamAsync, isUpdatingTeam } = useUpdateTeam(teamSlug)

  const [updatedTeam, setUpdatedTeam] = useSetState(team)

  const handleSubmit = () => {
    updateTeamAsync(
      pick<Team, keyof Team>(updatedTeam, 'default_requestor_uuid', 'default_facilitator_uuid'),
    )
      .then(() => cabalToast({ style: 'success', content: 'Team settings updated successfully!' }))
      .catch(() => cabalToast({ style: 'error', content: 'Failed to update team settings!' }))
  }

  return (
    <>
      <div className="flex flex-col gap-4 max-w-sm">
        {!team?.enable_portfolio && (
          <>
            <div className="flex flex-col gap-1">
              <Typography>Requestor</Typography>

              <Select
                value={updatedTeam.default_requestor_uuid || ''}
                onChange={(e) => setUpdatedTeam({ default_requestor_uuid: e || '' })}
                options={
                  team?.admins_and_members.map((user) => ({
                    label: `${user.name} - ${user.email}`,
                    value: user.uuid,
                  })) || []
                }
              />
            </div>
          </>
        )}

        <div className="flex flex-col gap-1 ">
          <Typography>Facilitator</Typography>

          <Select
            value={updatedTeam.default_facilitator_uuid || ''}
            onChange={(e) => setUpdatedTeam({ default_facilitator_uuid: e || '' })}
            options={
              team?.admins_and_members.map((user) => ({
                label: `${user.name} - ${user.email}`,
                value: user.uuid,
              })) || []
            }
          />
        </div>
      </div>

      <div className="flex justify-end">
        <CabalButton
          variant="primary"
          disabled={isUpdatingTeam}
          working={isUpdatingTeam}
          onClick={handleSubmit}
          data-testid="save-public-dir"
        >
          Save
        </CabalButton>
      </div>
    </>
  )
}

export default Defaults

import * as React from 'react'

import axios from 'axios'
import { SkeletonTheme } from 'react-loading-skeleton'
import { useLocation } from 'react-router'

import { useComposer } from 'components/Composer'
import GoogleAuthModal from 'components/GoogleAuthModal'
import PaywallBanner from 'containers/App/PaywallBanner'
import { useAccessControl } from 'global/AccessControl'
import CompanyNav from 'global/CompanyNav'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import { useCurrentUser, useCurrentUserSettings, useTeam } from 'store/hooks'

import { useSlug } from 'utils/hooks/useSlug'

import GlobalNav from './GlobalNav'
import Shortcuts from './Shortcuts'
import { AppWrapper, MainContentWrap, OverflowWrapper } from './styles'

interface Props {
  children: React.ReactNode
}

const MainContainer: React.FC<Props> = (props) => {
  const location = useLocation()
  const { user } = useCurrentUser()
  const { theme } = user

  const { showModal } = useModal()
  const { teamSlug: team_slug, isLoading } = useSlug()
  const { settings } = useCurrentUserSettings()
  const [pageLoading, setPageLoading] = React.useState<boolean>(true)

  const { team, isLoading: teamIsLoading } = useTeam(team_slug)
  const { canViewShortcuts, canCreateDraft, isAdminOrEmployee, isAdvisor } =
    useAccessControl(team_slug)
  const { compose } = useComposer()

  React.useEffect(() => {
    const searchParams = new URL(window.location.href).searchParams

    if (searchParams.get('provider') === 'gmail' && searchParams.has('auth_failed')) {
      showModal(
        (resolve) => (
          <GoogleAuthModal
            header="Almost! Your Gmail isn't authorized with Cabal yet."
            show
            message={`Make sure to select "Send email on your behalf"
            to start sending emails directly from Cabal.`}
            buttonText="Try again"
            onHide={resolve}
            onAuthorize={() => resolve()}
          />
        ),
        'gmail-auth-modal',
      )
    }
  }, [])

  React.useEffect(() => {
    if (canCreateDraft && window.location.hash === '#compose') {
      window.location.hash = ''
      compose()
    }
  }, [canCreateDraft, compose])

  React.useEffect(() => {
    const checkCookie = setInterval(() => {
      if (!window.linkedin_cookies) return

      axios.post('/auth/cookies', {
        domain: 'linkedin.com',
        env: process.env.NODE_ENV,
        data: JSON.stringify(window.linkedin_cookies),
      })

      clearInterval(checkCookie)
      delete window.linkedin_cookies
    }, 2000)
    window.addEventListener('resize', appHeight)
    appHeight()

    return () => {
      clearInterval(checkCookie)
      window.removeEventListener('resize', appHeight)
    }
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      setPageLoading(false)
    }, 3000)
  }, [])

  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  const showTeamNav = team?.features?.home_v2
    ? !teamIsLoading && !!team_slug && isAdminOrEmployee
    : !!team_slug

  const showPaywall = team?.features.show_paywall_banner && isAdminOrEmployee

  // const teamPageWithoutNav =
  //   team?.features?.home_v2 && !teamIsLoading && !!team_slug && !isAdminOrEmployee && isAdvisor

  const fullWidth =
    (team_slug &&
      location.pathname.startsWith(`/${team_slug}/messages`) &&
      settings?.messages_split_view) ||
    location.pathname.startsWith(`/messages/`)

  const hideBackground =
    location.pathname.startsWith('/home') ||
    location.pathname.startsWith(`/${team_slug}/home`) ||
    location.pathname === `/${team_slug}`

  if (pageLoading || isLoading) {
    return (
      <div className="w-full flex items-center justify-center h-[75vh]">
        <Loading size={60} />
      </div>
    )
  }

  const baseColor = theme === 'light' ? '#f0f0f0' : '#293342'
  const highlightColor = theme === 'light' ? '#e0e0e0' : '#444'

  return (
    <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
      {canViewShortcuts && <Shortcuts />}
      <AppWrapper showTeamNav={showTeamNav || false} showPaywall={showPaywall || false}>
        <GlobalNav />
        {showPaywall && <PaywallBanner />}
        {showTeamNav && <CompanyNav />}

        <OverflowWrapper id="main-overflow-wrapper">
          <MainContentWrap
            restrictWidth={!fullWidth}
            hideBackground={hideBackground}
            // style={{ paddingTop: teamPageWithoutNav ? 20 : 0 }}
            id="main-container"
          >
            {props.children}
          </MainContentWrap>
        </OverflowWrapper>
      </AppWrapper>
    </SkeletonTheme>
  )
}

export default MainContainer

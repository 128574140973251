import React from 'react'

import { useHistory } from 'react-router-dom'

import Avatar from 'global/Avatar'
import Typography from 'global/Typography'

import { cn } from 'utils/styles'

interface Props {
  name: string
  logo?: string
  description?: string
  cta?: React.ReactNode
  showCheck?: boolean
}

const DetailPageHeader = ({ name, logo, description, cta, showCheck = false }: Props) => {
  const history = useHistory()

  return (
    <div className="flex justify-between items-top mt-4">
      <div className={cn('flex')}>
        <Typography
          color={'fog'}
          fontSize="16"
          className="mr-3 cursor-pointer"
          onClick={() => history.goBack()}
          component="button"
        >
          <i className="far fa-chevron-left" />
        </Typography>
        <div className="flex flex-shrink-0">
          <Avatar src={logo} name={name} size={'72px'} />
        </div>

        <div className="flex flex-col ml-3">
          <div className="flex items-baseline gap-2">
            <Typography lineHeight={1.25} fontSize="36" fontWeight={600}>
              {name}
            </Typography>
            {showCheck && (
              <Typography fontSize="20" color="purple" lineHeight={1}>
                <i className="fa fa-badge-check fa-fw -mr-1" />
              </Typography>
            )}
          </div>
          <div>
            <Typography fontSize="14" color="fog">
              {description}
            </Typography>
          </div>
        </div>
      </div>
      {cta && <div>{cta}</div>}
    </div>
  )
}

export default DetailPageHeader

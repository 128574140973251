import React, { useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { useResourcesListContext } from 'containers/ListIndexView/ResourcesList'
import ResourceCompanyAddWidget from 'containers/ListIndexView/ResourcesList/ResourceCompanyList/ResourceCompanyAddWidget'
import ResourcesListBulkActions from 'containers/ListIndexView/ResourcesList/ResourcesListBulkActions'
import ResourcesListCTAs from 'containers/ListIndexView/ResourcesList/ResourcesListCTAs'
import ResourcesListEmptyState from 'containers/ListIndexView/ResourcesList/ResourcesListEmptyState'
import ResourcesListFilters from 'containers/ListIndexView/ResourcesList/ResourcesListFilters'
import ResourcesListHeader from 'containers/ListIndexView/ResourcesList/ResourcesListHeader'
import {
  IResourceCompany,
  ResourcesFilterTypes,
} from 'containers/ListIndexView/ResourcesList/types'
import { useScrollToElement } from 'containers/ListIndexView/hooks'
import { StickyHeader } from 'containers/ListIndexView/style'
import Loading from 'global/Loading'

import ResourceCompanyAdminView from './ResourceCompanyAdminView'
import ResourceCompanyMemberView from './ResourceCompanyMemberView'

interface Props {
  listLoading: boolean
  memberView: boolean
  // adminView: boolean
  resourcesListEmpty: boolean
  sharedListView: boolean
  filters: ResourcesFilterTypes
  setFilters: (filters: ResourcesFilterTypes) => void
}

const ResourceCompanyList: React.FC<Props> = ({
  listLoading,
  memberView,
  resourcesListEmpty,
  sharedListView,
  filters,
  setFilters,
}) => {
  const [showAddWidget, setShowAddWidget] = useState(false)
  const [selectedCompanies, setSelectedCompanies] = useState<IResourceCompany[]>([])
  const { adminView } = useResourcesListContext()
  const scrollToAdd = useScrollToElement('resource-company-add')

  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  return (
    <div>
      This is the resource company list
      <StickyHeader>
        <div className="flex flex-col sm:flex-row justify-between gap-4 sm:items-center">
          <ResourcesListHeader />
          <div className="flex gap-6">
            <ResourcesListBulkActions selectedItems={selectedCompanies} listUuid={''} />
            <ResourcesListCTAs
              onShowAddWidget={() => {
                setShowAddWidget(!showAddWidget)
                if (!showAddWidget) {
                  scrollToAdd()
                }
              }}
            />
          </div>
        </div>
        <ResourcesListFilters
          filters={filters}
          setFilters={setFilters}
          ownedCompanyTags={[]}
          setSelectedItems={setSelectedCompanies}
        />
      </StickyHeader>
      {listLoading && (
        <div className="my-10 flex items-center justify-center">
          <Loading />
        </div>
      )}
      {!listLoading && (
        <>
          {/* Need new add widget */}
          <div ref={parent} id="resource-company-add">
            {showAddWidget && <ResourceCompanyAddWidget onClose={() => setShowAddWidget(false)} />}
          </div>

          {resourcesListEmpty && (
            <ResourcesListEmptyState
              onShowAddWidget={() => {
                setShowAddWidget(!showAddWidget)
              }}
              title="No company"
              description="Add company to get started"
              buttonLabel="Add company"
            />
          )}

          {adminView && !resourcesListEmpty && (
            <ResourceCompanyAdminView
              selectedCompany={selectedCompanies}
              setSelectedCompany={setSelectedCompanies}
            />
          )}

          {(memberView || sharedListView) && !resourcesListEmpty && <ResourceCompanyMemberView />}
        </>
      )}
    </div>
  )
}

export default ResourceCompanyList

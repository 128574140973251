import React, { useMemo } from 'react'

import { useMutation, useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

import BulkActions from 'containers/ListIndexView/Components/BulkActions'
import { useJobsListContext } from 'containers/ListIndexView/TalentList/JobsList'
import JobsListBulkActionModal from 'containers/ListIndexView/TalentList/JobsList/JobsListBulkActionModal'
import { IJob } from 'containers/ListIndexView/TalentList/JobsList/types'
import { useModal } from 'global/Modal'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { GetInvestorTagsResponse } from 'utils/types/investor'

interface Props {
  selectedJobs: IJob[]
  jobTags?: GetInvestorTagsResponse
}

const JobsListBulkActions: React.FC<Props> = ({ selectedJobs, jobTags }) => {
  const { showModal } = useModal()
  const { adminView } = useJobsListContext()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const archivedStatus = searchParams.get('status') === 'archived'
  const {
    companyListData: companyList,
    refetchListItems,
    view,
    setView,
    refetchCompanyList,
  } = useJobsListContext()
  const queryClient = useQueryClient()

  const selectedJobsUuids = useMemo(
    () => selectedJobs.map((job) => job.uuid).filter((uuid): uuid is string => uuid !== undefined),
    [selectedJobs],
  )

  const renderJobsListBulkActionModal = (resolve: () => void, header: string, kind: string) => (
    <JobsListBulkActionModal
      onHide={() => resolve()}
      refetch={refetchListItems}
      selectedJobsUuids={selectedJobsUuids}
      jobTags={jobTags}
      header={header}
      kind={kind}
      companyListUuid={companyList.uuid}
    />
  )

  const { mutate: bulkAction } = useMutation(
    (params: any) =>
      callApi(api.jobsBulkActions, companyList.uuid, {
        selected_jobs_uuids: selectedJobsUuids,
        bulk_action: params.action,
        kind: params.kind,
        values: [],
      }),
    {
      onSuccess: (_, params) => {
        refetchListItems()
        queryClient.invalidateQueries(['jobsForAdd', companyList.uuid])
        refetchCompanyList()
        cabalToast({ style: 'success', content: `Successfully ${params.action}d!` })
      },
      onError: () => {
        cabalToast({
          style: 'error',
          content: 'Something went wrong, please try again or contact support',
        })
      },
    },
  )

  const handleOnSelect = (header: string, action: string, kind: string) => {
    if (selectedJobs.length === 0) {
      cabalToast({
        content: 'Select companies to perform bulk actions',
        style: 'error',
      })
      return
    }
    if (action === 'delete') {
      const confirmDelete = window.confirm('Are you sure you want to bulk delete?')
      if (confirmDelete) {
        bulkAction({ action, kind })
      }
    } else if (action === 'archive' || action === 'restore') {
      bulkAction({ action, kind })
    } else {
      showModal(
        (resolve) => renderJobsListBulkActionModal(resolve, header, kind),
        'render_jobs_list_bulk_actions',
      )
    }
  }

  const bulkMenuItems = [
    {
      label: 'Add Tags',
      onSelect: () => handleOnSelect('Add Tags', 'add', 'tags'),
    },
    {
      label: 'Delete Tags',
      onSelect: () => handleOnSelect('Delete Tags', 'delete', 'tags'),
    },
    {
      label: 'Delete Jobs',
      onSelect: () => handleOnSelect('Delete Jobs', 'delete', 'jobs'),
    },
    {
      label: `${archivedStatus ? 'Restore' : 'Archive'} Jobs`,
      onSelect: () =>
        handleOnSelect('Archive Jobs', archivedStatus ? 'restore' : 'archive', 'jobs'),
    },
  ]

  return (
    <>
      {adminView && (
        <span onClick={() => view === 'grid' && setView('list')}>
          <BulkActions disabled={selectedJobs.length === 0} bulkMenuItems={bulkMenuItems} />
        </span>
      )}
    </>
  )
}

export default JobsListBulkActions

import React from 'react'

import Skeleton from 'react-loading-skeleton'

import Widget from 'ui-components/Widget'

import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonWidget = () => {
  return (
    <Widget
      title={
        <div className="w-32">
          <Skeleton count={1} containerClassName="flex-1" />
        </div>
      }
    >
      {Array.from({ length: 3 }).map((_, index) => (
        <div key={index} className="flex ml-1 gap-2 py-2">
          <Skeleton className="w-8 h-8" circle={true} />
          <div className="flex flex-col w-full">
            <Skeleton count={2} containerClassName="flex-1" />
          </div>
        </div>
      ))}
    </Widget>
  )
}

export default SkeletonWidget

import React, { useMemo } from 'react'

import { useAccessControl } from 'global/AccessControl'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'
import PillV2 from 'ui-components/PillV2'

import { TagOptions } from 'utils/constants/tags'
import { List } from 'utils/types'

interface Props {
  lists: List[]
  filter: string
  onSetFilter: (filter: string) => void
  teamSlug?: string
}

const ListPills: React.FC<Props> = ({ lists, filter, onSetFilter, teamSlug }) => {
  const { canViewPortfolio, canViewOffers } = useAccessControl(teamSlug)
  const { team } = useTeam(teamSlug)

  const tags = useMemo(() => {
    const newTags: string[] = []

    lists.forEach((list) => {
      if (list.tags) {
        newTags.push(...list.tags)
      }
    })
    const uniqueTags = new Set(newTags)
    return Array.from(uniqueTags)
  }, [lists])

  function getIconByTitle(title: string) {
    for (let i = 0; i < TagOptions.length; i++) {
      if (TagOptions[i].title === title) {
        return TagOptions[i].icon
      }
    }
    // If no matching title is found, you can return a default value or handle it as needed
    return null
  }

  if (tags.length === 0 && !canViewPortfolio && !canViewOffers) {
    return null
  }

  return (
    <div className="mt-10 flex flex-wrap gap-2">
      <PillV2
        title={
          <Typography fontSize="12" lineHeight={1}>
            All
          </Typography>
        }
        active={filter === ''}
        onClick={() => onSetFilter('')}
      />

      {tags.map((tag, idx) => {
        const icon = getIconByTitle(tag)
        return (
          <PillV2
            key={idx}
            title={
              <Typography fontSize="12" lineHeight={1}>
                <i className={`far fa-${icon} mr-1.5`}></i>
                {tag}
              </Typography>
            }
            active={filter === tag}
            onClick={() => onSetFilter(tag)}
          />
        )
      })}

      {canViewPortfolio && team?.show_old_portfolio_on_page && (
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              <i className={`far fa-${getIconByTitle('Portfolio')} mr-1.5`} />
              Portfolio
            </Typography>
          }
          active={filter === 'Portfolio'}
          onClick={() => onSetFilter('Portfolio')}
        />
      )}

      {canViewOffers && (
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              <i className={`far fa-${getIconByTitle('Resources')} mr-1.5`} />
              Resources
            </Typography>
          }
          active={filter === 'Resources'}
          onClick={() => onSetFilter('Resources')}
        />
      )}
    </div>
  )
}

export default ListPills

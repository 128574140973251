import React, { useState } from 'react'

import styled from 'styled-components'

import CabalButton from 'global/CabalButton'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'

import { List } from 'utils/types'

import CompanyDetails from './CompanyDetails'
import PublishedLists from './PublishedLists'
import WelcomeMessage from './WelcomeMessage'

const Nav = styled.div<{ active: boolean }>`
  border-bottom: 2px solid ${({ theme, active }) => (active ? theme.colors.purple : 'transparent')};
`

interface Props {
  onHide: () => void
  teamSlug: string
  companyLists: List[]
  isVcTeam: boolean
}

const PageEditModal: React.FC<Props> = ({ onHide, teamSlug, companyLists, isVcTeam }) => {
  const [step, setStep] = useState(1)

  return (
    <Modal show={true} onHide={onHide} header={'Edit Company Page'}>
      <div className="flex gap-11 items-center w-full">
        <Nav active={step === 1}>
          <Typography
            fontSize="14"
            color={step === 1 ? 'purple' : 'fog'}
            onClick={() => setStep(1)}
            className="cursor-pointer"
          >
            Company Details
          </Typography>
        </Nav>

        <Nav active={step === 2}>
          <Typography
            fontSize="14"
            color={step === 2 ? 'purple' : 'fog'}
            onClick={() => setStep(2)}
            className="cursor-pointer"
          >
            Welcome Message
          </Typography>
        </Nav>
        {!isVcTeam && (
          <Nav active={step === 3}>
            <Typography
              fontSize="14"
              color={step === 3 ? 'purple' : 'fog'}
              onClick={() => setStep(3)}
              className="cursor-pointer"
              lineHeight={2}
            >
              Published Lists
            </Typography>
          </Nav>
        )}
      </div>

      <ModalSectionWrapper>
        {step === 1 && <CompanyDetails teamSlug={teamSlug} />}
        {step === 2 && <WelcomeMessage teamSlug={teamSlug} />}
        {step === 3 && <PublishedLists teamSlug={teamSlug} companyLists={companyLists} />}
      </ModalSectionWrapper>
    </Modal>
  )
}

export default PageEditModal

import React from 'react'

import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import CandidateDataTags from 'containers/CompanyPage/SharedListsSection/CandidateDataTags'
import { usePeopleListContext } from 'containers/ListIndexView/TalentList/PeopleList'
import { ITalentPeople } from 'containers/ListIndexView/TalentList/type'
import { FacilitateIntroModal } from 'containers/Portfolio/MemberView/FacilitateIntroModal'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import GridWrapper from 'ui-components/GridWrapper'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import { cn } from 'utils/styles'

const TalentPerson = ({ person, view }: { person: ITalentPeople; view: 'grid' | 'list' }) => {
  const history = useHistory()
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()

  const { companyListData, refetchPeople } = usePeopleListContext()
  const { candidate_profile: candidateProfile } = person
  const companyListSlug = companyListData.slug

  const openRequestIntroModal = () => {
    showModal(
      (resolve) => (
        <FacilitateIntroModal
          requestable={person}
          companyList={companyListData}
          resolve={resolve}
          gatekeeperId={companyListData?.gatekeeper_id}
          facilitator={companyListData?.owning_team}
          includeInitialNote={false}
        />
      ),
      'facilitate-intro-modal',
    )
  }

  const exportList = useMutation(() => api.exportList(companyListData?.uuid), {
    onSuccess: () => {
      cabalToast({
        content: 'Your export will be emailed to you shortly',
        style: 'success',
      })
    },
  })

  const menuItems = [
    {
      label: 'Export',
      onSelect: () => exportList.mutate(),
    },
  ]

  const gridView = view === 'grid'

  return (
    <DataCardV2
      onClick={() => history.push(`/${teamSlug}/lists/${companyListSlug}/${person.uuid}`)}
      cta={
        <CabalButton
          onClick={() => openRequestIntroModal()}
          variant={gridView ? 'primary' : 'link'}
          className="w-full"
        >
          {'Request Intro'}
        </CabalButton>
      }
      avatar={<Avatar src={person.logo_url} name={person.name} size={gridView ? '72' : '32'} />}
      title={person.name}
      description={person.headline}
      descriptionBottom={
        <div className="flex flex-col items-start gap-2.5 mb-2">
          {candidateProfile?.company && (
            <Typography lineHeight={1}>
              <i className="far fa-building" /> {candidateProfile?.company}
            </Typography>
          )}
          {candidateProfile?.location && (
            <Typography lineHeight={1}>
              <i className="far fa-location-dot" /> {candidateProfile.location}
            </Typography>
          )}
        </div>
      }
      gridPillsSection={<CandidateDataTags company={candidateProfile} />}
      dropDownMenu={menuItems.length > 0 ? { menuItems: menuItems } : undefined}
      view={view}
      className={cn({
        'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded':
          !gridView,
      })}
    />
  )
}

const PeopleListMemberView = () => {
  const { talentPeople, view } = usePeopleListContext()

  const itemRow = (view: 'grid' | 'list') => {
    return (
      <>
        {talentPeople.map((person) => (
          <TalentPerson key={person.uuid} person={person} view={view} />
        ))}
      </>
    )
  }

  return (
    <div>
      {view === 'grid' && <GridWrapper>{itemRow('grid')}</GridWrapper>}
      {view === 'list' && <>{itemRow('list')}</>}
    </div>
  )
}

export default PeopleListMemberView

import * as React from 'react'

import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import styled from 'styled-components'
import tw from 'twin.macro'

import BulkIntroRequest from 'containers/ListView/Companies/BulkIntroRequest'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'

import { joinReactElements } from 'utils/array'
import copyToClipboard from 'utils/copyToClipboard'
import { CompaniesFiltersType } from 'utils/types'
import { BlockItemCardDescriptionPart } from 'utils/types/block'

export interface PageHeaderDataProps {
  title: string
  avatar_url?: string
  description_parts?: BlockItemCardDescriptionPart[] | NonNullable<React.ReactNode>[]
}

interface ActionTypeAddItem {
  label: string
  variant?: 'link' | 'primary' | 'secondary'
  onClick: () => void
}

interface ActionTypeCandidateLink {
  label: string
  variant?: 'link' | 'primary' | 'secondary'
  onClick: () => void
}

interface ActionViewToggle {
  label: string
  onClick: () => void
}

interface ActionExportLink {
  label: string
  onClick: () => void
}

interface ActionExportWithConnectionsLink {
  label: string
  onClick: () => void
}

interface ActionsType {
  comments?: boolean
  edit?: (() => void) | null
  bulkActions?: React.ReactNode | null
  share?: (() => void) | boolean | null
  others?: React.ReactNode | null
  addItem?: ActionTypeAddItem | null
  candidateLink?: ActionTypeCandidateLink | null
  viewToggle?: ActionViewToggle | null
  exportLink?: ActionExportLink | null
  exportWithConnectionsLink?: ActionExportWithConnectionsLink | null
}

interface Props {
  renderFilters?: React.ReactNode
  data: PageHeaderDataProps
  filters?: CompaniesFiltersType
  actions?: ActionsType
  backUrl?: string
  hideBackButton?: boolean
  onBack?: () => void
}

const StickyHeader = styled.div`
  ${tw`sticky z-10 pb-2`}
  top: 0px;
  background-color: ${({ theme }) => theme.layout.nav_bg_color};
`

const PageHeader: React.FC<Props> = ({
  renderFilters,
  data,
  filters,
  backUrl,
  actions = {},
  hideBackButton = false,
  onBack,
}) => {
  const teamSlug = useTeamSlug()
  const history = useHistory()
  const listQuery = useSearchParam('list')
  const { list_id } = useParams<{ list_id?: string }>()
  const list = listQuery || list_id || 'companies'

  return (
    <StickyHeader className="sticky">
      <div className="flex justify-between items-center mt-4 mb-2">
        <div className="flex flex-col space-y-3">
          <div className="flex items-center space-x-2">
            {!hideBackButton && (
              <Typography
                component="button"
                color="fog"
                fontSize="12"
                data-testid="item-back-button"
                className="hover:text-[#5C69D1]"
                onClick={() => {
                  if (onBack) {
                    onBack()
                  } else if (backUrl) {
                    history.push(backUrl)
                  } else {
                    const urlParams = new URLSearchParams(window.location.search)
                    urlParams.delete('search')
                    history.push(`/${teamSlug}/lists/${list}?${urlParams.toString()}`)
                  }
                }}
              >
                <i className="far fa-chevron-left" />
              </Typography>
            )}
            {data.avatar_url && <Avatar size="40px" src={data.avatar_url} name={data.title} />}

            <Typography fontSize="28" fontWeight={600} data-testid="page-header-title">
              {data.title}
            </Typography>
          </div>
        </div>
        <div className="flex-shrink-0 flex items-center">
          {actions.bulkActions && actions.bulkActions}
          {actions.exportLink && !actions.exportWithConnectionsLink && (
            <CabalButton
              leftIcon={<i className="far fa-file-download" />}
              variant="link"
              onClick={() => actions.exportLink && actions.exportLink.onClick()}
            >
              {actions.exportLink.label}
            </CabalButton>
          )}
          {actions.exportLink && actions.exportWithConnectionsLink && (
            <DropDownMenu
              menuItems={[
                {
                  label: 'Companies',
                  onSelect: () => actions?.exportLink?.onClick(),
                },
                {
                  label: 'Companies with connections',
                  onSelect: () => actions?.exportWithConnectionsLink?.onClick(),
                },
              ]}
              trigger={
                <CabalButton leftIcon={<i className="far fa-file-download" />} variant="link">
                  {actions.exportLink.label}
                </CabalButton>
              }
            />
          )}
          {actions.comments && (
            <CabalButton
              variant="link"
              padding="0"
              leftIcon={<i className="far fa-comment" />}
              onClick={() => {
                document.getElementById('item-comments')?.scrollIntoView({ behavior: 'smooth' })
              }}
            >
              Comments
            </CabalButton>
          )}
          {actions.addItem && (
            <CabalButton
              leftIcon={<i className="far fa-plus" />}
              variant={actions.addItem.variant || 'link'}
              onClick={() => actions.addItem && actions.addItem.onClick()}
              data-testid="page-header-add-button"
            >
              {actions.addItem.label || 'Add'}
            </CabalButton>
          )}
          {actions.candidateLink && (
            <CabalButton
              leftIcon={<i className="far fa-link" />}
              variant={actions.candidateLink.variant || 'link'}
              onClick={() => actions.candidateLink && actions.candidateLink.onClick()}
            >
              {actions.candidateLink.label || 'Add'}
            </CabalButton>
          )}
          {actions.edit && (
            <CabalButton
              leftIcon={<i className="far fa-pencil fa-sm" />}
              variant="link"
              onClick={() => actions.edit()}
              data-testid="page-header-edit-button"
            >
              Edit
            </CabalButton>
          )}
          {actions.others && actions.others}
          {actions.share !== false && (
            <CabalButton
              leftIcon={<i className="far fa-arrow-up-from-bracket" />}
              variant="link"
              data-testid="page-header-share-button"
              onClick={() => {
                if (actions.share) {
                  actions.share()
                } else {
                  copyToClipboard(window.location.href, 'Link copied to clipboard')
                }
              }}
            >
              Share
            </CabalButton>
          )}
        </div>
      </div>
      <div>
        {!!data.description_parts?.length && (
          <div className="flex flex-col py-2">
            <div className="-mt-3 flex">
              {joinReactElements(
                data.description_parts?.map((part) => {
                  if (typeof part === 'object' && 'label' in part) {
                    return (
                      <Typography fontSize="14" color="fog" className="truncate" key={part.id}>
                        {part.label}
                      </Typography>
                    )
                  } else {
                    return part
                  }
                }) || [],
                () => (
                  <Typography color="fog" fontSize="12" className="mx-1.5">
                    {'·'}
                  </Typography>
                ),
              )}
            </div>
          </div>
        )}
      </div>

      <div className="flex items-center gap-2">
        {renderFilters && (
          <>
            <div className="flex justify-between whitespace-nowrap w-full mt-1 mb-2 items-center">
              <div className="flex w-full">{renderFilters}</div>
            </div>
            <div>
              <BulkIntroRequest filters={filters} teamSlug={teamSlug} />
            </div>
          </>
        )}
      </div>
    </StickyHeader>
  )
}

export default React.memo(PageHeader)

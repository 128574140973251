import React, { useState } from 'react'

import styled from 'styled-components'

import AddIndividualSection from 'containers/ListIndexView/TalentList/JobsList/JobsAddWidget/AddIndividualSection'
import ImportSection from 'containers/ListIndexView/TalentList/JobsList/JobsAddWidget/ImportSection'
import { WidgetTab } from 'containers/ListIndexView/TalentList/PeopleList/AddCandidate'
import CabalButton from 'global/CabalButton'
import Widget from 'ui-components/Widget'

interface Props {
  onClose: () => void
}

const TabWrapper = styled.div`
  border-bottom: ${({ theme }) => theme.border};
`

const JobsAddWidget: React.FC<Props> = ({ onClose }) => {
  const [tab, setTab] = useState<'add_individually' | 'import'>('add_individually')

  return (
    <Widget
      title="Add jobs"
      cta={
        onClose && (
          <CabalButton
            variant="tertiary"
            onClick={(e) => {
              e.preventDefault()
              onClose()
            }}
            padding={'0'}
            leftIcon={<i className="far fa-times" />}
          ></CabalButton>
        )
      }
      className="mb-4"
    >
      <TabWrapper className="flex gap-8 mt-6">
        <WidgetTab active={tab === 'add_individually'} onClick={() => setTab('add_individually')}>
          <i className="far fa-plus mr-1 fa-sm" /> Add Individually
        </WidgetTab>
        <WidgetTab active={tab === 'import'} onClick={() => setTab('import')}>
          <i className="far fa-file-csv mr-1 fa-sm" /> Import
        </WidgetTab>
      </TabWrapper>

      {tab === 'add_individually' && <AddIndividualSection setOnClose={onClose} />}
      {tab === 'import' && <ImportSection setOnClose={onClose} />}
    </Widget>
  )
}

export default JobsAddWidget

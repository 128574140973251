import React, { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import Defaults from 'containers/Settings/Company/Defaults'
import { useTeam } from 'store/hooks'
import SectionHeader from 'ui-components/SectionHeader'

import { CompanySlugParam, Team } from 'utils/types'

import { CardBg } from '../styles'
import Community from './Community'
import CompanyInfo from './CompanyInfo'
import Features from './Features'

export interface TeamSettingProps {
  team: Team
  reloadTeam: () => Promise<any>
}

const Company = () => {
  const { company_slug: companySlug } = useParams<CompanySlugParam>()
  const { team, reloadTeam } = useTeam(companySlug)

  const [showDefaults, setShowDefaults] = React.useState(false)

  useEffect(() => {
    if (team) {
      setShowDefaults(!team.enable_portfolio)
    }
  }, [team, team?.enable_portfolio])

  const { public_directory } = team || {}

  if (!team) return <></>

  const teamSettingProps: TeamSettingProps = {
    reloadTeam,
    team,
  }

  return (
    <div className="">
      <div>
        <SectionHeader hideTopBorder={true} title="Company Info" />
        <CardBg>
          <CompanyInfo {...teamSettingProps} />
        </CardBg>

        {public_directory && (
          <>
            <SectionHeader title="Directory" />

            <CardBg>
              <Community {...teamSettingProps} />
            </CardBg>
          </>
        )}
        <SectionHeader title="Features" />
        <CardBg>
          <Features onEnablePortfolioChange={(enabled) => setShowDefaults(!enabled)} />
        </CardBg>

        <>
          <SectionHeader title="Defaults" />
          <CardBg>
            <Defaults />
          </CardBg>
        </>
      </div>
    </div>
  )
}

export default Company

import React from 'react'

import queryString from 'query-string'

import Block from 'components/Block'
import Filters from 'components/Block/Filters'
import ListSharingSettings from 'components/EditList/EditListAccess/ListSharingSettings'
import PageHeader from 'containers/ItemDetailPage/PageHeader'
import { useAccessControl } from 'global/AccessControl'
import { RenderModal, useModal } from 'global/Modal/Context'
import { cabalToast } from 'ui-components/Toast'

interface Props {
  list_id: string
  ownerType: string
  ownerId: string
  team: any
  companyList: any
}

const ListViewWithBlocks = (props: Props) => {
  const { list_id, ownerType, ownerId, team, companyList } = props
  const { isAdmin } = useAccessControl(team.slug)
  const { showModal } = useModal()
  const { isAdminOrEmployee } = useAccessControl(team.slug)

  const renderShareListModal: RenderModal = (resolve) => (
    <ListSharingSettings
      teamSlug={team.slug}
      resolve={() => resolve(false)}
      uuid={list_id}
      onDone={() => {
        cabalToast({ style: 'success', content: 'Share settings updated' })
      }}
      header={`Share`}
    />
  )

  return (
    <>
      <PageHeader
        data={{
          title: `${companyList ? companyList.owning_team.name : team.name} Team Connections`,
        }}
        actions={{
          share: isAdmin ? () => showModal(renderShareListModal, 'render_share_list_modal') : null,
        }}
        backUrl={isAdminOrEmployee ? `/${team.slug}/lists` : `/${team.slug}`}
        renderFilters={
          <Filters
            filters={{}}
            filterTypes={[
              {
                key: 'search',
                type: 'search',
                icon: 'far fa-search',
                labelStr: 'Search',
                skipLabelInAppliedPill: true,
                noPopover: true,
              },
            ]}
            block_id={list_id}
          />
        }
      />
      <Block
        blockId={list_id}
        ownerType={ownerType}
        ownerId={ownerId}
        params={{ filters: location.search ? queryString.parse(location.search) : {} }}
      />
    </>
  )
}

export default ListViewWithBlocks

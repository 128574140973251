import { ButtonView, Plugin } from 'ckeditor5'

export default class Signature extends Plugin {
  init() {
    const editor = this.editor
    const signature = editor.config.get('signature')
    if (!signature) return

    editor.ui.componentFactory.add('signature', (locale) => {
      const view = new ButtonView(locale)

      view.set({
        icon: '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="signature" class="svg-inline--fa fa-signature fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M637.2 199.8c-.9-.9-3-2.5-5.7-2.2-36.2 2.4-84.6 29.9-123.4 51.9-16 9.1-29.8 16.9-41.1 22-30.7 14-57.1 26.2-81.4 26.2-10.6 0-18.5-3-23.8-9.3-9.5-11-9.3-29.7-6.1-54.3 3.7-28.4.1-50.5-9.7-61.3-6-6.5-14.5-9.3-25.5-8.6-27.8 1.6-76.6 39-168.7 129.1l-27.4 26.9L181 175.9c13.2-33.5 4-70.1-23.3-93.1-21.8-18.4-58.8-29.2-97.7-4L4 117.1c-4 2.6-5.1 7.8-2.7 11.6L18.9 157c1.2 1.9 3 3.2 5.2 3.7 2.1.4 4.3.1 6.2-1.1L89.6 119c5.4-3.4 11.2-5.1 17-5.1 7 0 13.9 2.5 19.7 7.4 10.6 9 14.2 23.1 9.1 36.1L34.6 413.6c-2.9 7.3-1.7 17.3 3 24.3 3.1 4.6 9 10.1 19.9 10.1 6.6 0 12.8-2.6 17.4-7.3 43.5-44.2 158.5-157.2 217.3-205l14.8-12-1.5 19.2c-2.1 27.9-2.5 57.2 19 81.2 14.1 15.7 34.7 23.7 61.2 23.7 34.8 0 67.2-14.9 101.6-30.6 10.5-4.8 25-13.4 40.3-22.5 35.2-20.9 75.1-44.5 104.4-47 4.7-.4 8.1-3.8 8.1-8.2V206c-.1-2.3-1.1-4.6-2.9-6.2z"></path></svg>',
        label: 'Use Signature',
        tooltip: true,
        withText: false,
      })

      view.on('execute', () => {
        const sig: string = editor.config.get('signature')
        const viewFragment = editor.data.processor.toView(`
          <p><br/></p>
          <p><br/></p>
          <p>--</p>
          ${sig}
        `)
        if (!viewFragment) return

        const modelFragment = editor.data.toModel(viewFragment as any)
        editor.model.insertContent(modelFragment)
      })

      return view
    })
  }
}

import React from 'react'

const createOptionsFor = (mapping: any) => {
  return (
    Object.keys(mapping).map((key) => ({
      value: key,
      label: mapping[key as keyof typeof mapping],
    })) || []
  )
}

export const ABBREVIATED_ROLE_FUNCTION_MAP = {
  product: { title: 'Product', icon: <i className="far fa-pen fa-fw fa-sm" /> },
  engineering: { title: 'Eng', icon: <i className="far fa-code fa-fw fa-sm" /> },
  data_science: { title: 'Data', icon: <i className="far fa-chart-pie-simple fa-fw fa-sm" /> },
  finance: { title: 'Finance', icon: <i className="far fa-fw fa-chart-simple fa-sm" /> },
  design: { title: 'Design', icon: <i className="far fa-palette fa-fw fa-sm" /> },
  people: { title: 'People', icon: <i className="far fa-people-simple fa-fw fa-sm" /> },
  recruiting: { title: 'Talent', icon: <i className="far fa-briefcase fa-fw fa-sm" /> },
  marketing: { title: 'Mrktng', icon: <i className="far fa-megaphone fa-fw fa-sm" /> },
  sales: { title: 'Sales', icon: <i className="far fa-paper-plane fa-fw fa-sm" /> },
  customer_success: { title: 'CS', icon: <i className="far fa-heart fa-fw fa-sm" /> },
  operations: { title: 'Ops', icon: <i className="far fa-gear fa-fw fa-sm" /> },
  legal: { title: 'Legal', icon: <i className="far fa-gavel fa-fw fa-sm" /> },
}

export const ROLE_FUNCTION_MAP = {
  product: 'Product',
  engineering: 'Engineering',
  data_science: 'Data Science',
  finance: 'Finance',
  design: 'Design',
  people: 'People',
  recruiting: 'Recruiting',
  marketing: 'Marketing',
  sales: 'Sales',
  customer_success: 'Customer Success',
  operations: 'Operations',
  legal: 'Legal',
}

export const COMPANY_SIZE_MAP = {
  '1-10': '1-10 employees',
  '11-50': '11-50 employees',
  '51-200': '51-200 employees',
  '201-500': '201-500 employees',
  '501-1000': '501-1000 employees',
  '1001-5000': '1001-5000 employees',
  '5001-10000': '5001-10,000 employees',
  '10001+': '10,001+ employees',
}

export const COMPANY_SZIE_WITH_OPEN_TO_ALL_MAP = {
  open_to_all: 'Open to all sizes',
  ...COMPANY_SIZE_MAP,
}

export const ABBREVIATED_OFFICE_MAP = {
  remote: 'Remote',
  hybrid: 'Hybrid',
  in_office: 'Office',
  willing_to_relocate: 'Reloc',
}

export const OFFICE_MAP = {
  remote: 'Remote',
  hybrid: 'Hybrid',
  in_office: 'In office',
  willing_to_relocate: 'Willing to relocate',
}

export const ABBREVIATED_OFFICE_WITH_OPEN_TO_ALL_MAP = {
  openlocation: 'Any',
  ...ABBREVIATED_OFFICE_MAP,
}

export const OFFICE_WITH_OPEN_TO_ALL_MAP = {
  openlocation: 'Open to all locations',
  ...OFFICE_MAP,
}

export const STAGE_MAP = {
  preseed: 'Pre-seed',
  seed: 'Seed',
  seriesa: 'Series A',
  seriesb: 'Series B',
  seriesc: 'Series C',
  seriesdplus: 'Series D+',
}

export const STAGE_WITH_OPEN_TO_ALL_MAP = {
  openstages: 'Open to all stages',
  ...STAGE_MAP,
}

export const ABBREVIATED_LEVEL_MAP = {
  individual_contributor: 'IC',
  lead: 'Lead',
  manager: 'Lead',
  head: 'Lead',
  director: 'Dir',
  vp: 'VP',
  c_suite: 'Exec',
}

export const LEVEL_MAP = {
  individual_contributor: 'Individual Contributor',
  lead: 'Lead',
  manager: 'Manager / Sr. Manager',
  head: 'Head of',
  director: 'Director / Sr. Director',
  vp: 'VP',
  c_suite: 'C-suite',
}

export const OPPORTUNITY_TYPE_MAP = {
  full_time: 'Full time',
  advisory: 'Advisory',
  interim: 'Interim',
  networking: 'Networking',
}

export const STATUS_MAP = {
  active: 'Active',
  archived: 'Archived',
}

export const ROLE_FUNCTION_OPTIONS = createOptionsFor(ROLE_FUNCTION_MAP)
export const COMPANY_SIZE_OPTIONS = createOptionsFor(COMPANY_SIZE_MAP)
export const COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS = createOptionsFor(
  COMPANY_SZIE_WITH_OPEN_TO_ALL_MAP,
)
export const OFFICE_OPTIONS = createOptionsFor(OFFICE_MAP)
export const OFFICE_WITH_OPEN_TO_ALL_OPTIONS = createOptionsFor(OFFICE_WITH_OPEN_TO_ALL_MAP)
export const STAGE_OPTIONS = createOptionsFor(STAGE_MAP)
export const STAGE_WITH_OPEN_TO_ALL_OPTIONS = createOptionsFor(STAGE_WITH_OPEN_TO_ALL_MAP)
export const LEVEL_OPTIONS = createOptionsFor(LEVEL_MAP)
export const OPPORTUNITY_TYPE_OPTIONS = createOptionsFor(OPPORTUNITY_TYPE_MAP)
export const STATUS_OPTIONS = createOptionsFor(STATUS_MAP)

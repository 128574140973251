/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import CreateManualIntro from 'containers/IntroRequestModal/components/Modals/ManualIntro/CreateManualIntro'
import { BasicTable } from 'containers/RequestCenter/BasicTable'
import { HeaderForCenter } from 'containers/RequestCenter/HeaderForCenter'
import { PeopleSearch } from 'containers/RequestCenter/PeopleSearch'
import { StatusSearch } from 'containers/RequestCenter/StatusSearch'
import { REQUEST_CENTER_ENDPOINTS } from 'containers/RequestCenter/apiEndpoints'
import { clearbitUrl } from 'containers/RequestCenter/constants'
import { useFetchIntroRequests } from 'containers/RequestCenter/queries/FetchIntroRequests'
import { useFetchValidStatus } from 'containers/RequestCenter/queries/FetchValidStatus'
import { useExportRequestCenterData } from 'containers/RequestCenter/queries/RequestCenterExport'
import {
  getParamsFromUrlOrState,
  prepareStatusForState,
  updateUrlWithSearchParams,
} from 'containers/RequestCenter/utils'
import CabalButton from 'global/CabalButton'
import { RenderModal, useModal } from 'global/Modal/Context'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import {
  IIntroStateParams,
  IPageLoadParams,
  IPaginationExtended,
  IRequestCenterResponse,
  IRequestsData,
} from './types/ITypes'

export function ManageRequestCenter() {
  const { showModal } = useModal()
  const [requestCenterData, setRequestCenterData] = useState<IRequestsData[] | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState<number | null>(1)
  const [reload, setReload] = useState<boolean>(false)
  const [nextPage, setNextpage] = useState<number | null>(null)
  const [prevPage, setPrevPage] = useState<number | null>(null)
  const [order, setOrder] = useState<{ order_by: string; direction: string }>({
    order_by: '',
    direction: 'desc',
  })
  const [pagination, setPagination] = useState<IPaginationExtended | null>(null)
  const [statusRequestQuery, setStatusRequestQuery] = useState<string[] | null>(null)
  const [fetchRequestQuery, setFetchRequestQuery] = useState<string | null>(null)
  const location = useLocation<IIntroStateParams | undefined>()
  const [fetchRecords, setFetchRecords] = useState<boolean>(false)
  const [validStatusArr, setValidStatusArr] = useState<string[] | null>(null)
  const [initialRedirectFetch, setInitialRedirectFetch] = useState<boolean>(
    location?.state?.initialRedirectFetch ? location?.state?.initialRedirectFetch : true,
  )
  const history = useHistory()
  const { refetch: refetchRecords, isLoading: isRecordsLoading } = useFetchIntroRequests({
    enabled: false,
    page: page,
    name: fetchRequestQuery,
    status: statusRequestQuery,
    order_by: order.order_by,
    direction: order.direction,
  })
  const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { refetch: refetchValidStatus } = useFetchValidStatus({
    teamId: team!.uuid,
    enabled: false,
  })
  const { mutate: exportMutate } = useExportRequestCenterData({
    teamSlug: teamSlug,
    name: fetchRequestQuery,
    status: statusRequestQuery,
  })
  const [isMouseOverTable, setIsMouseOverTable] = useState(false)

  useEffect(() => {
    if (order.order_by) {
      setPage(1)
      setFetchRecords(true)
      updateUrl({
        introPage: page === undefined ? null : page,
        order_by: order.order_by,
        direction: order.direction,
      })
    }
  }, [order])

  const handleMouseEnter = () => {
    setIsMouseOverTable(true)
  }

  const handleMouseLeave = () => {
    setIsMouseOverTable(false)
  }

  // Assigns data fetched from server for intro_requests and pagination to state variables
  //
  const assignRecordsToState = (responseData: IRequestCenterResponse) => {
    setRequestCenterData((_prev) => responseData.intro_requests)
    setPagination((_prev) => responseData.pagination)
    setNextpage((_prev) => responseData.pagination.next_page)
    setPrevPage((_prev) => responseData.pagination.prev_page)
    setPage((_prev) => responseData.pagination.current_page)
  }
  // Assigns state from location state object passed through redirection
  // from IntroDetails page
  // sets fetchRecords to true, so fetchIntroRequests hook can be called
  // with all the state variables as parameters
  //
  const assignFromUrlSearchOrState = ({ introPage, introName, introStatus }: IIntroStateParams) => {
    if (introName) {
      setFetchRequestQuery((_prev) => introName)
    }
    if (introStatus) {
      setStatusRequestQuery((_prev) => introStatus.split(','))
    }
    if (introPage) {
      setPage((_prev) => introPage)
      setNextpage((_prev) => introPage)
    }
    setFetchRecords((_prev) => true)
  }

  // Fetches records when redirected from IntroDetails page or when next/previous button
  // is clicked
  //
  const handleFetchRecords = () => {
    refetchRecords().then((response) => {
      if (response && response.data) {
        const resJsonData = JSON.parse(JSON.stringify(response.data.pages[0].data))
        assignRecordsToState(resJsonData)
      }
    })
  }
  const pageLoad = ({ next, prev }: IPageLoadParams) => {
    if (next) {
      setPage(nextPage)
    } else {
      setPage(prevPage)
    }
    setFetchRecords(true)
  }

  // Calls updateUrlWithSearchParams method with required parameters
  //
  const updateUrl = ({
    introPage,
    order_by,
    direction,
  }: {
    introPage: number | null
    order_by: string | null
    direction: string | null
  }) => {
    updateUrlWithSearchParams({
      introPage: introPage,
      introName: fetchRequestQuery,
      introStatus: prepareStatusForState(statusRequestQuery),
      history: history,
      order_by: order_by,
      direction: direction,
    })
  }

  // initial fetch of records when user first lands on intro-details page with
  // search/state parameters or redirects back from intro-details page to intro-center
  // or clicks next/previous buttons
  //
  const isInitialFetchRedirectPagination = () => {
    return (
      (fetchRecords && (page === nextPage || page === prevPage)) ||
      (fetchRecords && !initialRedirectFetch)
    )
  }

  // initial setup of table data when user first lands on intro-details page without
  // search/state parameters
  // When status/name field is cleared, this method is used to setup table
  //
  const initialTableSetup = () => {
    return !fetchRequestQuery && !statusRequestQuery && !fetchRecords && !initialRedirectFetch
  }
  // user selects a status from drop down, or types in person/company name
  //
  const isNameOrStatusChanged = () => {
    return (
      !fetchRecords &&
      !(page === nextPage || page === prevPage) &&
      (fetchRequestQuery || statusRequestQuery) &&
      !initialRedirectFetch
    )
  }

  useEffect(() => {
    // Fetch valid status for given team if portfolio feature is disabled
    //
    if (team && !team.enable_portfolio && !validStatusArr) {
      refetchValidStatus().then((response) => {
        if (response && response.data && response.data.data) {
          const resJsonData = JSON.parse(JSON.stringify(response.data.data.data))
          setValidStatusArr(resJsonData)
        }
      })
    }
    // When user is redirected from intro details page to intro center, information about
    // page, name, status is passed through state parameters in location object
    // Checks if state object has any of these parameters and assigns state variable
    // to the location state parameters
    // If user has bookmarked any url with search parameters and is accessing it directly
    // in browser the search parameters will take precedence over state parameters and
    // be used to load the page
    // For normal usage, search, fetchRecords and redirect to intro details page will occur
    // through state parameters, the URL will only reflect the state parameters in search url
    // Once state parameters been used to assign, location state object is set to null
    // sets fetchRecords to true, so the index action is called with the useFetchIntroRequests hook
    //
    if (initialRedirectFetch) {
      const { introPage, introName, introStatus } = getParamsFromUrlOrState({
        stateObject: location.state,
        locationSearch: location.search,
      })
      assignFromUrlSearchOrState({ introName, introPage, introStatus })
      history.replace({
        ...location,
        state: { introPage: null, introName: null, introStatus: null },
      })
      setInitialRedirectFetch(false)
    }
    // Initial fetch when intro-page is loaded, fetchRequestQuery, statusRequestQuery, and location state
    // or search parameters are null at this time including fetchRecords
    // When status/name field is cleared, this method is used to setup table
    if (initialTableSetup()) {
      refetchRecords().then((response) => {
        if (response && response.data) {
          const resJsonData = JSON.parse(JSON.stringify(response.data.pages[0].data))
          assignRecordsToState(resJsonData)
        }
      })
    }

    // this is used to setup the initial load of page with table data
    // when user selects status or types in a name of person/company in search bar, this code block
    // is called. useQuery hook does not cause immediate load of component, and hence directly used
    // fetch here
    if (isNameOrStatusChanged()) {
      fetch(
        `${REQUEST_CENTER_ENDPOINTS.index}?${fetchRequestQuery ? `name=${fetchRequestQuery}` : ''}${
          fetchRequestQuery && statusRequestQuery ? '&' : ''
        }${statusRequestQuery ? `status=${statusRequestQuery.join(',')}` : ''}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': csrfToken || '',
          },
        },
      ).then((response: Response) => {
        response
          .json()
          .then((jsonResponse: IRequestCenterResponse) => {
            if (jsonResponse) {
              assignRecordsToState(jsonResponse)
              // update URL with search parameters
              updateUrl({ introPage: null, order_by: null, direction: null })
            }
          })
          .catch((error) => {
            console.error('Failed to fetch request center data:', error)
          })
      })
    }

    // Initial fetch of records with search/state parameters, redirects from intro-details page
    // to intro-center or clicks next/previous button
    //
    if (isInitialFetchRedirectPagination()) {
      handleFetchRecords()
      setFetchRecords(false)
      // update URL with search parameters
      updateUrl({
        introPage: page === undefined ? null : page,
        order_by: order.order_by === '' ? null : order.order_by,
        direction: order.order_by === '' ? null : order.direction,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, fetchRequestQuery, statusRequestQuery, page, fetchRecords, location.state])

  const exportIntroRequests = () => {
    {
      exportMutate()
      cabalToast({
        content: 'Your export will be emailed to you shortly',
        style: 'success',
      })
    }
  }

  const renderManualIntroModal: RenderModal = (resolve) => (
    <CreateManualIntro team={team}></CreateManualIntro>
  )

  return (
    <PageWrapper title="Intro Center">
      <HeaderForCenter>
        <PeopleSearch
          setReload={setReload}
          fetchRequestQuery={fetchRequestQuery}
          setFetchRequestQuery={(newFetchRequestQuery) => {
            setFetchRequestQuery(newFetchRequestQuery)
          }}
        />
        <StatusSearch
          setStatusRequestQuery={(newStatus) => {
            setStatusRequestQuery(newStatus)
          }}
          validStatusArr={validStatusArr}
          statusRequestQuery={statusRequestQuery}
        />
        <div className="flex justify-end w-full">
          <CabalButton
            variant="link"
            onClick={() => showModal(renderManualIntroModal, 'render_manual_intro_modal')}
            leftIcon={<i className="far fa-plus"></i>}
          >
            Add
          </CabalButton>
          <CabalButton
            variant="link"
            onClick={() => exportIntroRequests()}
            leftIcon={<i className="far fa-file-download fa-fw"></i>}
          >
            Export
          </CabalButton>
        </div>
      </HeaderForCenter>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <BasicTable
          requestCenterData={requestCenterData}
          pagination={pagination}
          introPage={page}
          introStatus={statusRequestQuery}
          introName={fetchRequestQuery}
          isMouseOverTable={isMouseOverTable}
          setOrder={setOrder}
        />
      </div>
      <div className="flex justify-between items-center">
        <div className="flex justify-center">
          <Typography fontSize="14" color="fog_rain">
            {pagination?.current_results_set_text}. Go to page:
          </Typography>
          <select
            style={{
              marginLeft: '5px',
              padding: '0 15px',
              fontSize: '14px',
              color: '#333',
              borderRadius: '5px',
              paddingRight: '25px',
              border: '1px solid #ccc',
            }}
            value={page || 1}
            onChange={(e) => {
              setPage(Number(e.target.value))
              updateUrl({
                introPage: Number(e.target.value),
                order_by: order.order_by,
                direction: order.direction,
              })
            }}
          >
            {[...Array(pagination?.total_pages)].map((_, i) => (
              <option key={i} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end align-center gap-x-4">
          <div className="flex justify-center">
            <CabalButton
              variant="secondary"
              disabled={isRecordsLoading || prevPage === null}
              onClick={() => pageLoad({ next: false, prev: true })}
            >
              Previous
            </CabalButton>
          </div>
          <div className="flex justify-center">
            <CabalButton
              variant="secondary"
              disabled={isRecordsLoading || nextPage === null}
              onClick={() => pageLoad({ next: true, prev: false })}
            >
              Next
            </CabalButton>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <Typography color="fog" fontSize="12">
          <a href={clearbitUrl}>Logos provided by Clearbit</a>
        </Typography>
      </div>
    </PageWrapper>
  )
}

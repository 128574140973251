import { ButtonView, Plugin } from 'ckeditor5'
import { TextEditorProps } from '../v2'

export default class ExtraButtons extends Plugin {
  init() {
    const editor = this.editor
    const extraButtons: {
      buttons: TextEditorProps['toolbarLeftButtons']
      onClick: TextEditorProps['onToolbarButtonClick']
    } = editor.config.get('extraButtons')

    if (!extraButtons || !extraButtons.buttons) return

    extraButtons.buttons.forEach((extraButton) => {
      editor.ui.componentFactory.add('extra-button-' + extraButton.command, (locale) => {
        const view = new ButtonView(locale)

        view.set({
          icon: extraButton.icon,
          label: extraButton.label,
          tooltip: true,
          withText: false,
        })

        view.on('execute', () => {
          extraButtons.onClick?.(extraButton.command)
        })

        return view
      })
    })
  }
}

import * as React from 'react'

import compact from 'lodash/compact'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { history } from 'webextension-polyfill'

import { useComposer } from 'components/Composer'
import CrmIcon from 'components/CrmIcon'
import useCrmStatus from 'components/CrmStatus'
import { EditListModal } from 'components/EditList'
import ActivityTrackingModal from 'components/GlobalProfile/ActivityTrackingModal'
import OpenOfferModal from 'components/OpenOfferModal'
import EditGroupsModal from 'containers/Groups/EditGroupsModal'
import CreateManualIntro from 'containers/IntroRequestModal/components/Modals/ManualIntro/CreateManualIntro'
import { useAccessControl } from 'global/AccessControl'
import DropDownMenu, { DropdownMenuItem } from 'global/DropDownMenu'
import { RenderModal, useModal } from 'global/Modal/Context'
import { useAdvisorship, useGroups, useTeam } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { useSlug } from 'utils/hooks/useSlug'
import { CompanyListBlueprint } from 'utils/types'

const DropDownWrapper = styled.div`
  & > button {
    width: 100%;
    height: 100%;
  }
`

const ComposeButton = styled.button`
  ${tw`rounded-full h-full`}
  color: ${({ theme }) => theme.buttons.primary.text_color};
  background-color: ${({ theme }) => theme.buttons.primary.bg_color};
  font-size: 14px;
  font-weight: 600;
  width: 40px;
  height: 40px;

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.buttons.primary.bg_hover};
  }
`

interface Props {
  slug: string
  hideText?: boolean
}
const NewButton: React.VFC<Props> = ({ slug }) => {
  const { compose } = useComposer(slug)
  const history = useHistory()
  const { showModal } = useModal()
  const { reloadGroups } = useGroups(slug)
  const { team } = useTeam(slug)
  const { advisor } = useAdvisorship(slug)
  const { isAdminOrEmployee, canEditGroups, canSendAgreement, canSendMessages, canAddAdvisor } =
    useAccessControl(slug)
  const { salesforceConnected, hubspotConnected } = useCrmStatus(slug)
  const noCrmAdded = !salesforceConnected && !hubspotConnected
  const showNewListOption = isAdminOrEmployee
  const showHubspotListOption = showNewListOption && (noCrmAdded || hubspotConnected)
  const showSalesforceListOption = showNewListOption && (noCrmAdded || salesforceConnected)

  const renderNewListModal: RenderModal = (resolve) => (
    <EditListModal onHide={() => resolve(false)} teamSlug={slug} />
  )

  const renderNewDynamicListModal: (
    resolve: (value: boolean) => void,
    provider: 'salesforce' | 'hubspot',
  ) => React.ReactNode = (resolve, provider) => (
    <EditListModal
      dynamic={true}
      onHide={() => resolve(false)}
      reload={() => null}
      teamSlug={slug}
      currentStep={1}
      listCategory="sales"
      listType="companies"
      provider={provider}
    />
  )

  const renderGroupAddModal: RenderModal = (resolve) => (
    <EditGroupsModal
      teamSlug={slug}
      onHide={() => resolve(false)}
      onSave={() => {
        reloadGroups()
      }}
      onDelete={() => {
        reloadGroups()
        resolve(false)
      }}
    />
  )

  const renderActivityTrackingModal: RenderModal = (resolve) => (
    <ActivityTrackingModal
      show
      teamSlug={slug}
      onHide={() => resolve(false)}
      refetchActivity={() => null}
      advisorUuid={advisor?.uuid}
    ></ActivityTrackingModal>
  )

  const renderManualIntroModal: RenderModal = (resolve) => (
    <CreateManualIntro team={team}></CreateManualIntro>
  )

  const renderOpenOfferModal: RenderModal = (resolve) => (
    <OpenOfferModal teamSlug={slug} show onHide={() => resolve(false)}></OpenOfferModal>
  )

  const AdminOrEmployeeMenu: DropdownMenuItem[] = compact([
    canSendMessages && {
      label: (
        <div data-testid="create-message">
          <i className="far fa-pen fa-fw mr-3"></i>
          {'Message'}
        </div>
      ),
      onSelect: () => {
        compose()
      },
    },
    {
      label: (
        <div data-testid="add-group">
          <i className="far fa-trophy fa-fw mr-3"></i>
          {'Intro'}
        </div>
      ),
      onSelect: () => showModal(renderManualIntroModal, 'render_manual_intro_modal'),
    },
    canAddAdvisor && {
      label: (
        <div data-testid="add-member">
          <i className="far fa-user fa-fw mr-3"></i>
          {'Member'}
        </div>
      ),
      onSelect: () => {
        history.push(`/${slug}/import`)
      },
    },
    canEditGroups && {
      label: (
        <div data-testid="add-group">
          <i className="far fa-users fa-fw mr-3"></i>
          {'Group'}
        </div>
      ),
      onSelect: () => showModal(renderGroupAddModal, 'render_group_add_modal'),
    },
    showNewListOption && {
      label: (
        <div data-testid="add-group">
          <i className="far fa-list fa-fw mr-3"></i>
          {'List'}
        </div>
      ),
      onSelect: () => showModal(renderNewListModal, 'render_new_list_modal'),
    },
    canSendAgreement && {
      label: (
        <div data-testid="add-adgreement">
          <i className="far fa-signature fa-fw mr-3"></i>
          {'Agreement'}
        </div>
      ),
      onSelect: () => {
        history.push(`/${slug}/agreements/new`)
      },
    },

    team?.features?.open_ended_offers && {
      label: (
        <div>
          <i className="far fa-square-kanban fa-fw mr-3"></i>
          {'Offer'}
        </div>
      ),
      onSelect: () => showModal(renderOpenOfferModal, 'render_open_offer_modal'),
    },
  ])

  const MembersMenu: DropdownMenuItem[] = compact([
    team?.features?.open_ended_offers && {
      label: (
        <div>
          <i className="far fa-square-kanban fa-fw mr-3"></i>
          {'Offer'}
        </div>
      ),
      onSelect: () => showModal(renderOpenOfferModal, 'render_open_offer_modal'),
    },
    {
      label: (
        <div data-testid="add-group">
          <i className="far fa-trophy fa-fw mr-3"></i>
          {'Contribution'}
        </div>
      ),
      onSelect: () => showModal(renderActivityTrackingModal, 'render_activity_tracking_modal'),
    },
    canSendMessages && {
      label: (
        <div data-testid="create-message">
          <i className="far fa-pen fa-fw mr-3"></i>
          {'Message'}
        </div>
      ),
      onSelect: () => {
        compose()
      },
    },
  ])

  const menu = isAdminOrEmployee ? AdminOrEmployeeMenu : MembersMenu

  if (showHubspotListOption) {
    menu.push({
      label: (
        <div data-testid="add-group">
          <CrmIcon crm={'hubspot'} className="mr-3 fa-fw" />
          {'HubSpot List'}
        </div>
      ),
      onSelect: () => {
        if (hubspotConnected) {
          showModal(
            (resolve) => renderNewDynamicListModal(resolve, 'hubspot'),
            'render_hubspot_list_modal',
          )
        } else {
          if (window.location.host === 'localhost:3000') {
            window.location.assign(`/auth/hubspotdev?return_to=${window.location.href}`)
          } else {
            window.location.assign(`/auth/hubspot?return_to=${window.location.href}`)
          }
        }
      },
    })
  }

  if (showSalesforceListOption) {
    menu.push({
      label: (
        <div data-testid="add-group">
          <CrmIcon crm={'salesforce'} className="mr-3 fa-fw" />
          {'Salesforce List'}
        </div>
      ),
      onSelect: () => {
        if (salesforceConnected) {
          showModal(
            (resolve) => renderNewDynamicListModal(resolve, 'salesforce'),
            'render_sfdc_list_modal',
          )
        } else {
          window.location.assign(`/salesforce_auth`)
        }
      },
    })
  }

  return (
    <DropDownWrapper>
      <DropDownMenu
        portal={false}
        trigger={
          <ComposeButton data-testid="create-new">
            <i className="far fa-plus"></i>
          </ComposeButton>
        }
        menuItems={menu}
      ></DropDownMenu>
    </DropDownWrapper>
  )
}

export default NewButton

import React, { useMemo, useState } from 'react'

import compact from 'lodash/compact'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import OptionCardBlock from 'components/CompanyHome/OptionCardBlock'
import CrmIcon from 'components/CrmIcon'
import useCrmStatus from 'components/CrmStatus'
import EditListDetails from 'components/EditList/Details'
import ListSharingSettings from 'components/EditList/EditListAccess/ListSharingSettings'
import CabalButton from 'global/CabalButton'
import { Label, Select } from 'global/Input'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { joinReactElements } from 'utils/array'
import { CompanyListBlueprint, Team } from 'utils/types'

const SourceBlock = styled.button`
  ${tw`flex-1 flex flex-col rounded-lg items-center p-4 gap-4 hover:ring-2`}

  background-color: ${({ theme }) => theme.colors.cardBackground};
`

const ListItemsContainer = styled.div`
  ${tw`p-6 pt-2 rounded-lg`}

  background-color: ${({ theme }) => theme.colors.cardBackground};
  width: 600px;
`

interface Props {
  teamAdvised: Team
  nextStep: () => void
}

const ListDetails: React.FC<Props> = ({ teamAdvised, nextStep }) => {
  const history = useHistory()
  const { showModal } = useModal()
  const { user } = useCurrentUser()
  const { crmOrListId } = useParams<{
    crmOrListId?: string
  }>()
  const { team: userTeam } = useTeam(user.team!.slug)
  const { salesforceConnected, hubspotConnected } = useCrmStatus(user.team?.slug)
  const [selectedList, setSelectedList] = useState<CompanyListBlueprint | null>()

  const { mutate: updateCompanyListAccess, isLoading: isAddingAccess } = useMutation(
    ['updateCompanyListAccess'],
    (list: CompanyListBlueprint) =>
      callApi(api.addListAccessors, user.team!.slug, list.uuid, {
        accessors: [{ accessor_type: 'Team', accessor_uuid: teamAdvised.slug }],
        notify: true,
        message: '',
      }),
    {
      onSuccess: (_, list) => {
        history.push(`/${user.team!.slug}/lists/${list.slug}`, { shared_with: teamAdvised })
      },
    },
  )

  React.useEffect(() => {
    if (crmOrListId === 'salesforce' && !salesforceConnected) {
      cabalToast({
        content: 'Redirecting you to Salesforce...',
        passive: true,
      })

      window.location.assign(`/salesforce_auth?return_to=${window.location.pathname}/salesforce`)
    } else if (crmOrListId === 'hubspot' && !hubspotConnected) {
      cabalToast({
        content: 'Redirecting you to HubSpot...',
        passive: true,
      })
      window.location.assign(`/auth/hubspot?return_to=${window.location.pathname}/hubspot`)
    }
  }, [crmOrListId, salesforceConnected, hubspotConnected])

  const getListsQuery = useQuery(
    ['getCompanyLists', userTeam?.slug],
    () => callApi(api.getCompanyLists, userTeam!.slug),
    {
      enabled: !!userTeam,
    },
  )
  const companyLists = (getListsQuery.data?.company_lists || []).filter(
    (l) => !l.portfolio && !!l.owned,
  )
  const alreadyHasList = !!companyLists.length
  const editingList =
    (crmOrListId === 'salesforce' && salesforceConnected) ||
    (crmOrListId === 'hubspot' && hubspotConnected) ||
    (!!crmOrListId && ['manual', 'companies', 'personas', 'jobs'].includes(crmOrListId))

  const listUuid = useMemo(() => {
    if (!crmOrListId) return

    if (['manual', 'companies', 'personas', 'salesforce', 'hubspot', 'jobs'].includes(crmOrListId))
      return

    return crmOrListId
  }, [crmOrListId])

  if (!userTeam || getListsQuery.isLoading) return <Loading className="my-10" />

  if (editingList) {
    return (
      <ListItemsContainer>
        <EditListDetails
          defaultListName={
            crmOrListId === 'jobs'
              ? 'Target Jobs'
              : crmOrListId === 'personas'
              ? 'Target Personas'
              : 'Target Accounts'
          }
          listUuid={listUuid}
          teamSlug={userTeam.slug}
          onSubmit={(list) => {
            updateCompanyListAccess(list)
          }}
          modal={false}
          dynamic={!listUuid && !['manual', 'companies', 'personas', 'jobs'].includes(crmOrListId)}
          listType={
            crmOrListId === 'jobs' ? 'jobs' : crmOrListId === 'personas' ? 'personas' : 'companies'
          }
          listCategory={crmOrListId === 'jobs' ? 'talent' : 'sales'}
          hideListType
          hideOfferNotification
          hideTargetPersonas
          hideTemplate
          hideListItemsLabel
          hideAdvance
          hideDefaultRequestor
          renderSubmitButton={(props) => (
            <div className="flex-1">
              <CabalButton className="w-full" {...props} working={props.working || isAddingAccess}>
                Share with {teamAdvised.name}
              </CabalButton>
            </div>
          )}
        />
      </ListItemsContainer>
    )
  }

  return (
    <div className="space-y-12 sm:w-[620px]">
      {alreadyHasList && (
        <div className="flex">
          <Label
            label={
              <Typography color="primary" fontWeight={500} fontSize={'13'}>
                Choose an existing list
              </Typography>
            }
            className="flex-1"
          >
            <div className="flex flex-col items-center gap-2">
              <Select
                className="w-full"
                value={selectedList}
                options={companyLists.map((l) => ({
                  label: joinReactElements(
                    compact([
                      l.name,
                      `${l.item_count} items`,
                      l.provider && <CrmIcon crm={l.provider} />,
                    ]),
                    () => ' - ',
                  ),
                  toString: () => l.name,
                  value: l,
                }))}
                onChange={(v) => {
                  setSelectedList(v)
                }}
                isClearable
                placeholder="Select a list"
                lightBg
              />
              {selectedList && (
                <Typography className="whitespace-nowrap hover:underline">
                  <a
                    href={`/${userTeam.slug}/lists/${selectedList.uuid}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="far fa-external-link" /> View List
                  </a>
                </Typography>
              )}
            </div>
          </Label>
        </div>
      )}

      {!selectedList && (
        <div>
          <Typography fontWeight={500} fontSize={'13'}>
            {alreadyHasList ? 'Or c' : 'C'}reate a new list
          </Typography>
          <div className="flex gap-2 mt-2">
            <OptionCardBlock
              title={
                <span>
                  Share pipeline <br />
                  from Salesforce
                </span>
              }
              icon={
                <Typography fontSize="18" style={{ color: '#1798c1' }}>
                  <i className="fab fa-salesforce fa-fw" />
                </Typography>
              }
              onClick={() => history.push(`${window.location.pathname}/salesforce`)}
            />
            <OptionCardBlock
              title={
                <span>
                  Share pipeline <br />
                  from Hubspot
                </span>
              }
              icon={
                <Typography fontSize="18" style={{ color: '#FF7A59' }}>
                  <i className="fab fa-hubspot fa-fw" />
                </Typography>
              }
              onClick={() => {
                history.push(`${window.location.pathname}/hubspot`)
              }}
            />
            <OptionCardBlock
              title="Add target Companies"
              icon={<i className="far fa-plus fa-fw" />}
              onClick={() => {
                history.push(`${window.location.pathname}/companies`)
              }}
            />
            <OptionCardBlock
              title={
                <span>
                  Add target <br />
                  Personas
                </span>
              }
              icon={<i className="far fa-people fa-fw" />}
              onClick={() => {
                history.push(`${window.location.pathname}/personas`)
              }}
            />
          </div>
        </div>
      )}

      {selectedList && (
        <div className="flex-1">
          <CabalButton
            className="w-full"
            working={isAddingAccess}
            onClick={() => updateCompanyListAccess(selectedList)}
          >
            Share with {teamAdvised.name}
          </CabalButton>
        </div>
      )}
    </div>
  )
}

export default ListDetails

import React from 'react'

import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import CandidateNoteModal from 'containers/CandidateProfile/CandidateNoteModal'
import CandidateProfileModal from 'containers/CandidateProfileModal'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import PillV2 from 'ui-components/PillV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

interface Props {
  filter: string
  onSetFilter: (filter: string) => void
  reloadCandidateProfile: () => void
  candidateUuid: string
  teamSlug: string
  isCandidate: boolean
  isCandidateProfilePage?: boolean
  showCandidatePreferences?: boolean
  showCandidateNotes?: boolean
  showCandidateDocuments?: boolean
  archived: boolean
  listUuid?: string
}

const FilterPills: React.FC<Props> = ({
  filter,
  onSetFilter,
  candidateUuid,
  teamSlug,
  reloadCandidateProfile,
  isCandidate,
  isCandidateProfilePage = false,
  showCandidatePreferences = true,
  showCandidateNotes = true,
  showCandidateDocuments = true,
  archived,
  listUuid,
}) => {
  const { showModal } = useModal()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const history = useHistory()
  const [deleting, setDeleting] = React.useState(false)

  const renderCandidateProfileModal = (resolve: () => void, linkedinUrl?: string) => (
    <CandidateProfileModal
      listUuid={''}
      teamSlug={teamSlug}
      onFinalize={() => {
        reloadCandidateProfile()
        resolve()
      }}
      linkedinUrl={linkedinUrl}
      onHide={() => resolve()}
      inPeopleList={false}
      candidateUuid={candidateUuid}
    />
  )

  const renderCandidateNoteModal = (resolve: () => void) => (
    <CandidateNoteModal
      candidateUuid={candidateUuid}
      teamSlug={teamSlug}
      onHide={() => resolve()}
      onFinalize={() => {
        reloadCandidateProfile()
        resolve()
      }}
      action={'create'}
    />
  )

  const { mutate: bulkAction } = useMutation(
    (action: string) =>
      callApi(api.candidatesBulkActions, {
        list_uuid: listUuid,
        selected_candidates_uuids: [candidateUuid],
        bulk_action: action,
        kind: 'candidates',
      }),
    {
      onSuccess: (_, action) => {
        reloadCandidateProfile()
        cabalToast({ style: 'success', content: `Successfully ${action}d!` })
      },
      onError: () => {
        cabalToast({
          style: 'error',
          content: 'Something went wrong, please try again or contact support',
        })
      },
    },
  )

  const showPreferences =
    (isCandidateProfilePage && showCandidatePreferences) || !isCandidateProfilePage
  const showNotes = (isCandidateProfilePage && showCandidateNotes) || !isCandidateProfilePage
  const showDocuments =
    (isCandidateProfilePage && showCandidateDocuments) || !isCandidateProfilePage

  return (
    <div className="flex justify-between items-center mt-6">
      <div className="flex flex-wrap gap-2">
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              All
            </Typography>
          }
          active={filter === ''}
          onClick={() => onSetFilter('')}
        />
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              <i className={`far fa-user mr-1.5`} />
              About
            </Typography>
          }
          active={filter === 'About'}
          onClick={() => onSetFilter('About')}
        />
        {showPreferences && (
          <PillV2
            title={
              <Typography fontSize="12" lineHeight={1}>
                <i className={`far fa-list-ol mr-1.5`} />
                Preferences
              </Typography>
            }
            active={filter === 'Preferences'}
            onClick={() => onSetFilter('Preferences')}
          />
        )}
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              <i className={`far fa-briefcase mr-1.5`} />
              Experience
            </Typography>
          }
          active={filter === 'Experience'}
          onClick={() => onSetFilter('Experience')}
        />
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              <i className={`far fa-school mr-1.5`} />
              Education
            </Typography>
          }
          active={filter === 'Education'}
          onClick={() => onSetFilter('Education')}
        />
        {isAdminOrEmployee && showNotes && (
          <PillV2
            title={
              <Typography fontSize="12" lineHeight={1}>
                <i className={`far fa-note mr-1.5`} />
                Notes
              </Typography>
            }
            active={filter === 'Notes'}
            onClick={() => onSetFilter('Notes')}
          />
        )}
        {showDocuments && (
          <PillV2
            title={
              <Typography fontSize="12" lineHeight={1}>
                <i className={`far fa-files mr-1.5`} />
                Documents
              </Typography>
            }
            active={filter === 'Documents'}
            onClick={() => onSetFilter('Documents')}
          />
        )}
      </div>
      <div>
        {isAdminOrEmployee && (
          <>
            <CabalButton
              onClick={() =>
                showModal((resolve) => renderCandidateNoteModal(resolve), 'render_candidate_note')
              }
              variant="link"
            >
              Add Note
            </CabalButton>
            <CabalButton
              onClick={() => bulkAction(archived ? 'restore' : 'archive')}
              variant="link"
            >
              {archived ? 'Restore' : 'Archive'}
            </CabalButton>
          </>
        )}

        {(isAdminOrEmployee || isCandidate) && (
          <>
            <CabalButton
              onClick={() =>
                showModal(
                  (resolve) => renderCandidateProfileModal(resolve, ''),
                  'render_candidate_profile',
                )
              }
              variant="link"
            >
              Edit
            </CabalButton>

            <CabalButton
              disabled={deleting}
              onClick={() => {
                if (
                  window.confirm(
                    `Are you sure you want to delete ${
                      isAdminOrEmployee ? 'this' : 'your'
                    } profile? ${isAdminOrEmployee ? '' : 'You will be logged out if proceed'}`,
                  )
                ) {
                  setDeleting(true)
                  api
                    .destroyCandidateProfile(teamSlug, candidateUuid)
                    .then(() => {
                      if (isAdminOrEmployee) {
                        cabalToast({
                          content: 'Profile deleted',
                          style: 'success',
                        })
                        history.push(`/${teamSlug}/lists/all-candidates`)
                      } else {
                        window.location.href = '/logout'
                      }
                    })
                    .catch(() => {
                      cabalToast({
                        content: 'Profile could not be deleted',
                        style: 'error',
                      })
                    })
                    .finally(() => setDeleting(false))
                }
              }}
              variant="link"
            >
              Delete {deleting ? <i className="fas fa-spinner fa-spin ml-1" /> : null}
            </CabalButton>
          </>
        )}
      </div>
    </div>
  )
}

export default FilterPills

import React, { useEffect } from 'react'

import ListHeader from 'containers/ListIndexView/Components/ListHeader'
import { useJobsListContext } from 'containers/ListIndexView/TalentList/JobsList'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'

import { joinReactElements } from 'utils/array'

const getDescriptionArray = (
  itemCount: number | undefined,
  description: string | undefined,
  owningTeam: any,
  listType: string,
): React.ReactNode[] => {
  const descriptionArray: React.ReactNode[] = [
    <div className="flex items-center justify-center gap-1" key="team-owner-info">
      <Avatar src={owningTeam.logo} size="16" />
      <Typography fontSize="14" color="fog" className="truncate">
        {owningTeam.name}
      </Typography>
    </div>,
  ]

  if (itemCount) {
    descriptionArray.push(<Typography key="item-count">{itemCount} items</Typography>)
  }

  if (listType) {
    descriptionArray.push(
      <Typography>
        <i className="far fa-star mr-1" />
        {listType.charAt(0).toUpperCase() + listType.slice(1)}
      </Typography>,
    )
  }

  if (description) {
    descriptionArray.push(<Typography key="description">{description}</Typography>)
  }

  return descriptionArray
}

const JobsListHeader = () => {
  const { companyListData } = useJobsListContext()
  const {
    name: listName,
    owning_team: owningTeam,
    description,
    item_count: itemCount,
    list_type: listType,
  } = companyListData
  const descriptionPart = getDescriptionArray(itemCount, description, owningTeam, listType)

  return (
    <ListHeader
      title={listName}
      description={joinReactElements(
        descriptionPart?.map((part, idx) => {
          return (
            <Typography className="truncate" key={idx}>
              {part}
            </Typography>
          )
        }),
        () => (
          <Typography className="mx-1.5">{'·'}</Typography>
        ),
      )}
    />
  )
}

export default JobsListHeader

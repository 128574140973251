import React from 'react'

import pick from 'lodash/pick'
import { useSetState } from 'react-use'

import CabalButton from 'global/CabalButton'
import { CheckBox } from 'global/Input'
import { useTeam, useTeamSlug, useUpdateTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import useTeamSetting from 'utils/hooks/useTeamSetting'
import { Team } from 'utils/types'

const Features: React.FC<{ onEnablePortfolioChange: (enabled: boolean) => void }> = ({
  onEnablePortfolioChange,
}) => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { updateTeamAsync, isUpdatingTeam } = useUpdateTeam(teamSlug)
  const [enablePortfolioJobs, setEnablePortfolioJobs] = useTeamSetting(
    teamSlug,
    'enable_portfolio_jobs',
  )

  const [updatedTeam, setUpdatedTeam] = useSetState(team)

  const handleSubmit = () => {
    updateTeamAsync(
      pick<Team, keyof Team>(
        updatedTeam,
        'enable_offers',
        'public_offers',
        'enable_portfolio',
        'enable_asks',
        'allow_employees_to_import',
        'allow_employees_to_notify_members',
        'only_admin_can_manage_members',
      ),
    )
      .then(() => cabalToast({ style: 'success', content: 'Team settings updated successfully!' }))
      .catch(() => cabalToast({ style: 'error', content: 'Failed to update team settings!' }))
  }

  return (
    <>
      <label>
        <div>
          <CheckBox
            checked={updatedTeam.enable_offers}
            onChange={(e) => setUpdatedTeam({ enable_offers: e.currentTarget.checked })}
            label="Enable Resources Page"
          />
        </div>
      </label>
      <label>
        <div>
          <CheckBox
            checked={updatedTeam.public_offers}
            onChange={(e) => setUpdatedTeam({ public_offers: e.currentTarget.checked })}
            label="Enable Public Resources Page"
          />
        </div>
      </label>
      <label>
        <div>
          <CheckBox
            data-testid="enable-portfolio"
            checked={updatedTeam.enable_portfolio}
            onChange={(e) => {
              setUpdatedTeam({ enable_portfolio: e.currentTarget.checked })
              onEnablePortfolioChange(e.currentTarget.checked)
            }}
            label="Enable Portfolio"
          />
        </div>
        {updatedTeam.enable_portfolio && (
          <div>
            <CheckBox
              data-testid="enable-portfolio-jobs"
              checked={enablePortfolioJobs}
              onChange={(e) => setEnablePortfolioJobs(e.currentTarget.checked)}
              label="Enable Portfolio Jobs"
            />
          </div>
        )}
      </label>
      <label>
        <div>
          <CheckBox
            data-testid="allow-employee-import"
            checked={updatedTeam.allow_employees_to_import}
            onChange={(e) => setUpdatedTeam({ allow_employees_to_import: e.currentTarget.checked })}
            label="Allow non-admins to add members"
          />
        </div>
      </label>
      <label>
        <div>
          <CheckBox
            data-testid="allow-employee-share"
            checked={updatedTeam.allow_employees_to_notify_members}
            onChange={(e) =>
              setUpdatedTeam({ allow_employees_to_notify_members: e.currentTarget.checked })
            }
            label="Allow non-admins to share drafts with members"
          />
        </div>
      </label>
      <label>
        <div>
          <CheckBox
            data-testid="allow-employee-share"
            checked={updatedTeam.only_admin_can_manage_members}
            onChange={(e) =>
              setUpdatedTeam({ only_admin_can_manage_members: e.currentTarget.checked })
            }
            label="Only allow admin users to add + edit members"
          />
        </div>
      </label>

      <div className="flex justify-end">
        <CabalButton
          variant="primary"
          disabled={isUpdatingTeam}
          working={isUpdatingTeam}
          onClick={handleSubmit}
          data-testid="save-public-dir"
        >
          Save
        </CabalButton>
      </div>
    </>
  )
}

export default Features

import React, { useCallback, useState } from 'react'

import { debounce, set } from 'lodash'
import { useMutation, useQuery } from 'react-query'

import { IIntroRequestTarget } from 'containers/IntroRequestModal/types'
import { Select, TextInput } from 'global/Input'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

interface IEmailSelectorResponse {
  email: string
  first_name: string
  last_name: string
}

interface Props {
  target: IIntroRequestTarget
  pointOfContactEmail: string
  inputEmail: (email: string) => void
  disabled?: boolean
}

const PointOfContactInput: React.FC<Props> = ({
  target,
  pointOfContactEmail,
  inputEmail,
  disabled,
}) => {
  const [email, setEmail] = useState(pointOfContactEmail)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [originalParams, setOriginalParams] = useState({ email: '', first_name: '', last_name: '' })

  const { data: emailOptions, refetch: refetchEmailOptions } = useQuery({
    queryFn: () => callApi(api.getContactPoints, target.uuid, target?.itemType),
    onSuccess: (data) => {
      const selectedEmailOption = data.find(
        (option: IEmailSelectorResponse) => option.email === email,
      )

      if (selectedEmailOption) {
        setOriginalParams(selectedEmailOption)
        setFirstName(selectedEmailOption.first_name)
        setLastName(selectedEmailOption.last_name)
      }
    },
  })

  const mutationCreate = useMutation({
    mutationFn: (updatedParams) => {
      return callApi(api.createContactPoint, updatedParams)
    },
    onSuccess: () => {
      cabalToast({ style: 'success', content: 'Email added successfully' })
      refetchEmailOptions()
      setFirstName('')
      setLastName('')
    },
    onError: (error: any) => {
      cabalToast({ style: 'error', content: error?.response?.data?.errors })
    },
  })

  const mutationUpdate = useMutation({
    mutationFn: (updatedParams) => {
      return callApi(api.updateContactPoint, updatedParams.uuid, updatedParams)
    },
    onSuccess: (data) => {
      setFirstName(data?.first_name)
      setLastName(data?.last_name)
      refetchEmailOptions()
    },
    onError: (error: any) => {
      cabalToast({ style: 'error', content: error?.response?.data?.errors })
    },
  })

  const selectedContactPoint = (searchOption: string) => {
    return emailOptions?.find((option: IEmailSelectorResponse) => option.email === searchOption)
  }

  const handleInputChange = (key: string, value: string) => {
    if (key === 'email') {
      setEmail(value)
      inputEmail(value)

      const emailOption = selectedContactPoint(value)

      if (emailOption) {
        setFirstName(emailOption.first_name || '')
        setLastName(emailOption.last_name || '')
        setOriginalParams(emailOption)
      } else {
        setFirstName('')
        setLastName('')
        setOriginalParams({ email: value, first_name: '', last_name: '' })
      }
    } else if (key === 'first_name') {
      setFirstName(value)
    } else if (key === 'last_name') {
      setLastName(value)
    }
  }

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleEmailCreation = (newEmail: string) => {
    if (!validateEmail(newEmail)) {
      cabalToast({ style: 'error', content: 'Email not valid' })
      return
    }

    const updatedParams = {
      email: newEmail,
      first_name: firstName,
      last_name: lastName,
      contactable_type: target.itemType,
      uuid: target?.uuid,
    }

    setEmail(newEmail)
    mutationCreate.mutate(updatedParams)
    inputEmail(newEmail)
    setFirstName(updatedParams.first_name || '')
    setLastName(updatedParams.last_name || '')
  }

  const debouncedMutationUpdate = useCallback(
    debounce((updatedParams) => {
      mutationUpdate.mutate(updatedParams)
    }, 500),
    [],
  )

  const handleBlur = () => {
    const contactPoint = selectedContactPoint(email)

    if (
      contactPoint &&
      (email !== originalParams.email ||
        firstName !== originalParams.first_name ||
        lastName !== originalParams.last_name)
    ) {
      const updatedParams = {
        email,
        first_name: firstName,
        last_name: lastName,
        contactable_type: target.itemType,
        uuid: selectedContactPoint(email)?.uuid,
      }

      debouncedMutationUpdate(updatedParams)
    }
  }

  return (
    <div className="mb-4">
      <Typography fontSize="12" fontWeight={400} lineHeight="1" className="text-center">
        {target.name} contact
      </Typography>

      <div className="flex flex-col space-y-4 mt-2">
        <div className="flex items-center space-x-4">
          <Typography color="admin_label" className="w-24" fontSize="12">
            First Name
          </Typography>
          <TextInput
            data-testid="facilitated-by-name"
            className="flex-grow h-10"
            value={firstName}
            onChange={(e) => handleInputChange('first_name', e.target.value)}
            onBlur={handleBlur}
          />
        </div>

        <div className="flex items-center space-x-4">
          <Typography color="admin_label" className="w-24" fontSize="12">
            Last Name
          </Typography>
          <TextInput
            data-testid="facilitated-by-first-name"
            className="flex-grow h-10"
            value={lastName}
            onChange={(e) => handleInputChange('last_name', e.target.value)}
            onBlur={handleBlur}
          />
        </div>

        <div className="flex items-center space-x-4">
          <Typography color="admin_label" className="w-24" fontSize="12">
            Email
          </Typography>
          <div className="flex-grow">
            <Select
              className="w-full h-10"
              value={email}
              onChange={(e) => handleInputChange('email', e)}
              options={
                emailOptions?.map((email: IEmailSelectorResponse) => ({
                  label: email.email,
                  value: email.email,
                })) || []
              }
              creatable
              onCreateOption={handleEmailCreation}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PointOfContactInput

import React, { useMemo } from 'react'

import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'

import BulkActions from 'containers/ListIndexView/Components/BulkActions'
import { usePeopleListContext } from 'containers/ListIndexView/TalentList/PeopleList'
import { ITalentPeople } from 'containers/ListIndexView/TalentList/type'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

interface Props {
  selectedPeople: ITalentPeople[]
}

const PeopleListBulkActions: React.FC<Props> = ({ selectedPeople }) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const archivedStatus = searchParams.get('status') === 'archived'
  const {
    companyListData: companyList,
    refetchPeople,
    adminView,
    view,
    setView,
  } = usePeopleListContext()

  const selectedPeopleUuids = useMemo(
    () =>
      selectedPeople
        .map((person) => person.candidate_profile?.uuid)
        .filter((uuid): uuid is string => uuid !== undefined),
    [selectedPeople],
  )

  const { mutate: bulkAction } = useMutation(
    (params: any) => callApi(api.candidatesBulkActions, params),
    {
      onSuccess: (_, params) => {
        refetchPeople()
        cabalToast({ style: 'success', content: `Successfully ${params.bulk_action}d!` })
      },
      onError: () => {
        cabalToast({
          style: 'error',
          content: 'Something went wrong, please try again or contact support',
        })
      },
    },
  )

  const handleOnSelect = (action: string, kind: string) => {
    if (selectedPeople.length === 0) {
      cabalToast({
        content: 'Select profiles to perform bulk actions',
        style: 'error',
      })
      return
    }
    if (action === 'delete') {
      const confirmDelete = window.confirm('Are you sure you want to bulk delete?')
      if (confirmDelete) {
        bulkAction({
          selected_candidates_uuids: selectedPeopleUuids,
          bulk_action: action,
          kind: kind,
        })
      }
    } else if (action === 'archive' || action === 'restore') {
      bulkAction({
        list_uuid: companyList.uuid,
        selected_candidates_uuids: selectedPeopleUuids,
        bulk_action: action,
        kind: kind,
      })
    }
  }

  const bulkMenuItems = [
    {
      label: 'Delete Profiles',
      onSelect: () => handleOnSelect('delete', 'candidates'),
    },
    {
      label: `${archivedStatus ? 'Restore' : 'Archive'} Profiles`,
      onSelect: () => handleOnSelect(archivedStatus ? 'restore' : 'archive', 'candidates'),
    },
  ]

  return (
    <>
      {adminView && (
        <span onClick={() => view === 'grid' && setView('list')}>
          <BulkActions disabled={selectedPeople.length === 0} bulkMenuItems={bulkMenuItems} />
        </span>
      )}
    </>
  )
}

export default PeopleListBulkActions

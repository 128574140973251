import React, { useCallback } from 'react'

import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { EditListModal } from 'components/EditList'
import ListSharingSettings from 'components/EditList/EditListAccess/ListSharingSettings'
import CTAs from 'containers/ListIndexView/Components/CTAs'
import TalentSettingsModal from 'containers/ListIndexView/TalentList/TalentSettingsModal'
import { useAccessControl } from 'global/AccessControl'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import { CompanyListBlueprint } from 'utils/types'

interface CTA_OPTION {
  label: React.ReactNode
  onClick: () => void
}

interface Props {
  onShowAddWidget: () => void
  view: 'list' | 'grid'
  setView: (view: 'list' | 'grid') => void
  companyList: CompanyListBlueprint
  refetchCompanyList: () => void
}

const TalentListCTAs: React.FC<Props> = ({
  onShowAddWidget,
  view,
  setView,
  companyList,
  refetchCompanyList,
}) => {
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()
  const history = useHistory()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const adminView = !!isAdminOrEmployee

  const renderSettingsModal: RenderModal = (resolve) => (
    <TalentSettingsModal
      teamSlug={teamSlug}
      onHide={() => resolve(false)}
      companyList={companyList}
      reload={refetchCompanyList}
    />
  )

  const renderShareListModal: RenderModal = (resolve) => (
    <ListSharingSettings
      teamSlug={teamSlug}
      resolve={() => resolve(false)}
      uuid={companyList?.uuid}
      reload={refetchCompanyList}
      header={`Share ${companyList?.name}`}
    />
  )

  const exportList = useMutation(() => api.exportList(companyList?.uuid), {
    onSuccess: () => {
      cabalToast({
        content: 'Your export will be emailed to you shortly',
        style: 'success',
      })
    },
  })

  const options = [
    adminView && {
      label: (
        <Typography className="flex gap-2 items-center">
          <i className="far fa-file-arrow-down" />
          Export
        </Typography>
      ),
      onClick: () => exportList.mutate(),
    },
    adminView && {
      label: (
        <Typography className="flex gap-2 items-center">
          <i className="far fa-plus" />
          Add
        </Typography>
      ),
      onClick: () => onShowAddWidget(),
    },
    {
      label: (
        <Typography className="flex gap-2 items-center">
          {view === 'list' ? (
            <>
              <i className="far fa-grid" />
              Grid
            </>
          ) : (
            <>
              <i className="far fa-list" />
              List
            </>
          )}
        </Typography>
      ),
      onClick: () => setView(view === 'list' ? 'grid' : 'list'),
    },
    adminView && {
      label: (
        <Typography className="flex gap-2 items-center">
          <i className="far fa-arrow-up-from-bracket" />
          Share
        </Typography>
      ),
      onClick: () => showModal(renderShareListModal, 'render_share_list_modal'),
    },
    adminView && {
      label: (
        <Typography className="flex gap-2 items-center">
          <i className="far fa-gear" />
        </Typography>
      ),
      onClick: () => showModal(renderSettingsModal, 'render_talent_settings_modal'),
    },
  ]

  return <CTAs CTAOptions={options.filter(Boolean) as CTA_OPTION[]} />
}

export default TalentListCTAs

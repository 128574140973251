import React, { useState } from 'react'

import pick from 'lodash/pick'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { EditListType } from 'components/EditList'
import ResourceDiscountOptions from 'containers/ListIndexView/ResourcesList/ResourceSettingsModal/ResourceDiscountOptions'
import ResourcePeopleOptions from 'containers/ListIndexView/ResourcesList/ResourceSettingsModal/ResourcePeopleOptions'
import CabalButton from 'global/CabalButton'
import Modal from 'global/Modal'
import { ModalSectionWrapper } from 'global/Modal/styles'
import { useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CompanyListBlueprint } from 'utils/types'

const listParams = (list: Partial<EditListType>) => {
  return pick(list, ['uuid', 'name', 'enable_gatekeeper', 'gatekeeper_id', 'dynamic_gatekeeper'])
}

interface Props {
  onHide: () => void
  teamSlug: string
  companyList: CompanyListBlueprint
  reload: () => void
}

const ResourceSettingsModal = ({ onHide, teamSlug, companyList, reload }: Props) => {
  const { team } = useTeam(teamSlug)
  const history = useHistory()
  const [list, setList] = useState<Partial<EditListType>>(companyList)

  const adminAndMembersValues = [
    {
      label: 'Portfolio Company Owner',
      value: 'dynamic_gatekeeper',
    },
    ...(team?.admins_and_members.map((user) => ({
      label: `${user.first_name} ` + `${user.last_name} ` + `(${user.email})`,
      value: user.identifier,
    })) || []),
  ]

  const updateListMutation = useMutation(
    ['updateList', teamSlug],
    () =>
      callApi(api.updateCompanyList, teamSlug, {
        company_list: listParams(list),
      }),
    {
      onSuccess: () => {
        onHide()
        reload()
        cabalToast({ style: 'success', content: 'List updated successfully!' })
      },
    },
  )

  const deleteListMutation = useMutation(
    ['deleteCompanyList', companyList],
    () => callApi(api.deleteCompanyList, teamSlug, companyList.uuid),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'List deleted' })
        onHide()
        history.push(`/${teamSlug}/lists`)
      },
    },
  )

  return (
    <Modal
      show={true}
      onHide={onHide}
      header={companyList.name}
      rightActions={<CabalButton onClick={() => updateListMutation.mutate()}>Save</CabalButton>}
      leftActions={
        <CabalButton onClick={() => deleteListMutation.mutate()} variant="destructive">
          Delete
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        {companyList.list_type === 'people' && (
          <ResourcePeopleOptions
            list={list}
            setList={setList}
            adminAndMembersValues={adminAndMembersValues}
          />
        )}

        {companyList.list_type === 'discounts' && (
          <ResourceDiscountOptions list={list} setList={setList} />
        )}
      </ModalSectionWrapper>
    </Modal>
  )
}

export default ResourceSettingsModal
